import axios from "axios";
import { URL_ELEMS } from '../../helper/variables';
import { storageAccessToken } from '../../helper/storage';
import store from '../../stores';

const url = process.env.NODE_ENV === 'production' ? '/api' : 'http://natas.westlinkclient.com/api'; //'http://natas.westlinkclient.com/api';//'http://172.16.3.136:3000/';//'http://localhost:30001/';

const http = axios.create({ baseURL: url });

http.interceptors.response.use((response) => {
    if(response.status === 401) {
        const message = "You are not authorized";
        const event = new CustomEvent('errorhandle', {detail: message});
        window.dispatchEvent(event);
        console.log(message);
        window.location.href = `${URL_ELEMS}/login`;
    }
    return response;
}, (error) => {
    if (error.response && error.response.status) {
        const message = JSON.stringify(error.response.data); // TODO Change it
        const url = error.response.config.url;
        const event = new CustomEvent('errorhandle', {detail: {message: message, url: url}});
        window.dispatchEvent(event);
        //TODO Implement alert message
        // window.location.href = `${URL_ELEMS}/login`;
        return Promise.reject(error.response.data);
    }
    //window.location.href = `${URL_ELEMS}/login`;
    return Promise.reject(error.message);
});

http.interceptors.request.use(request => {
    const token = storageAccessToken || store.token;
	if (token) {
        request.headers['Access-Token'] = token;
    }
    return request;
    }, (error) => {
        const event = new CustomEvent('errorhandle', {detail: error.message});
        window.dispatchEvent(event);
        return Promise.reject(error.message);
});

export {
    url,
    http
}
