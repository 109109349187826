import React, { Fragment } from 'react';

const Input = ({type, label, id, onChange, validationError, required, placeholder, value, disabled, showError, viewUserDetailsMode}) => {
    return (
      <div className={`text-input ${(showError && validationError) ? 'invalid' : '' }`}>
        <label htmlFor={id}>{label}{showError && validationError}</label>
        {viewUserDetailsMode ? 
            <div className="viewedField">{value ? value : '-'}</div>
            :
            <input type={type} id={id} onChange={onChange} required={required} placeholder={placeholder} value={value} onKeyDown={(e)=>{
                if(e.keyCode === 13) {
                    e.preventDefault()
                }}} disabled={disabled} />}
      </div>
    )
}

const InputList = ({type, label, id, onChange, validationError, required, placeholder, value, disabled, list, onKeyDown}) => {
    return (
        <div className={`text-input ${validationError ? 'invalid' : ''}`}>
            <label htmlFor={id}>{label}{validationError}</label>
            <input type={type} id={id} list={list} onChange={onChange} required={required} placeholder={placeholder}
                   value={value} disabled={disabled} onKeyDown={onKeyDown}/>
        </div>
    )
}

const SelectType = ({label, id, options, value, onChange, disabled, showError, validationError, viewUserDetailsMode}) => {
    return (
        <div className={`select ${showError && validationError ? 'invalid' : ''}`}>
            <label htmlFor={id}>{label}{showError && validationError}</label>
            {viewUserDetailsMode ?
                <div className="viewedField">{value ? value : '-'}</div>
                :
                <select className="selected-values" id={id} value={value} onChange={onChange} disabled={disabled}>
                    {(!value) && <option value="Not Set">Not Set</option>}
                    {value === 'Not Selected' && <option>{value}</option>}
                    {options && options.map((option, index) => {
                        return <option key={index}>{option}</option>
                    })}
                </select>
                }
        </div>
    )
}

const SelectGroupItem = ({label, id, options, value, onChange, disabled, validationError, showError}) => {
    return (
        <div className={`select ${validationError && showError ? 'invalid' : ''}`}>
            <label htmlFor={id}>{label}{showError && validationError}</label>
            <select className="selected-values" id={id} value={value} onChange={onChange} disabled={disabled}>
                {(!value) && <option value="Not Set">Not Set</option>}
                {options && options.map((option, index) => {
                    return <option key={index}>{option}</option>
                })}
            </select>
        </div>
    )
}

const SelectStatus = ({id, options, value, onChange, disabled, validationError}) => {
    return (
        <div className={`select ${validationError ? 'invalid' : ''}`}>
            <select className="selected-values" id={id} value={value} onChange={onChange} disabled={disabled}>
                {value === 'Not Selected' && <option>{value}</option>}
                {options && options.map((option, index) => {
                    return <option key={index}>{option}</option>
                })}
            </select>
        </div>
    )
}

const SelectOrgType = ({label, id, options, value, onChange, disabled, allUnits}) => {
    return (
        <div className="select">
            <label htmlFor={id}>{label}</label>
            <select id={id} value={value} onChange={onChange} disabled={disabled}>
                <option value={allUnits}>All Units</option>
                {options && options.map((option, index) => {
                    return <option key={index}>{option}</option>
                })}
            </select>
        </div>
    )
}

const SelectObject = ({label, id, options, value, onChange, disabled, valid, showError, viewUserDetailsMode }) => {
  return (
    <div className={`select ${valid ? '' : `${showError === true ? 'invalid' : ''}`}`}>
      <label htmlFor={id}>{valid ? label : `${showError === true ? `${label} must be selected` : `${label}`}`}</label>
      {viewUserDetailsMode ?
        <div className="viewedField">{value ? value : '-'}</div>
        :
        <select value={value} onChange={onChange} disabled={disabled} id={id}>
            {/* {(!value) && <option>not set</option>} */}
            {options && options.length && options.map((option) => {
                return <option key={option.id}>{id === 'nested_in' ? option.path : option.title}</option>
            })}
        </select>
      }
    </div>
  )
}

const SelectOrganization = ({label, id, options, value, onChange, disabled, valid, showError }) => {
    return (
      <div className={`select ${valid ? '' : `${showError === true ? 'invalid' : ''}`}`}>
        <label htmlFor={id}>{valid ? label : `${showError === true ? `${label} must be selected` : `${label}`}`}</label>
        <select value={value} onChange={onChange} disabled={disabled} id={id}>
            {(!value) && <option value="Not Set">Not Set</option>}
            {(value && !options.length) && <option value={value}>{value}</option>}
            {options && options.length && options.map((option) => {
              return <option key={option.id}>{option.title}</option>
            })}
        </select>
      </div>
    )
  }

const BlockInfo = ({label, id, options}) => {
    const uniquePositionOptions =  options.length && options.filter((item, index, self) =>
        index === self.findIndex((t) => (
        item && t && t.id === item.id
        ))
    )

    const uniqueOrganizationOptions =  options.length && options.filter((item, index, self) =>
        index === self.findIndex((t) => (
        item && t && t === item
        ))
    )

    return (
        <div className="block-info">
            <label htmlFor={id}>{label}</label>
            {label === 'Position' ? 
                uniquePositionOptions.length ? uniquePositionOptions.map((option, index) => {
                    if (option) {
                        return <span key={index} id={option.id}>{option.title}</span>
                    } else return null;

                })
                : <span>{'-'}</span>
            :
                uniqueOrganizationOptions.length ? uniqueOrganizationOptions.map((option, index) => {
                    if (option) {
                        return <span key={index} id={option}>{option}</span>
                    } else return null;

                })
            : <span>{'-'}</span>
            }
        </div>
    )
}

const Notes = ({label, id, value}) => {
    return (
        <div className="block-info">
            <label htmlFor={id}>{label}</label>
            <span>{value}</span>
        </div>
    )
}

const Textarea = ({label, id, placeholder, onChange, defaultValue, disabled, validationError, showError}) => {
    return (
        <div className={`textarea ${validationError && showError ? 'invalid' : ''}`}>
            <label htmlFor={id}>{label}{showError && validationError}</label>
            <textarea id={id} placeholder={placeholder} onChange={onChange} value={defaultValue} disabled={disabled}/>
        </div>
    )
}
  
const Button = ({className, value, onClick, disabled, disabledDelete, disabledCreate, id}) => {
  if (value === "Create Group") {
    return <button className={className} onClick={onClick} disabled={disabledCreate}>{value}</button>   
  } else if (value === "Delete") {
    return <button className={className} onClick={onClick} disabled={disabledDelete}>{value}</button>   
  } else {
    return <button className={className} onClick={onClick} id={id}>{value}</button>    
}
}

const PaginationButton = ({className, value, onClick, disabled}) => {
    return <button className={className} onClick={onClick} disabled={disabled}>{value}</button>
}

const Checkbox = ({id, className, checked, value, onChange}) => {
    return (
        <div className={className}>
            <input id={id} type="checkbox" checked={checked} onChange={onChange}/>
            <label htmlFor={id}>{value}</label>
        </div>
    )
}

const Select = ({label, id, options, value, onChange, disabled, valid, showError}) => {
  const selectedValues = options && options.filter((option)=>option.selected).map((option) => option.title).join(', ')

  return (
    <div className={`select ${valid ? '' : `${showError === true ? 'invalid' : ''}`}`}>
      <label htmlFor={id}>{valid ? label : `${showError === true ? `${label} must be selected` : `${label}`}`}</label>
      <div className="selected-values">
        <p>{selectedValues}</p>
      </div>
      <div className="options-list">
        {options.map((item, index) => {
          const positionId = `${id}${item.id}${index}`;
          return <Checkbox
              id={positionId}
              key={index}
              className="checkbox"
              checked={item.selected}
              onChange={onChange}
              value={item.title || item.name}
          />
        })
        }
      </div>
    </div>
  )
}

const SelectOrganizationPositions = ({options, userId, close, onClick, handleUserPosition, valid, showError, orgId}) => {
    
    const selectedValues = options && options.filter((option)=>option.selected).map((option) => option.title).join(', ')
    const placeholder = options.length ? (selectedValues.length ? selectedValues : 'Not Set') : 'Not Set'

    return (
        <div className={`select ${valid ? '' : `${showError === true ? 'invalid' : ''}`}`}>
            <div className={`selected-values ${options.length ? '' : 'disabled'}`} onClick={onClick} id={`s-orgpos${userId}`}>
                <p id={`p-orgpos${userId}`}>{placeholder}</p>
            </div>
            {!close && options.length>0 &&
                <div className='options-list'>
                    {options.map((item, index) => {
                        return <Checkbox
                            id={`orgpos${userId}${index}`}
                            key={index}
                            className="checkbox"
                            checked={item.selected}
                            onChange={(e)=>handleUserPosition(e, item.id)} 
                            value={item.title || item.name}
                        />
                    })}
                </div>
            }
        </div>
    )
}


const ActionsMenu = ({onViewDetails, onEditPopup, onDeletePopup, role_id, containUser}) => {

    const renderElements = () => {
        return (
            <Fragment>
                <li onClick={onEditPopup}>
                    Edit
                </li>
                    <li onClick={onDeletePopup}>
                    Delete
                </li>
            </Fragment>
        )
    }

    return (
        <div className="dropdown-menu-container">
            <ul className='dropdown-menu'>
                {onViewDetails &&
                    <li onClick={onViewDetails}>
                        View Details
                    </li>
                }
                {role_id ? 
                    (role_id === 1 || (role_id === 2 && containUser !== 0)) && renderElements() :
                    renderElements()
                }    
            </ul>
        </div>
    )
}

const StatusMenu = ({isOpen, onStatusFilter}) => {
    if (isOpen) {
        return (
            <div className="dropdown-menu-container">
                <ul className='dropdown-menu'>
                    <li onClick={()=>onStatusFilter('active')}>
                        Active
                    </li>
                    <li onClick={()=>onStatusFilter('inactive')}>
                        Inactive
                    </li>
                    <li onClick={()=>onStatusFilter('achived')}>
                        Archived
                    </li>
                </ul>
            </div>
        )
    } else return null;

}

export {
    Input,
    InputList,
    Select,
    SelectType,
    SelectOrgType,
    SelectObject,
    SelectOrganization,
    SelectOrganizationPositions,
    SelectStatus,
    SelectGroupItem,
    BlockInfo,
    Notes,
    Textarea,
    Button,
    PaginationButton,
    Checkbox,
    ActionsMenu,
    StatusMenu
}