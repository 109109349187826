import React, { Component } from 'react';
import { observer } from 'mobx-react';
import removeButton from '../../../images/remove-tag.svg';

@observer
class GroupTagInput extends Component { 
    render() {
        const { tags, handleInputChange, handleInputKeyDown, handleRemoveItem, input } = this.props;
        return (
                <label className="tags-block">
                    <label htmlFor="list">Tags (type tag and press Enter)</label>
                    <ul className='tags-container' id='list'>
                    {tags.map((tag, i) => {
                        return (
                            <li key={i} className='tag' onClick={handleRemoveItem(i)}>
                                {tag}
                                <span><img src={removeButton} alt='remove'/></span>
                            </li>
                        )
                    })}
                    <input
                        className='tags-input'
                        value={input}
                        onChange={(e) => handleInputChange(e)}
                        onKeyDown={(e) => handleInputKeyDown(e)} />
                    </ul>
                </label>
        );
    }
}

export default GroupTagInput;