import {observable, action} from 'mobx';
import {storageAccessToken} from '../helper/storage';
import KnowledgeBaseService from '../services/KnowledgeBaseService';
import {IKnowledgeItem, IKnowledgeList} from '../components/KnowledgeBase/InterfaceKnowledgeBase'


class KnowledgeBaseStore {

    constructor() {
        this.token = storageAccessToken;
        this.responce = {}
    }

    @observable token = null;
    @observable isLoading = true;
    @observable responce: IKnowledgeList = {};
    @observable page: Number = 0;
    @observable newPage: Number = 0;
    @observable totalPages: Number = 0;
    @observable totalGroupsCount: Number = 0;
    @observable shownGroupsCount: Number = 0;
    @observable items: [IKnowledgeItem] = [];
    @observable singleItem: null;
    @observable singleItemLoaded: false;
    @observable success: false;
    @observable searchValue = '';
    @observable dateJoined = 0;
    @observable datePeriodJoined = 0;
    @observable unitList = [];
    @observable unitListBeforeAddingUnit = [];
    @observable defaultGroup = {
        id: 0,
        title: '',
        source: '',
        status: '',
        description: '',
        tags: [],
        filters: [],
    }

    @action list = async (config = {}) => {
        this.isLoading = true;
        const {data} = await KnowledgeBaseService.list(config);
        this.responce = data;
        this.setData();
    };

    @action show = async (id) => {
        this.singleItemLoaded = false;
        const {data} = await KnowledgeBaseService.show(id);
        this.singleItem = data;
        this.singleItemLoaded = true;
    };

    @action createGroup = async(data) => {
        this.isLoading = true;

        const response = await KnowledgeBaseService.create(data);

        if (response.statusText === "Created") {
            this.isLoading = false;
            this.success = true;
            this.list();
        } else {
            this.isLoading = false;
            this.success = false; 
        }
    }

    @action editGroup = async(id, data) => {
        this.isLoading = true;

        const response = await KnowledgeBaseService.edit(id, data);

        if (response.statusText === "OK") {
            this.isLoading = false;
            this.success = true;
            this.list();
        } else {
            this.isLoading = false;
            this.success = false; 
        }
    }

    @action deleteGroup = async (id) => {
        this.isLoading = true;
        await KnowledgeBaseService.delete(id);
        this.isLoading = false;
    };

    @action getUnitList = async (id) => {
        this.isLoading = true;
        const { data } = await KnowledgeBaseService.organizationList(id);
        this.unitList = data;
        this.isLoading = false;
    };

    setData() {
        this.page = this.responce.page;
        this.totalPages = Math.ceil((this.responce.total / this.responce.limit));
        this.items = this.responce.result;
        this.totalGroupsCount = this.responce.total;
        this.shownGroupsCount = this.items.length ? this.items.length + (this.responce.page-1)*10 : this.items.length;
        this.isLoading = false;
    }
}

const store = new KnowledgeBaseStore();


export default store;