import React from 'react';
import { observer } from 'mobx-react';
import store from '../../stores';
import KnowledgeBaseStore from '../../stores/KnowledgeBaseStore';
import cancel from '../../images/cancel.svg';
import './errorBoundary.scss'

@observer
class ErrorBoundary extends React.Component {
  state = {
    errorMessage: null,
    isErrorWindowOpen: false,
  }

  componentDidMount() {
    window.addEventListener('errorhandle', this.errorHandle);
  }

  componentWillUnmount() {
    window.removeEventListener('errorhandle', this.errorHandle);
  }

  errorHandle = (e) => {
    store.isLoading = false;
    store.isInitialLoading = false;
    KnowledgeBaseStore.isLoading = false;
    this.setState({...this.state, isErrorWindowOpen: true, errorMessage: e.detail}, () => store.error = e.detail)
  }

  onClose = () => {
    this.setState({...this.setState, isErrorWindowOpen: false, errorMessage: null})
  }

render() {
  const { children } = this.props;
  const { errorMessage, isErrorWindowOpen } = this.state;

  // let _errorMessage = {error: ''};

  // try {
  //   _errorMessage = JSON.parse(errorMessage.message)



  // } catch (e) {
  //   console.error(e);
  // }

  if (errorMessage) {
    if(errorMessage.url && errorMessage.url.includes('import')) {
      return children;
    } 
    else if (JSON.parse(errorMessage.message).error && (errorMessage.url.includes('users') || errorMessage.url.includes('knowledges')) && (JSON.parse(errorMessage.message).error.includes('Entity already exists') || JSON.parse(errorMessage.message).error.includes('duplicate'))) {
      return (
        <div>
          <div className={`error-message ${isErrorWindowOpen ? '' : 'close'}`}>
            <span onClick={this.onClose}><img src={cancel} alt="cancel" /></span>
            {'This email was already taken'}
          </div>
          {children}
        </div>
      )
    }
    else if (JSON.parse(errorMessage.message).service_host && JSON.parse(errorMessage.message).service_host.includes('mailchimp')) {
      return (
        <div>
          <div className={`error-message ${isErrorWindowOpen ? '' : 'close'}`}>
            <span onClick={this.onClose}><img src={cancel} alt="cancel" /></span>
            {'External error. Mailchimp'}
          </div>
          {children}
        </div>
      )
    } 
    else if (JSON.parse(errorMessage.message).service_host && JSON.parse(errorMessage.message).service_host.includes('google') && !errorMessage.url.includes('oauth2_callback')) {
      return (
        <div>
          <div className={`error-message ${isErrorWindowOpen ? '' : 'close'}`}>
            <span onClick={this.onClose}><img src={cancel} alt="cancel" /></span>
            {'External error. Google'}
          </div>
          {children}
        </div>
      )
    } 
    else if (JSON.parse(errorMessage.message).error && JSON.parse(errorMessage.message).error.includes('external')) {
      return (
        <div>
          <div className={`error-message ${isErrorWindowOpen ? '' : 'close'}`}>
            <span onClick={this.onClose}><img src={cancel} alt="cancel" /></span>
            {'External error'}
          </div>
          {children}
        </div>
      )
    } 
    else if (errorMessage.url && errorMessage.url.includes('oauth2_callback')) {
      return (
        <div>
          <div className={`error-message ${isErrorWindowOpen ? '' : 'close'}`}>
            <span onClick={this.onClose}><img src={cancel} alt="cancel" /></span>
            {'You don’t have permissions to access the portal. Make sure to use correct Google account.'}
          </div>
          {children}
        </div>
      )
    } 
    else {
      return (
        <div>
          <div className={`error-message ${isErrorWindowOpen ? '' : 'close'}`}>
            <span onClick={this.onClose}><img src={cancel} alt="cancel" /></span>
            {errorMessage.message}
          </div>
          {children}
        </div>
      )
    }
    } 
  else return children;
}
}

export default ErrorBoundary;