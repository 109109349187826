import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input, Button, SelectStatus, SelectOrganizationPositions } from '../../../helper/components';
import Spinner from '../../Spinner';
import store from '../../../stores';
import CatalogServices from '../../../services/CatalogServices';
import { 
    ACTIVE, 
    ARCHIVED, 
    REMOVE, 
    ADD, 
    ADD_NEW_USER,
    NAME,
    POSITION,
    STATUS,
    ACTION,
    LOAD_MORE,
    NO_RECORDS_FOUND
} from '../../../helper/strings';

@observer
class User extends Component {

    state = {
        options: [],
        close: true,
        valid: false,
        showError: false,
    }

    ref = React.createRef();

    componentDidMount() {
        const { positions, positionList, orgId } = this.props;
        let selectedPositions = [];
        let unSelectedPositions = [];

        if(!orgId) {
            selectedPositions = positions.length ? positionList.filter(o1 => positions.some(o2 => o1.id === o2.id && o2.selected)).map((item) => {return {...item, selected: true}}) : positionList.map((item) => {return {...item, selected: false}})
            unSelectedPositions = positions.length ? positionList.filter(o1 => !positions.some(o2 => o1.id === o2.id && o2.selected)).map((item) => {return {...item, selected: false}}) : positionList.map((item) => {return {...item, selected: false}})
        } else if (orgId) {
            selectedPositions = positions.length ? positionList.filter(o1 => positions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : positionList.map((item) => {return {...item, selected: false}})
            unSelectedPositions = positions.length ? positionList.filter(o1 => !positions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: false}}) : positionList.map((item) => {return {...item, selected: false}})
        }
        

        const newPositions = [...selectedPositions, ...unSelectedPositions].filter((thing, index, self) =>
            index === self.findIndex((t) => (
            t.id === thing.id
            ))
        );

        this.setState({...this.state, options: newPositions})
        window.addEventListener('click', this.onPositionMenuClickHandler)
    }

    componentDidUpdate(prevProps) {
        const { positions, positionList } = this.props;
        
        if(prevProps.positionList !== positionList) {
            const selectedPositions = positions.length ? positionList.filter(o1 => positions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : positionList.map((item) => {return {...item, selected: false}})
            const unSelectedPositions = positions.length ? positionList.filter(o1 => !positions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: false}}) : positionList.map((item) => {return {...item, selected: false}})
    
            const newPositions = [...selectedPositions, ...unSelectedPositions].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );
            this.setState({...this.state, options: newPositions})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onPositionMenuClickHandler)
    }

    addPositionUser = async(userId, posId) => {
        const { orgId } = this.props;

        const updatedUser = {
            organizations: [{
                organization_id: orgId,
                position_id: posId
            }]
        }

        await CatalogServices.updateUser(userId, updatedUser);
    }

    handleUserPosition = (e, posId) => {
        const { orgId } = this.props;
        const { id, includes } = this.props.item;
        const targetId = e.target.id;

        const selectedOptions = this.state.options.map((option, index) => {
            if (targetId === `orgpos${id}${index}`) {
                if(option.selected && includes && orgId) {
                    const entityId = this.props.organization_users.filter((org) => org.position_id === option.id)[0].id;
                    if(entityId) {
                        CatalogServices.deletePositionFromUser(entityId)
                    }
                } else if (!option.selected) {
                    if(includes && orgId) {
                        this.addPositionUser(id, posId);
                    }
                } 
                return {...option, selected: !option.selected} 
            } else return {...option}
        })

        const valid = selectedOptions.map((option) => option.selected).length;

        if(!orgId) {
            const newAddedUsersBeforeUnitCreate = store.addedUsersBeforeUnitCreate.map((user) => {
                if (user.id === id) {
                    return {
                        ...user,
                        positions: selectedOptions
                    }
                } else return {...user}
            })
            store.addedUsersBeforeUnitCreate = newAddedUsersBeforeUnitCreate;
        }

        this.setState({...this.state, options: selectedOptions, valid: valid})
    }

    handleClick = () => {
        this.setState({...this.state, close: !this.state.close});
    }

    onPositionMenuClickHandler = (e) => {
        if (!this.ref.current.contains(e.target)) {
            this.setState({
                ...this.state,
                close: true,
            });
        }
    } 

    onAddUser = async() => {
        const { id } = this.props.item;
        const { orgId } = this.props;

        const selectedPositions = this.state.options.filter((option) => option.selected)
        let updateOrganizations = [];

        selectedPositions.forEach((pos) => {
            updateOrganizations.push({
                organization_id: orgId,
                position_id: pos.id
            })
        })

        const userOrganizations = {
            organizations: updateOrganizations,
        }

        if(this.state.valid) {
            if (orgId) {
                await CatalogServices.updateUser(id, userOrganizations);
                store.viewedOrgUsersList = [];
                store.viewedOrgUsersListPage = 1;
                store.updatedOrgUsersListPage = 1;
                if(store.searchOrgUsersInputValue.length) {
                    store.getOrgUsersList();
                } else {
                    store.getOrganizationUsers(orgId);
                }
            } else {
                const userObject = {
                    id: this.props.item.id,
                    first_name: this.props.item.first_name,
                    last_name: this.props.item.last_name,
                    positions: selectedPositions,
                }
                store.addedUsersBeforeUnitCreate = [...store.addedUsersBeforeUnitCreate, userObject]
                this.setState({...this.state, positions: selectedPositions})
            }
        } else {
            this.setState({...this.state, showError: true})
        }
    }

    onRemoveUser = async() => {
        const { orgId, item } = this.props;
        
        if (orgId) {
            const entityIds = this.childEntities();

            for(let i=0; i < entityIds.length; i++) {
                if (entityIds[i]) {
                    await CatalogServices.deletePositionFromUser(entityIds[i])
                }
            }
            store.viewedOrgUsersList = [];
            store.viewedOrgUsersListPage = 1;
            store.updatedOrgUsersListPage = 1;
            if(store.searchOrgUsersInputValue.length) {
                store.getOrgUsersList();
            } else {
                store.getOrganizationUsers(orgId);
            }
        } else if (!orgId && item.id !== store.current_user_id) {
            store.addedUsersBeforeUnitCreate = store.addedUsersBeforeUnitCreate.filter((user) => user.id !== item.id)
            const defaultOptions = this.props.positionList.map((item) => {return {...item, selected: false}})
            this.setState({...this.state, options: defaultOptions}) 
        }
    }

    childEntities = () => {
        const { orgId, organization_users } = this.props;
        const childrenUnits = store.unitsTree.filter((org) => org.id === orgId)[0].child_ids;
        const entities = organization_users.filter(o1 => childrenUnits.some(o2 => o1.organization_id === o2)).map((org) => org.id)
        return entities;
    }

    onStatusChange = async(e) => {
        const { id } = this.props.item;
        const userStatus = {status: e.target.value}
        await CatalogServices.updateUser(id, userStatus);
        store.getOrgUsersList();
        store.getOrganizationUsers(this.props.orgId);
    }

    render() {
        const { item, orgId } = this.props;
        const { options, close, valid, showError } = this.state;
        const name = `${item.first_name} ${item.last_name}`

        return (
            <tr>
                <td>
                    {name}
                </td>
                <td ref={this.ref}>
                    <SelectOrganizationPositions options={options} userId={item.id} close={close} onClick={this.handleClick} handleUserPosition={this.handleUserPosition} orgId={orgId} valid={valid} showError={showError} />
                </td>
                <td>
                    <SelectStatus value={item.status} options={[ACTIVE, ARCHIVED]} disabled={item.includes ? null : 'disabled'} onChange={this.onStatusChange} />
                </td>
                <td>
                    {item.includes ? 
                        <span className="remove" onClick={this.onRemoveUser}>{REMOVE}</span>                                                        
                        :
                        <span className="add" onClick={this.onAddUser}>{ADD}</span>
                    }
                </td>
            </tr>
        )
    }
}
@observer
class UsersData extends Component {

    state = {
        users: []
    }

    componentDidMount() {
        this.setState({users: this.props.users})
    }

    componentDidUpdate(prevProps) {
        if(prevProps.users !== this.props.users || prevProps.addedUsersBeforeUnitCreate !== this.props.addedUsersBeforeUnitCreate) {
            if (!this.props.orgId) {
            const addedUsers = store.addedUsersBeforeUnitCreate.length ? this.props.users.filter(o1 => store.addedUsersBeforeUnitCreate.some(o2 => o1.id === o2.id)).map((item) => {return {...item, includes: true}}) : this.props.users.map((item) => {return {...item, includes: false}})
            const unAddedUsers = store.addedUsersBeforeUnitCreate.length ? this.props.users.filter(o1 => !store.addedUsersBeforeUnitCreate.some(o2 => o1.id === o2.id)).map((item) => {return {...item, includes: false}}) : this.props.users.map((item) => {return {...item, includes: false}})
    
            const newUsersArray = [...addedUsers, ...unAddedUsers].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );  
            this.setState({users: newUsersArray})
            } else if (this.props.orgId) {
                this.setState({users: this.props.users})    
            }
        }
    }

    onChange = (e) => {
        store.updatedOrgUsersListPage = 1;
        
        store.searchOrgUsersInputValue = e.target.value;
        if(!e.target.value.length) {
            store.viewedOrgUsersList = [];
            store.viewedOrgUsersListPage = 1;
        }
    }

    onCreateUserPopup = () => {
        store.createUserMode = true;
        store.editUserMode = false;
        if (this.props.orgId) {
            store.updatedUserAddress.organizations = [{organization_id: this.props.orgId, position_id: null}]
        }        
        store.isUserModalShown = true;
    }
    
    onClick = () => {
        if(store.searchOrgUsersInputValue.length) {
            store.updatedOrgUsersListPage = store.updatedOrgUsersListPage + 1;
            store.getOrgUsersList();
        } else {
            store.viewedOrgUsersListPage = store.viewedOrgUsersListPage + 1;
            if (!store.editOrganizationMode && store.viewedOrganizationId) {
                store.getOrganizationUsers(store.viewedOrganizationId);
            } else {
                store.getOrganizationUsers(store.editedOrgId);
            }
        }
    }
    
    render() {
        return (
            <div className="users-view">
                <div className="data">
                    <div className="panel">
                        <div className="search-input">
                            <Input type="text" onChange={this.onChange} placeholder='Search' />
                        </div>
                        {store.editOrganizationMode && <Button className='button-primary' value={ADD_NEW_USER} onClick={()=>this.onCreateUserPopup()} />}
                    </div>
                    <table className="organization-modal-grid">
                        <thead>
                            <tr>
                                <th>{NAME}</th>
                                <th>{POSITION}</th>
                                <th>{STATUS}</th>
                                <th>{ACTION}</th>
                            </tr>
                        </thead>
                        {store.isAddressBookLoading ? 
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <Spinner />
                                    </td>
                                </tr>  
                            </tbody>
                            :
                            this.state.users.length ? 
                                <tbody>
                                    {this.state.users.map((item, index) => <User item={item} key={index} orgId={this.props.orgId} positions={item.includes ? this.props.orgId ? item.positions.filter(o1 => item.organization_users.some(o2 => o1.id === o2.position_id && this.props.orgId === o2.organization_id)) : this.props.addedUsersBeforeUnitCreate.filter((user) => user.id === item.id).map((user) => user.positions)[0] : []} organization_users={item.organization_users} positionList={this.props.positionList} />)}
                                    {this.state.users.length && (!store.searchOrgUsersInputValue ? store.viewedOrgUsersListPage < store.viewedOrgUsersListTotalPages : store.updatedOrgUsersListPage < store.updatedOrgUsersListTotalPages) ?
                                        <tr className='load-more-btn-container'>
                                            <td colSpan="4">
                                                <Button className="load-more-btn" value={LOAD_MORE} onClick={()=>this.onClick()}/>
                                            </td>
                                        </tr>
                                        :
                                        null
                                    }
                                </tbody>
                                :
                                <tbody className="no-results-body">
                                    <tr>
                                        <td colSpan="4">
                                            <div className="no-results">{NO_RECORDS_FOUND}</div>
                                        </td>
                                    </tr>  
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }   
}

export default UsersData;