import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../stores';
import logo from '../../images/logo.svg';
import userIcon from '../../images/user_icon.svg';
import './header.scss';

@observer
class Header extends Component  {

  state = {
    windowWidth: window.innerWidth,
  }

  componentDidMount = async() => {
    window.addEventListener('resize', this.onUpdateWidth)
    await store.getCurrentUser(store.current_user_id)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onUpdateWidth)
  }

  roleDefine = () => {
    let userRole = '';
    const id = store.current_user_role_id;

    if (id === 1) {
      userRole = 'Admin'
    } else if (id === 2) {
      userRole = 'Manager'
    } else if (id === 3) {
      userRole = 'Employee'
    }

    return userRole;
  }


  onUpdateWidth = () => {
    this.setState({windowWidth: window.innerWidth});
  }

  signOut = () => {
    store.isAuthenticated=false; 
    localStorage.clear();
    store.signOut();
  }

  render() {
    return (
      <Fragment>
          <div className={`header-container ${store.current_user_role_id === 3 ? 'employee' : ''}`}>
            {this.props.isSidebarOpen && this.state.windowWidth > 900 ? 
              <div className="logo-container">
                <img src={logo} alt="logo" className="logo" />
              </div> 
              :
              <i className="fas fa-align-justify" onClick={this.props.onToggleSidebar}></i>
            }
            <div className="control-panel-container">
              <div className="dropdown-menu-container">
                  <div className="title">
                    <span className="type">NATAS ROSTER</span>
                  </div>
              </div>
              <div className="dropdown-menu-container">
                  <div className="title">
                    <div className="image-container">
                      {store.isAvatarLoading ? 
                        <img src={userIcon} alt="user-icon" />
                        :
                          store.user.avatar_url ?
                            <img src={`${store.user.avatar_url}`} alt="user-icon" />
                            :
                            <img src={`data:image/jpeg;base64,${store.user.avatar_url}`} alt="user-icon" />
                      }
                    </div>
                    <span className="type">{this.roleDefine()}</span>
                    <span>&#9662;</span>
                  </div>
                  <ul className='dropdown-menu'>
                    <Link to="/account_settings">
                      <li onClick={()=>console.log('acc settings')}>
                          Account Settings
                      </li>
                    </Link>
                    <Link to="/login">
                      <li onClick={()=>this.signOut()}>
                          Sign Out
                      </li>
                    </Link>
                  </ul> 
              </div>
            </div>
          </div>
      </Fragment>
    );
  }
}

export default Header;