import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input, Checkbox } from '../../../helper/components';
import store from '../../../stores/Store';
import { 
    ORGANIZATIONS, 
    CHAPTERS,
    OFFICES,
    POSITIONS,
    TAGS
} from '../../../helper/strings';

@observer
class FilterBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            organizations: '',
            chapters: '',
            offices: '',
            positions: '',
            tags: '',
        }
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        this.filteredOptions(this.props.options, this.state[this.props.id]);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedOptions.length !== this.props.selectedOptions.length) {
            this.filteredOptions(this.props.options, this.state[this.props.id]);
        }
    }

    storeUpdater = (option) => {
        const { id } = this.props;

        if (id === ORGANIZATIONS) {
            if (option.selected) {
                store.organizationFilterOptionsSelected = store.organizationFilterOptionsSelected.filter((item) => item.id !== option.id)
            } else if (!option.selected) {
                store.organizationFilterOptionsSelected = [...store.organizationFilterOptionsSelected, {...option, selected: true}]
            }
            store.organizationFilterOptionsSelectedId = [...store.organizationFilterOptionsSelected, ...store.chapterFilterOptionsSelected, ...store.officeFilterOptionsSelected].map((item) => item.id)
        }

        if (id === CHAPTERS) {
            if (option.selected) {
                store.chapterFilterOptionsSelected = store.chapterFilterOptionsSelected.filter((item) => item.id !== option.id)
            } else if (!option.selected) {
                store.chapterFilterOptionsSelected = [...store.chapterFilterOptionsSelected, {...option, selected: true}]
            }
            store.organizationFilterOptionsSelectedId = [...store.organizationFilterOptionsSelected, ...store.chapterFilterOptionsSelected, ...store.officeFilterOptionsSelected].map((item) => item.id)
        }

        if (id === OFFICES) {
            if (option.selected) {
                store.officeFilterOptionsSelected = store.officeFilterOptionsSelected.filter((item) => item.id !== option.id)
            } else if (!option.selected) {
                store.officeFilterOptionsSelected = [...store.officeFilterOptionsSelected, {...option, selected: true}]
            }
            store.organizationFilterOptionsSelectedId = [...store.organizationFilterOptionsSelected, ...store.chapterFilterOptionsSelected, ...store.officeFilterOptionsSelected].map((item) => item.id)
        }

        if (id === POSITIONS) {
            if (option.selected) {
                store.positionFilterOptionsSelected = store.positionFilterOptionsSelected.filter((item) => item.id !== option.id)
            } else if (!option.selected) {
                store.positionFilterOptionsSelected = [...store.positionFilterOptionsSelected, {...option, selected: true}] 
            }
            store.positionFilterOptionsSelectedId = store.positionFilterOptionsSelected.map((item) => item.id)
        }

        if (id === TAGS) {
            if (option.selected) {
                store.tagFilterOptionsSelected = store.tagFilterOptionsSelected.filter((item) => item.name !== option.name)
            } else if (!option.selected) {
                store.tagFilterOptionsSelected = [...store.tagFilterOptionsSelected, {...option, selected: true}]
            }
            store.tagFilterOptionsSelectedName = store.tagFilterOptionsSelected.map((tag) => tag.name)
        }  
    }

    storeUpdaterAll = (selected, option) => {
        const { id } = this.props;

        if (id === ORGANIZATIONS) {
            if (selected.length !== this.state.options.length) {
                if (!option.selected) {
                    store.organizationFilterOptionsSelected = [...store.organizationFilterOptionsSelected, {...option, selected: true}]
                }
            } else {
                if (option.selected) {
                    store.organizationFilterOptionsSelected = store.organizationFilterOptionsSelected.filter((item) => item.id !== option.id)
                }
            }
            store.organizationFilterOptionsSelectedId = [...store.organizationFilterOptionsSelected, ...store.chapterFilterOptionsSelected, ...store.officeFilterOptionsSelected].map((item) => item.id)
        }

        if (id === CHAPTERS) {
            if (selected.length !== this.state.options.length) {
                if (!option.selected) {
                    store.chapterFilterOptionsSelected = [...store.chapterFilterOptionsSelected, {...option, selected: true}]
                }
            } else {
                if (option.selected) {
                    store.chapterFilterOptionsSelected = store.chapterFilterOptionsSelected.filter((item) => item.id !== option.id)
                }
            }
            store.organizationFilterOptionsSelectedId = [...store.organizationFilterOptionsSelected, ...store.chapterFilterOptionsSelected, ...store.officeFilterOptionsSelected].map((item) => item.id)
        }

        if (id === OFFICES) {
            if (selected.length !== this.state.options.length) {
                if (!option.selected) {
                    store.officeFilterOptionsSelected = [...store.officeFilterOptionsSelected, {...option, selected: true}]
                }
            } else {
                if (option.selected) {
                    store.officeFilterOptionsSelected = store.officeFilterOptionsSelected.filter((item) => item.id !== option.id)
                }
            }
            store.organizationFilterOptionsSelectedId = [...store.organizationFilterOptionsSelected, ...store.chapterFilterOptionsSelected, ...store.officeFilterOptionsSelected].map((item) => item.id)
        }

        if (id === POSITIONS) {
            if (selected.length !== this.state.options.length) {
                if (!option.selected) {
                    store.positionFilterOptionsSelected = [...store.positionFilterOptionsSelected, {...option, selected: true}] 
                }
            } else {
                if (option.selected) {
                    store.positionFilterOptionsSelected = store.positionFilterOptionsSelected.filter((item) => item.id !== option.id)
                }
            }
            store.positionFilterOptionsSelectedId = store.positionFilterOptionsSelected.map((item) => item.id)
        }

        if (id === TAGS) {
            if (selected.length !== this.state.options.length) {
                if (!option.selected) {
                    store.tagFilterOptionsSelected = [...store.tagFilterOptionsSelected, {...option, selected: true}]
                }
            } else {
                if (option.selected) {
                    store.tagFilterOptionsSelected = store.tagFilterOptionsSelected.filter((item) => item.name !== option.name)
                }
            }
            store.tagFilterOptionsSelectedName = store.tagFilterOptionsSelected.map((tag) => tag.name)
        }  
    }

    handleCheckboxChange = (e) => {
        const id = e.target.id;

        this.state.options
            .filter((option, index) => id === `${this.props.id}${index}`)
            .map((option) => this.storeUpdater(option))

        store.currentUsersPage = 1;
        store.getUsersList();
    }

    handleCheckboxChangeAll = () => {
        const selected = this.state.options.filter((option) => option.selected);

        this.state.options.map((option) => this.storeUpdaterAll(selected, option))

        store.currentUsersPage = 1;
        store.getUsersList();
    }

    onChange = (e) => {
        this.setState({...this.state, [e.target.id]: e.target.value}, () => this.filteredOptions(this.props.options, this.state[this.props.id]));
    }

    filteredOptions = (arr, value) => {
        let unSelectedOptions = [];
        if(this.props.id === TAGS) {
            unSelectedOptions = this.props.selectedOptions.length ? arr.filter(o1 => !this.props.selectedOptions.some(o2 => o1.name === o2.name)).map((item) => {return {...item, selected: false}}) : arr;
        } else {
            unSelectedOptions = this.props.selectedOptions.length ? arr.filter(o1 => !this.props.selectedOptions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: false}}) : arr;
        }

        const filtered = [...this.props.selectedOptions, ...unSelectedOptions].filter((filteredItem) => {
            if (filteredItem.title) {
                return filteredItem.title.toLowerCase().includes(value.toLowerCase())
            } else if (filteredItem.name) {
                return filteredItem.name.toLowerCase().includes(value.toLowerCase())
            } else return null;
        })

        this.setState({...this.state, options: filtered})
    }

    render() {
        const { id } = this.props;
        const { options } = this.state;
        const selectedOptions = options.filter((option) => option.selected);

        return (
            <div className="filter" id={`${id}-filter-bar`} >
                <Checkbox 
                    id={`checkedAll${id}`}
                    className='checkbox_checkedAll' 
                    checked={options.length === selectedOptions.length}
                    onChange={this.handleCheckboxChangeAll}
                    value={`${selectedOptions.length}/${options.length}`}
                />
                <div className="search-input">
                    <Input type="text" onChange={this.onChange} id={id} />
                </div>
                <div className="options-list">
                    {this.state[id].length > 0 ? 
                        options.map((item, index) => {
                                    return <Checkbox
                                        id={`${id}${index}`}
                                        key={index}
                                        className="checkbox"
                                        checked={item.selected}
                                        onChange={this.handleCheckboxChange}
                                        value={item.title || item.name }
                                    />
                        }) :
                        this.state.options.map((item, index) => {
                            return <Checkbox
                                id={`${id}${index}`}
                                key={index}
                                className="checkbox"
                                checked={item.selected}
                                onChange={this.handleCheckboxChange}
                                value={item.title || item.name}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
}

export default FilterBar;