import { observable, action, reaction } from 'mobx';
import CatalogServices from '../services/CatalogServices';
import { storageAccessToken, storageCurrentUserId, storageCurrentUserRole, storageCurrentUser, storageOrganizations, storagePositions, storageRoles } from '../helper/storage';
import { generatePassword } from '../helper/variables';
import KnowledgeBaseStore from './KnowledgeBaseStore';
import KnowledgeBaseService from '../services/KnowledgeBaseService';

export const defaultUserInfo = {
    avatar_data: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    maiden_name: '',
    nickname: '',
    preferred_first_name: '',
    preferred_last_name: '',
    prefix: '',
}

export const defaultUserAddress = {
    addresses_users: [{
        active: 1,
        primary: true,
        value: '',
        options: ["personal", "working", "mailing"],
    }],
    phones_users: [{
        active: 1,
        primary: true,
        phone_type: '',
        value: ''
    }],
    emails_users: [{
        active: 1,
        primary: true,
        value: ''
    }],
    tags_users: [{
        active: 0,
        name: '',
    }],
    role: {
        id: 3,
        name: "employee",
        title: "Employee"
    },
    organizations: [],
    role_id: 0,
    private_notes: '',
    public_notes: '',
}

const defaultOrgTypes = ["organization", "sub-org", "department", "committee"];


class Store {
    constructor() {
        this.token = storageAccessToken;
        this.current_user_id = storageCurrentUserId;
        this.current_user_role_id = storageCurrentUserRole;
        this.user = storageCurrentUser;
        this.userOrganizations = storageOrganizations;
        this.userPositions = storagePositions;
        this.userRoles = storageRoles;
    }

    @observable link = null;
    @observable code = null;
    @observable token = null;
    @observable storageToken = null || storageAccessToken;
    @observable isAuthenticated = this.storageToken;
    @observable error = null;

    @observable current_user_id = null;
    @observable current_user_role_id = null;

    @observable defaultUser = {...defaultUserInfo, ...defaultUserAddress};
    @observable user = this.defaultUser;
    @observable base64avatar = null;

    @observable userOrganizations = [];
    @observable userPositions = [];
    @observable userRoles = [];
    @observable organizationList = [];

    @observable isInitialLoading = false;
    @observable isLoading = false;
    @observable isLocalLoading = false;
    @observable isAddressBookLoading = false;
    @observable isAvatarLoading = true;

    @observable formInfoValid = false;
    @observable formAddressValid = false;

    @observable users = [];

    @observable uniqueOrganizationOptions = [];
    @observable organizationFilterOptionsSelected = [];
    @observable organizationFilterOptionsSelectedId = [];

    @observable uniqueChapterOptions = [];
    @observable chapterFilterOptionsSelected = [];

    @observable uniqueOfficeOptions = [];
    @observable officeFilterOptionsSelected = [];

    @observable uniquePositionOptions = [];
    @observable positionFilterOptionsSelected = [];
    @observable positionFilterOptionsSelectedId = [];

    @observable uniqueTagOptions = [];
    @observable tagFilterOptionsSelected = [];
    @observable tagFilterOptionsSelectedName = [];

    @observable isUserModalShown = false;
    @observable isOrganizationModalShown = false;
    @observable isDeleteModalShown = false;
    @observable isViewUsersModalShown = false;
    @observable isViewPositionsModalShown = false;
    @observable isGroupModalShown = false;
    @observable isGroupDetailsModalShown = false;
    
    @observable createUserMode = null;
    @observable editUserMode = null;

    @observable editedUser = null;
    @observable editedGroup = null;
    @observable editedOrganization = null;

    @observable success = false;
    @observable updateDetailsSuccess = false;

    @observable updatedNewUserInfo = {};
    @observable updatedNewUserAddress = {};
    

    @observable updatedUserInfo = defaultUserInfo;
    @observable updatedUserAddress = defaultUserAddress;
    @observable newUser = {...this.updatedUserInfo, ...this.updatedUserAddress};
    @observable password = null;

    @observable searchInputValue = '';
    @observable searchOrgUsersInputValue = ''
    @observable searchBaseInputValue = '';

    @observable currentUsersPage = 1;
    @observable totalUsersPages = 0;
    @observable shownCount = 0;
    @observable totalUsersCount = 0;
    @observable pageNumbers = [];
    @observable startUserPage = 1;
    @observable endUserPage = 1;  

    @observable currentOrganizationsPage = 0;
    @observable totalOrganizationsPages = 0;
    @observable totalOrganizationsCount = 0;
    @observable showOrganizationsCount = 0;

    @observable currentBasePage = 1;
    @observable totalBasePages = 7;

    @observable totalDashboardUsers = null;
    @observable totalDashboardUnits = null;
    @observable totalDashboardGoogleGroups = null;
    @observable totalDashboardLogins = null;
    @observable totalDashboardActiveUsers = null;

    @observable deleteUserId = null;
    @observable deleteOrgId = null;

    @observable selectedOrganizations = [];
    @observable selectedChapters = [];
    @observable selectedOffices = [];
    @observable selectedPositions = [];
    @observable mergedOrganizations = [];

    @observable viewedOrgPositionsList = [];
    @observable viewedOrgUsersList = [];
    @observable viewedOrgUsersListPage = 1;
    @observable viewedOrgUsersListTotalPages = 0;

    @observable updatedOrganizationUsersList = [];
    @observable updatedOrgUsersListPage = 1;
    @observable updatedOrgUsersListTotalPages = 0;

    @observable uniqueOrganizationTypes = [...defaultOrgTypes];
    @observable editedOrganizationData = {};
    @observable editedOrganizationPositions = [];
    @observable editedOrganizationUsersList = [];
    @observable selectOrganizationList = [];
    @observable selectedParent = {};
    @observable editedOrgId = null;
    

    @observable createOrganizationMode = null;
    @observable editOrganizationMode = null;
    @observable filterRole = 'asc';
    @observable selectedOrgType = [];

    @observable totalDashboardMailchimpGroups = 0;
    @observable totalDashboardEditedUsers = 0;
    @observable newPosition = {};
    @observable addressesValid = false;
    @observable phonesValid = false;
    @observable emailsValid = false;
    @observable organizationsValid = false;
    @observable positionsValid = false; //TODO change status
    @observable roleValid = false;
    @observable organizationFormValid = false;
    @observable userOrganizationsList = [];
    @observable filteredOrganizationList = [];
    @observable newPosition = {};
    @observable userOrganizationPositions = [];

    @observable showAddressesValidationErrors = false;
    @observable showPhonesValidationErrors = false;
    @observable showEmailsValidationErrors = false;
    @observable showOrgValidationErrors = false;
    @observable showPosValidationErrors = false;

    @observable importTotalCount = 0;
    @observable successfulImportCount = 0;
    @observable importMessageShow = false;

    @observable viewedOrganizationId = null;
    @observable addedUsersBeforeUnitCreate = [];
    @observable unitsTree = [];
    @observable fullSuccess = false;
    @observable dashboardOrganizations = [];
    
    @action getLink = async() => {
        const { data } = await CatalogServices.getAuthLink();
        this.link = data.link;
    }

    @action setUserToken = async() => {
        this.isInitialLoading = true;
        const response = await CatalogServices.getCodeFromGoogle(this.code);

        if (!response.data.error || !response.data.error_description) {
            const token = response.data.session.token;
            const user = response.data.user;
            
            if (token) {
                this.isAuthenticated = true;
                this.token = token;
                localStorage.setItem('auth_token', token);
            }

            if (user) {
                localStorage.setItem('user_id', user.id);
                localStorage.setItem('user_role_id', user.role_id);
                this.current_user_id = user.id;
                this.current_user_role_id = user.role_id;
                this.isInitialLoading = false;
            }   
            
        }
    }

    @action getDashboard = async() => {
        this.isLoading = true;
        const { data } = await CatalogServices.getDashboardData();

        if (data) {
            const { organizations, google, sessions, users, groups } = data;
            this.totalDashboardUsers = users.total;
            this.totalDashboardUnits = organizations.total;
            this.totalDashboardGoogleGroups = google.groups;
            this.totalDashboardMailchimpGroups = groups.mailchimp;
            this.totalDashboardLogins = sessions.total;
            this.totalDashboardEditedUsers = users.info_edit_count;
            this.totalDashboardActiveUsers = sessions.online;
        }
        this.isLoading = false;
    }


    @action getCurrentUser = async (id) => {
        this.isLoading = true;
        this.isAvatarLoading = true;
        const { data } = await CatalogServices.getUser(id);

        if (data) {
            localStorage.setItem('user', JSON.stringify(data))

            this.user = data;
            this.isAvatarLoading = data.avatar_url ? false : true; 
            this.user.emails_users = data.emails_users.sort((a,b) => {return b.primary-a.primary});

            const filteredAddresses = data.addresses_users.filter((item)=>item.primary);
            const filteredPhones = data.phones_users.filter((item)=>item.primary);
            const filteredEmails = data.emails_users.filter((item)=>item.primary);        
            

            if (data.addresses_users.length === 0) {
                const primaryMailItems = [{
                    active: 1,
                    primary: true,
                    value: '',
                }];
                
                this.user.addresses_users = [...primaryMailItems];            
            }
            if (filteredAddresses.length>1) {
                const newArray = data.addresses_users.map((item, index) => {
                    if(index === 0) {
                        return {...item}
                    } else {
                        return {
                            ...item,
                            primary: false,
                        }
                    }
                }) 
                this.user.addresses_users = [...newArray];
            }
            
            if (data.phones_users.length === 0) {
                const phoneItem = [{
                    active: 1,
                    primary: true,
                    value: '',
                    phone_type: 'personal'
                }]
                this.user.phones_users = [...phoneItem];
            } 

            if (filteredPhones.length>1) {
                const newArray = data.phones_users.map((item, index) => {
                    if(index === 0) {
                        return {...item}
                    } else {
                        return {
                            ...item,
                            primary: false,
                        }
                    }
                }) 
                this.user.phones_users = [...newArray];
            }            

            if (!data.emails_users.length && !filteredEmails.length && !data.primary_email){
                const primaryEmailItem = [{
                    active: 1,
                    primary: true,
                    value: '',
                }];
                this.user.emails_users = [...primaryEmailItem];
            }
            if (data.emails_users.length && data.primary_email) {       
                const uniqueEmail = data.emails_users.filter((email) => email.value !== data.primary_email); 
                const duplicateEmail = data.emails_users.filter((email) => email.value === data.primary_email).map((item) => {return {...item, active: 0}});  
                if(filteredEmails.length) {
                    const newArray = uniqueEmail.map((item) => {
                        return {
                            ...item,
                            primary: false,
                        }                   
                    }) 
                    this.user.emails_users = [...newArray, ...duplicateEmail];
                }  
            }
        }

        this.isLoading = false;
        
    }

    @action deleteUser = async (id) => {
        this.isLoading = true;
        await CatalogServices.deleteUser(id);

        this.isLoading = false;
    }

    @action getEditedUser = async (id) => {
        this.isLoading = true;
        const { data } = await CatalogServices.getUser(id);

        if (data) {
            this.editedUser = data;
            this.updatedUserInfo = data;
            this.updatedUserAddress = data;

            this.editedUser.emails_users = data.emails_users.sort((a,b) => {return b.primary-a.primary});
            this.updatedUserAddress.emails_users = data.emails_users.sort((a,b) => {return b.primary-a.primary});;

            const filteredAddresses = data.addresses_users.filter((item)=>item.primary);
            const filteredPhones = data.phones_users.filter((item)=>item.primary);
            const filteredEmails = data.emails_users.filter((item)=>item.primary);

            if (data.addresses_users.length === 0) {
                const primaryMailItems = [{
                    active: 1,
                    primary: true,
                    value: '',
                }];
                
                this.editedUser.addresses_users = [...primaryMailItems];
                this.updatedUserAddress.addresses_users = [...primaryMailItems];
            } 

            if (filteredAddresses.length>1) {
                const newArray = data.addresses_users.map((item, index) => {
                    if(index === 0) {
                        return {...item}
                    } else {
                        return {
                            ...item,
                            primary: false,
                        }
                    }
                }) 
                this.editedUser.addresses_users = [...newArray];
                this.updatedUserAddress.addresses_users = [...newArray];
            }   
            
            if (data.phones_users.length === 0) {
                const phoneItem = [{
                    active: 1,
                    primary: true,
                    value: '',
                    phone_type: 'personal'
                }]
                this.editedUser.phones_users = [...phoneItem];
                this.updatedUserAddress.phones_users = [...phoneItem];
            } 

            if (filteredPhones.length>1) {
                const newArray = data.phones_users.map((item, index) => {
                    if(index === 0) {
                        return {...item}
                    } else {
                        return {
                            ...item,
                            primary: false,
                        }
                    }
                }) 
                this.editedUser.phones_users = [...newArray];
                this.updatedUserAddress.phones_users = [...newArray];
            }            

            if (!data.emails_users.length && !filteredEmails.length && !data.primary_email){
                const primaryEmailItem = [{
                    active: 1,
                    primary: true,
                    value: '',
                }];
                this.editedUser.emails_users = [...primaryEmailItem];
                this.updatedUserAddress.emails_users = [...primaryEmailItem];
            }

            if (data.emails_users.length && data.primary_email) {
                const uniqueEmail = data.emails_users.filter((email) => email.value !== data.primary_email); 
                const duplicateEmail = data.emails_users.filter((email) => email.value === data.primary_email).map((item) => {return {...item, active: 0}}); 
                if(filteredEmails.length) {
                    const newArray = uniqueEmail.map((item) => {
                            return {
                                ...item,
                                primary: false,
                            }                       
                    }) 
                    this.editedUser.emails_users = [...newArray, ...duplicateEmail];
                    this.updatedUserAddress.emails_users = [...newArray, ...duplicateEmail];
                } else {
                    this.editedUser.emails_users = [...uniqueEmail, ...duplicateEmail];
                    this.updatedUserAddress.emails_users = [...uniqueEmail, ...duplicateEmail];
                }  
            }

            const unique = data.organizations.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                    t.id === thing.id
                ))
            )
            
            const filteredOrg = unique.filter((org) => org.org_type === "organization");
            const filteredCpt = unique.filter((org) => org.org_type === "chapter");
            const filteredOff = unique.filter((org) => org.org_type === "office");
            const filteredDep = unique.filter((org) => org.org_type === "department");
            
            const selectedOrganizations = filteredOrg.length ? this.userOrganizationsList.organizations.filter(o1 => unique.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : this.userOrganizationsList.organizations.map((item) => {return {...item, selected: false}});
            const unSelectedOrganizations = filteredOrg.length ? this.userOrganizationsList.organizations.filter(o1 => unique.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}}) : this.userOrganizationsList.organizations.filter(o1 => unique.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}})
            const mergedOrg = unSelectedOrganizations.filter(o1 => selectedOrganizations.some(o2 => o1.id !== o2.id));
            this.userOrganizations.organizations = [...selectedOrganizations, ...mergedOrg].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );
           
            const selectedChapters = filteredCpt.length ? this.userOrganizationsList.chapters.filter(o1 => unique.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : this.userOrganizationsList.chapters.map((item) => {return {...item, selected: false}})
            const unSelectedChapters = filteredCpt.length ? this.userOrganizationsList.chapters.filter(o1 => unique.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}}) : this.userOrganizationsList.chapters.map((item) => {return {...item, selected: false}})
            const mergedCpt = unSelectedChapters.filter(o1 => selectedChapters.some(o2 => o1.id !== o2.id));
            this.userOrganizations.chapters = [...selectedChapters, ...mergedCpt].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );;

            const selectedOffices = filteredOff.length ? this.userOrganizationsList.offices.filter(o1 => unique.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : this.userOrganizationsList.offices.map((item) => {return {...item, selected: false}})
            const unSelectedOffices = filteredOff.length ? this.userOrganizationsList.offices.filter(o1 => unique.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}}) : this.userOrganizationsList.offices.map((item) => {return {...item, selected: false}})
            const mergedOfc = unSelectedOffices.filter(o1 => selectedOffices.some(o2 => o1.id !== o2.id));
            this.userOrganizations.offices = [...selectedOffices, ...mergedOfc].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );

            const selectedDepartments = filteredDep.length ? this.userOrganizationsList.departments.filter(o1 => unique.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : this.userOrganizationsList.departments.map((item) => {return {...item, selected: false}})
            const unSelectedDepartments = filteredDep.length ? this.userOrganizationsList.departments.filter(o1 => unique.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}}) : this.userOrganizationsList.departments.map((item) => {return {...item, selected: false}})
            const mergedDep = unSelectedDepartments.filter(o1 => selectedDepartments.some(o2 => o1.id !== o2.id));
            this.userOrganizations.departments = [...selectedDepartments, ...mergedDep].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );

            // const uniquePositions = data.organizations.filter((org)=>org.position).map((org) => {
            //     return org.position
            // })

            // const selectedPositions = uniquePositions.length ? this.userPositions.filter(o1 => uniquePositions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : this.userPositions.map((item) => {return {...item, selected: false}})
            // const unSelectedPositions = uniquePositions.length ? this.userPositions.filter(o1 => uniquePositions.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}}) : this.userPositions.map((item) => {return {...item, selected: false}})
            // const mergedPos = unSelectedPositions.filter(o1 => selectedPositions.some(o2 => o1.id !== o2.id));

            // this.userPositions = [...selectedPositions, ...mergedPos].filter((thing, index, self) =>
            //     index === self.findIndex((t) => (
            //     t.id === thing.id
            //     ))
            // );
           
        }
        this.isLoading = false;
    }

    @action getFilteredOrganizations = async(config = {}) => {
        this.isLoading = true;
        if (this.selectedOrgType.length>0 && this.selectedOrgType !=='' ) {
            config['filter_org_type'] = this.selectedOrgType
        }

        const { data } = await CatalogServices.getOrganizations(config);

        if (data) {
            const filteredOrganizationList = data.result || data;
            this.filteredOrganizationList = filteredOrganizationList;

            this.currentOrganizationsPage = data.page;
            this.showOrganizationsCount = filteredOrganizationList.length ? filteredOrganizationList.length + (this.currentOrganizationsPage-1)*10 : filteredOrganizationList.length;
            this.totalOrganizationsCount = data.total;
            const totalPages = Math.ceil(data.total / 10);
            this.totalOrganizationsPages = totalPages;
        }
        this.isLoading = false;
    }

    @action getDashboardOrganizations = async(page) => {
        const limit = 4;

        const config = {
            limit: limit,
            page: page,
        }
        const { data } = await CatalogServices.getOrganizations(config);
        return data.result;
    }

    @action getOrganizations = async() => {
        const limit = 100;

        const config = {
            limit: limit,
            page: 1,
        }

        //this.isLoading = true;

        const { data } = await CatalogServices.getOrganizations(config);

        if (data) {
            const totalPages = Math.ceil(data.total / limit);
            let organizationList = data.result || data;
            if(totalPages > 1) {
                for(let i = 0; i < totalPages; i++) {
                    if(i>0) {
                        const { data } = await CatalogServices.getOrganizations({limit: 100, page: i+1});
                        const newUnits = data.result || data;
                        organizationList = [...organizationList, ...newUnits];
                    }
                }

            }
            this.organizationList = organizationList;

            const organizations = organizationList.filter((organization) => organization.org_type === 'organization').map((org) => {
                return {
                    ...org,
                    selected: false,
                }});
            const chapters = organizationList.filter((organization) => organization.org_type === 'chapter').map((org) => {
                return {
                    ...org,
                    selected: false,
                }});
            const offices = organizationList.filter((organization) => organization.org_type === 'office').map((org) => {
                return {
                    ...org,
                    selected: false,
                }
            });
            const departments = organizationList.filter((organization) => organization.org_type === 'department').map((org) => {
                return {
                    ...org,
                    selected: false,
                }
            });

            const userOrganizations = {
                organizations: [...organizations],
                chapters: [...chapters],
                offices: [...offices],
                departments: [...departments],
            }
            this.userOrganizations = userOrganizations;
            this.userOrganizationsList = userOrganizations;
            localStorage.setItem('organizations', JSON.stringify(userOrganizations));
        }
        //this.isLoading = false;
    }

    @action getPositions = async() => {
        const { data } = await CatalogServices.getPositions();

        if (data) {
            const positions = data;
            const userPositions = positions.map((pos) => {
                return {
                    ...pos,
                    selected: false,
                }
            });
            this.userPositions = userPositions;
            localStorage.setItem('positions', JSON.stringify(userPositions));

            const positionFilterOptions = userPositions.map((position) => {
                return {...position, selected: false}
            })
            const newPositionArray = positionFilterOptions;
            const mergedPositionOptions = [].concat.apply([], newPositionArray);
            const uniquePositionOptions = mergedPositionOptions.reduce(
                (accumulator, current) => accumulator.some(x => x.id === current.id)? accumulator: [...accumulator, current ], []
            );
            this.uniquePositionOptions = uniquePositionOptions;
            const positionFilterOptionsSelected = uniquePositionOptions.filter((option) => option.selected)
            this.positionFilterOptionsSelected = positionFilterOptionsSelected.map((item) => item.id);
        }
    }

    @action createNewPosition = async(position) => {
        await CatalogServices.createPosition(position);
    }

    @action deletePositionFromOrg = async(orgId, posId) => {
        await CatalogServices.deletePositionFromOrg(orgId, posId);
    }

    @action getTags = async() => {
        const { data } = await CatalogServices.getTags();

        if(data) {
            const tagFilterOptions = data.map((option) => {
                return {name: option, selected: false}
            })
            this.uniqueTagOptions = tagFilterOptions;
            const tagFilterOptionsSelected = tagFilterOptions.filter((option) => option.selected)
            this.tagFilterOptionsSelected = [...tagFilterOptionsSelected];
            this.tagFilterOptionsSelectedName = tagFilterOptionsSelected.map((tag)=>tag.name);
        }

    }

    @action getRoles = async() => {
        const { data } = await CatalogServices.getRoles();

        if (data) {
            if(this.current_user_role_id === 1) {
                const roles = data;
                const userRoles = roles.map((pos) => {
                    return {
                        ...pos,
                        selected: false,
                    }
                });
                this.userRoles = userRoles;
                localStorage.setItem('roles', JSON.stringify(userRoles));
            }
            else if(this.current_user_role_id === 2) {
                const roles = data;
                const userRoles = roles.filter((pos) => pos.id !== 1).map((item) => {
                    return {
                        ...item,
                        selected: false,
                    }
                })
                this.userRoles = userRoles;
                localStorage.setItem('roles', JSON.stringify(userRoles));
            }
        }  
    }

    @action getUsersList = async() => {

        const limit = 10;

        const config = {
            limit: limit,
            page: this.currentUsersPage,
            filter_position: this.positionFilterOptionsSelectedId,
            filter_org: this.organizationFilterOptionsSelectedId,
            filter_tags: this.tagFilterOptionsSelectedName,
            order_by_role: this.filterRole,
            search: this.searchInputValue,
        }

        this.isAddressBookLoading = true;
        const response = await CatalogServices.getListOfUsers(config);

        const { data } = response;

        this.users = data.result.map((user) => {
            return {
                checked: false,
                ...user,
            }
        })

        this.shownCount = this.users.length ? this.users.length + (this.currentUsersPage-1)*limit : this.users.length;

        this.totalUsersCount = data.total;
        const totalPages = Math.ceil(data.total / limit)
        this.totalUsersPages = totalPages;

        let pageNumbers = [];

        for (let i = 1; i <= Math.ceil(totalPages); i++) {
            pageNumbers.push(i);
        }
        this.pageNumbers = pageNumbers;
        this.endUserPage = totalPages;
        
        this.isAddressBookLoading = false;
    }

    @action getOrgUsersList = async() => {
        this.isAddressBookLoading = true;

        const config = {
            limit: 10,
            page: this.updatedOrgUsersListPage,
            search: this.searchOrgUsersInputValue,
        }

        const response = await CatalogServices.getListOfUsersUnit(config);
        const { data } = response;

        if(data.page !== 1) {
            this.updatedOrganizationUsersList = [...this.updatedOrganizationUsersList, ...data.result];
        } else {
            this.updatedOrganizationUsersList = data.result;
        }

        if(!this.searchOrgUsersInputValue.length) {
            this.updatedOrgUsersListPage = 1;
            this.updatedOrgUsersListTotalPages = 0;
        } else {
            this.updatedOrgUsersListTotalPages = Math.ceil(data.total / 10);
        }
        
        this.isAddressBookLoading = false;
    }

    @action updateCurrentUser = async(id) => {
        this.isLoading = true;

        const updatedUser = {...this.updatedUserInfo, ...this.updatedUserAddress};
        const response = await CatalogServices.updateUser(id, updatedUser);

        if (response.statusText === "OK") {
            this.isLoading = false;
            this.updateDetailsSuccess = true;
            this.isUserModalShown = false;
        } else {
            this.isLoading = false;
            this.updateDetailsSuccess = false; 
        }
    }

    @action updateUserFromList = async(id) => {
        this.isLoading = true;

        const updatedUserAddress = {
            ...this.updatedUserAddress,
            organizations: this.updatedUserAddress.organizations.map((org) => {return {organization_id: org.organization_id, position_id: org.position.id}})
        }
  
        const updatedUser = {...this.updatedUserInfo, ...updatedUserAddress};
        const response = await CatalogServices.updateUser(id, updatedUser);

        if (response.statusText === "OK") {
            this.isLoading = false;
            this.updateDetailsSuccess = true;
            this.isUserModalShown = false;    
            this.getUsersList();
            if(id === this.current_user_id) {
                this.getCurrentUser(id);
            }
        } else {
            this.isLoading = false;
            this.updateDetailsSuccess = false; 
        }
    }

    @action updateUserOrganizations = async(id, orgId) => {
        this.isLoading = true;
  
        const organization = [orgId];
        const response = await CatalogServices.updateUser(id, organization);

        if (response.statusText === "OK") {
            this.isLoading = false;
            this.updateDetailsSuccess = true;
            this.isUserModalShown = false;    
            this.getUsersList();

        } else {
            this.isLoading = false;
            this.updateDetailsSuccess = false; 
        }
    }

    @action createNewUser = async() => {
        this.isLoading = true;

        const organizations = this.updatedUserAddress.organizations.map((org) => {return {organization_id: org.organization_id, position_id: org.position.id}})
        const password = generatePassword(8);
        this.password = password;
        
        const updatedUserAddress = {
            ...this.updatedUserAddress,
            organizations: organizations,
        }
        const updatedUser = {password: password, ...this.updatedUserInfo, ...updatedUserAddress};

        const response = await CatalogServices.createUser(updatedUser);
        
        if (response.statusText === "Created") {
            this.isLoading = false;
            this.success = true;
            this.formAddressValid = false;
            this.updatedUserInfo = defaultUserInfo;
            this.updatedUserAddress = defaultUserAddress; 
            this.getUsersList();
        } else {
            this.isLoading = false;
            this.success = false; 
        }

    }

    @action signOut = async() => {
        localStorage.clear();
        await CatalogServices.logOut();
    }

    @action getEditedOrganization = async(id, loading) => {
        this.isLoading = loading;

        const responseData = await CatalogServices.getEditedOrganization(id);

        this.editedOrgId = responseData.data.id;
        this.editedOrganizationData = {
            org_type: responseData.data.org_type,
            title: responseData.data.title,
            name: responseData.data.name,
            parent_id: responseData.data.parent_id,
        }

        let selectOrganizationList = [];
        if (responseData.data.org_type === "organization") {
            selectOrganizationList = [];
        } else if (responseData.data.org_type === "chapter") {
            selectOrganizationList = [...this.organizationList.filter((item) => item.org_type === "organization")].map((item) => {
                const path = store.unitsTree.find((unit) => unit.id === item.id).path.slice(-1);
                return {
                    ...item,
                    path: path
                }
            });
        } else if (responseData.data.org_type === "office") {
            selectOrganizationList = [...this.organizationList.filter((item) => item.org_type === "chapter")].map((item) => {
                const path = store.unitsTree.find((unit) => unit.id === item.id).path.slice(-2).join(' | ');
                return {
                    ...item,
                    path: path
                }
            });
        } else if (responseData.data.org_type === "department") {
            selectOrganizationList = [...this.organizationList.filter((item) => item.org_type === "office")].map((item) => {
                const path = store.unitsTree.find((unit) => unit.id === item.id).path.slice(-3).join(' | ');
                return {
                    ...item,
                    path: path
                }
            });
        } else if (responseData.data.org_type === "other") {
            selectOrganizationList = [...this.organizationList.filter((item) => item.org_type === "department")];
        } else {
            selectOrganizationList = [];
        };

        this.selectOrganizationList = selectOrganizationList;
        const selectedParent = selectOrganizationList.filter((organization) => organization.id === responseData.data.parent_id)[0];
        this.selectedParent = selectedParent;

        const config = {
            limit: 100,
        }

        const responsePositions = await CatalogServices.getOrganizationPositions(id);
        this.viewedOrgPositionsList = responsePositions.result || responsePositions.data.result;
        this.editedOrganizationPositions = (responsePositions.result || responsePositions.data.result).map((pos) => {return {...pos, existPosition: true}});

        const responseUsersList = await CatalogServices.getOrganizationUsers(id, config);
        this.editedOrganizationUsersList = responseUsersList.data.result;

        await KnowledgeBaseStore.getUnitList(id);
        this.isLoading = false;
    }

    @action getOrganizationPositions = async(id) => {
        //this.isLoading = true;
        const { data } = await CatalogServices.getOrganizationPositions(id);
        this.editedOrganizationPositions = data.result.map((pos) => {return {...pos, existPosition: true}});
        this.viewedOrgPositionsList = data.result;
        //this.isLoading = false;
    }

    @action getOrganizationUsers = async(id) => {

        const config = {
            limit: 10,
            page: this.viewedOrgUsersListPage,
        }

        this.isAddressBookLoading = true;
        const { data } = await CatalogServices.getOrganizationUsers(id, config)
        this.viewedOrgUsersList = [...this.viewedOrgUsersList, ...data.result];
        this.viewedOrgUsersListTotalPages = Math.ceil(data.total / 10);

        this.isAddressBookLoading = false;
    }

    @action createNewOrganization = async() => {
        this.isLoading = true;
        const positions = this.editedOrganizationPositions.map((pos)=>pos.id);
        const updatedOrganization = {...this.editedOrganizationData, positions: positions };

        const response = await CatalogServices.createOrganization(updatedOrganization);

        if (response.statusText === "Created") {
            const id = response.data.id;
            if (id && this.addedUsersBeforeUnitCreate.length) {
                for (let i=0; i < this.addedUsersBeforeUnitCreate.length; i++) {
                    const userId = this.addedUsersBeforeUnitCreate[i].id;
                    const userPositions = this.addedUsersBeforeUnitCreate[i].positions.filter((pos) => pos.selected);

                    let updateOrganizations = [];
                    userPositions.forEach((pos) => {
                        updateOrganizations.push({
                            organization_id: id,
                            position_id: pos.id
                        })
                    })
                    const userOrganizations = {
                        organizations: updateOrganizations,
                    }

                    await CatalogServices.updateUser(userId, userOrganizations);
                }
            }
            if(id && KnowledgeBaseStore.unitListBeforeAddingUnit.length) {
                for (let j=0; j < KnowledgeBaseStore.unitListBeforeAddingUnit.length; j++) {
                    const groupId = KnowledgeBaseStore.unitListBeforeAddingUnit[j].id;

                    const data = {
                        filters: [{
                            organization_id: id,
                        }],  
                    }

                    await KnowledgeBaseService.edit(groupId, data)
                }
            }
            this.isLoading = false;
            this.success = true;
            this.editedOrganizationData = {};
            //this.getFilteredOrganizations();
        } else {
            this.isLoading = false;
            this.success = false; 
        }
    }

    @action updateOrganization = async() => {
        this.isLoading = true;
        const positions = this.editedOrganizationPositions.map((pos)=>pos.id);

        const updatedOrganization = {...this.editedOrganizationData, positions: positions };

        const response = await CatalogServices.updateOrganization(this.editedOrgId, updatedOrganization);

        if (response.statusText === "OK") {
            this.isLoading = false;
            this.updateDetailsSuccess = true;
            this.isOrganizationModalShown = false;  
        } else {
            this.isLoading = false;
            this.updateDetailsSuccess = false; 
        }
    }

    @action getOrganizationsTree = async () => {
        //this.isLoading = true;
        const { data } = await CatalogServices.getOrganizationsTree();

        const unitsTree = data;
        this.unitsTree = unitsTree;
        const organizationFilterOptions = unitsTree.filter((item) => item.org_type === 'organization').map((organization) => {
            return {...organization, selected: false}
        })
        const chapterFilterOptions = unitsTree.filter((item) => item.org_type === 'chapter').map((organization) => {
            return {...organization, selected: false}
        })
        const officeFilterOptions = unitsTree.filter((item) => item.org_type === 'office').map((organization) => {
            return {...organization, selected: false}
        })

        const newOrganizationArray = organizationFilterOptions;
        const newChapterArray = chapterFilterOptions;
        const newOfficeArray = officeFilterOptions;

        const mergedOrganizationOptions = [].concat.apply([], newOrganizationArray);
        const mergedChapterOptions = [].concat.apply([], newChapterArray);
        const mergedOfficeOptions = [].concat.apply([], newOfficeArray);

        const uniqueOrganizationOptions = mergedOrganizationOptions.reduce(
            (accumulator, current) => accumulator.some(x => x.id === current.id)? accumulator: [...accumulator, current ], []
        );
        const uniqueChapterOptions = mergedChapterOptions.reduce(
            (accumulator, current) => accumulator.some(x => x.id === current.id)? accumulator: [...accumulator, current ], []
        );
        const uniqueOfficeOptions = mergedOfficeOptions.reduce(
            (accumulator, current) => accumulator.some(x => x.id === current.id)? accumulator: [...accumulator, current ], []
        );

        this.uniqueOrganizationOptions = uniqueOrganizationOptions;
        this.uniqueChapterOptions = uniqueChapterOptions;
        this.uniqueOfficeOptions = uniqueOfficeOptions;

        const organizationFilterOptionsSelected = uniqueOrganizationOptions.filter((option) => option.selected)
        const chapterFilterOptionsSelected = uniqueChapterOptions.filter((option) => option.selected)
        const officeFilterOptionsSelected = uniqueOfficeOptions.filter((option) => option.selected)

        this.organizationFilterOptionsSelected = organizationFilterOptionsSelected;
        this.chapterFilterOptionsSelected = chapterFilterOptionsSelected;
        this.officeFilterOptionsSelected = officeFilterOptionsSelected;
        
        this.organizationFilterOptionsSelectedId = [...organizationFilterOptionsSelected, ...chapterFilterOptionsSelected, ...officeFilterOptionsSelected].map((item) => item.id)

        //this.isLoading = false;
    }

    @action deleteOrganization = async (id) => {
        this.isLoading = true;
        await CatalogServices.deleteOrganization(id);

        this.isLoading = false;
    }

    @action onCheckboxOrgValidationInitial = () => {
        const totalOrg = this.userOrganizations.organizations.filter((item)=> item.selected);
        const totalCpt = this.userOrganizations.chapters.filter((item)=> item.selected);
        const totalOfc = this.userOrganizations.offices.filter((item)=> item.selected);
        const totalDep = this.userOrganizations.departments.filter((item)=> item.selected);

        const selected = [...totalOrg, ...totalCpt, ...totalOfc, ...totalDep].length;

        if(!totalOrg.length && !selected) {
            this.organizationsValid = false; 
        } else {
            store.organizationsValid = true; 
        }
    }

    @action onCheckboxOrgValidation = () => {
        // const totalOrg = this.userOrganizations.organizations.filter((item)=> item.selected);
        // const totalCpt = this.userOrganizations.chapters.filter((item)=> item.selected);
        // const totalOfc = this.userOrganizations.offices.filter((item)=> item.selected);

        // const selected = [...totalOrg, ...totalCpt, ...totalOfc].length;

        if(!this.organizationsValid) {
            this.showOrgValidationErrors = true;
        } else {
            this.showOrgValidationErrors = false;
        }
    }

    @action onCheckboxPosValidationInitial = () => {
        const totalPos = this.userPositions.filter((item)=> item.selected).length;

        if(!totalPos) {
            this.positionsValid = false;
        } else {
            store.positionsValid = true;
        }
    }

    @action onCheckboxPosValidation = () => {
        if(!this.positionsValid) {
            this.showPosValidationErrors = true;
        } else {
            this.showPosValidationErrors = false;
        }
    }

    @action exportContacts = async(format) => {
        this.isLoading = true;
        await CatalogServices.exportContacts(format);

        this.isLoading = false;
    }

    @action importContacts = async(file) => {
        this.importMessageShow = true;

        const formData = new FormData();
        formData.append('file', file);

        const { data } = await CatalogServices.importContacts(formData);

        if(data.file_errors.length === 0) {
            this.fullSuccess = true;
            this.importMessageShow = true;
            this.getUsersList();

            this.importTotalCount = data.total;
            this.successfulImportCount = data.successful;  
        } else {
            this.error = data;
            this.getUsersList();
        }
    }
} 

const store = new Store();

reaction(
    () => store.code,
    (code) => {
        if (code) {
            store.setUserToken();
        }   
    }
);

reaction(
    () => store.token,
    async token => {
        if (token) {
            store.isAuthenticated = true;
        } else {
            store.isAuthenticated = false;
        }
    }
);

reaction(
    () => store.current_user_id,
    async id => {
        if (id) {
            store.getCurrentUser(id);
        }
});

reaction(
    () => store.current_user_role_id,
    (id) => {
        if (id === 1 || id === 2) {
            store.getOrganizations();
            store.getPositions();
            store.getTags();
            store.getRoles();
        }
});

reaction(
	() => store.searchInputValue.length,
	() => {
		store.getUsersList();
	}, {delay: 1000}
);

reaction(
	() => store.searchOrgUsersInputValue.length,
	() => {
        if(store.searchOrgUsersInputValue.length) {
            store.getOrgUsersList();
        } else {
            store.getOrganizationUsers(store.editedOrgId)
        }
	}, {delay: 500}
);

reaction(
	() => store.currentUsersPage,
	() => {
        store.getUsersList();
	}
);

reaction(
    ()=>store.createUserMode,
    () => {
        if (store.createUserMode === true) {
            store.userOrganizations.organizations = store.userOrganizations.organizations.map((org) => {return {...org, selected: false}})
            store.userOrganizations.chapters = store.userOrganizations.chapters.map((org) => {return {...org, selected: false}})
            store.userOrganizations.offices = store.userOrganizations.offices.map((org) => {return {...org, selected: false}})
            store.userOrganizations.departments = store.userOrganizations.departments.map((org) => {return {...org, selected: false}})
            store.userPositions = store.userPositions.map((org) => {return {...org, selected: false}})
        }
    }
)

reaction(
    ()=>store.createOrganizationMode,
    () => {
        if (store.createOrganizationMode === true) {
            store.editedOrganizationPositions = []
            store.editedOrganizationUsersList = []
            store.editedOrganizationData = {
                org_type: 'Not Selected'
            }
        }
    }
)

reaction(
    ()=>store.accountSettingsMode,
    () => {
        if (store.createOrganizationMode === true) {
            store.editedOrganizationPositions = []
            store.editedOrganizationUsersList = []
            store.editedOrganizationData = {}
        }
    }
)

reaction(
	() => store.filterRole,
	() => {
        store.currentUsersPage = 1;
        store.getUsersList();
	}
);

reaction(
	() => store.addressesValid,
	() => {
        if (store.addressesValid && store.positionsValid && store.phonesValid && store.organizationsValid && store.roleValid && store.emailsValid) {
            store.formAddressValid = true;
        }
	}
);

reaction(
	() => store.phonesValid,
	() => {
        if (store.addressesValid && store.positionsValid && store.phonesValid && store.organizationsValid && store.roleValid && store.emailsValid) {
            store.formAddressValid = true;
        }
	}
);

reaction(
	() => store.emailsValid,
	() => {
        if (store.addressesValid && store.positionsValid && store.phonesValid && store.organizationsValid && store.roleValid && store.emailsValid) {
            store.formAddressValid = true;
        }
	}
);

reaction(
	() => store.organizationsValid,
	() => {
        if (store.addressesValid && store.positionsValid && store.phonesValid && store.organizationsValid && store.roleValid && store.emailsValid) {
            store.formAddressValid = true;
        }
	}
);

reaction(
	() => store.positionsValid,
	() => {
        if (store.addressesValid && store.positionsValid && store.phonesValid && store.organizationsValid && store.roleValid && store.emailsValid) {
            store.formAddressValid = true;
        }
	}
);

reaction(
	() => store.roleValid,
	() => {
        if (store.addressesValid && store.positionsValid && store.phonesValid && store.organizationsValid && store.roleValid && store.emailsValid) {
            store.formAddressValid = true;
        }
	}
);

reaction(
    () => store.newPosition,
    async (position) => {
        await store.createNewPosition(position);
        await store.getPositions();

        const updatePositions = store.userPositions.filter((pos) => pos.name === position.name);
        const newPositions = [...store.editedOrganizationPositions, ...updatePositions];
        store.editedOrganizationPositions = newPositions;

        if(store.editedOrgId) {
            const positions = newPositions.map((pos)=>pos.id);
            const updatedOrganization = {...store.editedOrganizationData, positions: positions };
            await CatalogServices.updateOrganization(store.editedOrgId, updatedOrganization);
            store.getOrganizationPositions(store.editedOrgId);
        }
    }
)

reaction(
    () => store.editedOrganizationData,
    () => {
        store.organizationFormValid = store.editedOrganizationData.title && store.editedOrganizationData.org_type
    }
)

reaction(
	() => KnowledgeBaseStore.searchValue.length,
	() => {
        if(store.isOrganizationModalShown) {
            KnowledgeBaseStore.list({limit: 100, search: KnowledgeBaseStore.searchValue});
        } else if (!store.isOrganizationModalShow) {
            KnowledgeBaseStore.list({search: KnowledgeBaseStore.searchValue});
        }
	}, {delay: 500}
);

export default store;