import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { SelectOrgType, Button, ActionsMenu } from '../../helper/components';
import Spinner from '../Spinner';
import Pagination from '../Pagination/PaginationV2';
import store from '../../stores';
import ooo from '../../images/ooo.svg';

import './organizationUnits.scss';
import { 
    TITLE, 
    TYPE, 
    ORGANIZATION, 
    ACTION, 
    PARENT_UNIT, 
    NO_RECORDS_FOUND, 
    ORGANIZATION_UNITS,
    ADD_UNIT,
    ALL_UNITS
} from '../../helper/strings';


export const OrganizationUnitsGrid = ({dashboardView, items, match}) => {
    const func = (item) => {
        if (item) {
            if(item.org_type === "organization") {
                return item.title;
            } else {
                const parentUnit = store.unitsTree.filter((par) => par.id === item.parent_id)[0];
                return func(parentUnit)                
            }
        } else return '-'
    }

    const onViewUsersClick = (id) => {
        store.getOrganizationUsers(id);
        store.getOrganizationPositions(id);
        store.getOrgUsersList();
        store.viewedOrganizationId = id;
        store.isViewUsersModalShown = true;

    }

    const onViewPositionClick = (id) => {
        store.getOrganizationPositions(id);
        store.isViewPositionsModalShown = true;
    }

    const onEditPopup = (id) => {
        store.getEditedOrganization(id, true);
        store.getOrganizationPositions(id);
        store.getOrganizationUsers(id);

        store.organizationFormValid = false;
        store.isOrganizationModalShown = true;
        store.editOrganizationMode = true;

    }
  
    const onDeletePopup = (id) => {
        store.deleteOrgId = id;
        store.isDeleteModalShown = true;
        store.editedOrganization = false;
    }

    const onFixOrgType = (orgtype) => {
        switch(orgtype) {
            case 'organization':
                return 'organization';
            case 'chapter':
                return 'sub-org';
            case 'office':
                return 'department';
            case 'department':
                return 'committee';
            case 'other':
                return 'other';

            default:
                return '-';
        }
    }

    return (
        <table className="organization-units-grid">
            <thead>
                <tr>
                    <th>{TITLE}</th>
                    {!dashboardView && <th>{TYPE}</th>}
                    <th>{PARENT_UNIT}</th>
                    <th>{ORGANIZATION}</th>
                    <th>Positions</th>
                    <th>Users</th>
                    {!dashboardView && <th>{ACTION}</th>}
                </tr>
            </thead>
            {store.isLoading ?
                <tbody>
                    <tr>
                        <td colSpan="8">
                            <Spinner />
                        </td>
                    </tr>  
                </tbody>
                :
                items.length ?
                <tbody>
                    {items.map((item, index) => {
                        const parentUnit = store.unitsTree.filter((par) => item.parent_id === 0 ? par.id === item.parent_id : par.id === item.parent_id);
                        return (
                            <tr key={index}>
                                <td>
                                    {item.title}
                                </td>
                                {!dashboardView &&
                                    <td className="org-type">
                                        {onFixOrgType(item.org_type)}
                                    </td>
                                }
                                <td>
                                    {parentUnit.length ? parentUnit[0].title : '-'}
                                </td>
                                <td>
                                    {item && func(item)}
                                </td>
                                <td>
                                    <span>{item.count_positions}</span>
                                    <Link to={`${match.url}/view_position`}><span onClick={()=>onViewPositionClick(item.id)}>view</span></Link>
                                </td>
                                <td>
                                    <span>{item.count_users}</span>
                                    <Link to={`${match.url}/view_user`}><span onClick={()=>onViewUsersClick(item.id)}>view</span></Link>
                                </td>
                                {!dashboardView &&
                                <td className="actions">
                                    <img src={ooo} alt='toogle-actions' />
                                    <ActionsMenu onEditPopup={()=>onEditPopup(item.id)} onDeletePopup={()=>onDeletePopup(item.id)} />
                                </td>
                                }
                            </tr>
                        )
                    })
                    }
                </tbody>
                :
                <tbody className="no-results-body">
                    <tr>
                        <td colSpan="8">
                            <div className="no-results">{NO_RECORDS_FOUND}</div>
                        </td>
                    </tr>  
                </tbody>
            }
        </table>
    )
}

@observer
class OrganizationUnits extends Component {

    state = {
        selectedItem: '',
    }

    async componentDidMount() {
        await store.getOrganizationsTree();
        await store.getFilteredOrganizations();
        store.getOrganizations();
    }

    componentWillUnmount() {
        store.selectedOrgType = [];
    }

    onClick = () => {
        store.createOrganizationMode = true;
        store.isOrganizationModalShown = true;
        store.organizationFormValid = false;
        store.addedUsersBeforeUnitCreate = [{
            id: store.user.id, 
            first_name: store.user.first_name, 
            last_name: store.user.last_name,
            positions: []
        }];
    }

    onChange = (e) => {
        const selectedItem = e.target.value;

        switch(selectedItem) {
            case ALL_UNITS:
                store.selectedOrgType = [];
                break;
            case 'organization':
                store.selectedOrgType = ['organization'];
                break;
            case 'sub-org':
                store.selectedOrgType = ['chapter'];
                break;
            case 'department':
                store.selectedOrgType = ['office'];
                break;
            case 'committee':
                store.selectedOrgType = ['department'];
                break;

            default:
                return null;
        }
        this.setState({selectedItem});
        store.getFilteredOrganizations();
    }

    changeCurrentPage = (page) => {
        store.getFilteredOrganizations({page})
    }

    changePagedUp = () => {
        this.changeCurrentPage(store.currentOrganizationsPage + 1);
    }

    changePagedDown = () => {
        this.changeCurrentPage(store.currentOrganizationsPage - 1);
    }
    
    render() {
        const { filteredOrganizationList, uniqueOrganizationTypes, currentOrganizationsPage, totalOrganizationsPages, showOrganizationsCount, totalOrganizationsCount, isLoading } = store;
        return (
            <div className='organization-units-container'>
                <div className='block-header'>
                    <div>
                        <div className='title'>{ORGANIZATION_UNITS}</div>
                        <div>{showOrganizationsCount} of {totalOrganizationsCount} units shown</div>
                    </div>
                    
                    <div className='control-board'>
                        <SelectOrgType label='Select Item' disabled='' options={uniqueOrganizationTypes} onChange={this.onChange} value={this.state.selectedItem} allUnits={ALL_UNITS}/>
                        <Button className='button-primary' value={ADD_UNIT} onClick={this.onClick} />
                    </div>
                </div>    
                <div>
                    <OrganizationUnitsGrid 
                        dashboardView={false} 
                        items={filteredOrganizationList} 
                        match={this.props.match} 
                    />
                </div>
                {totalOrganizationsPages>0 && !isLoading && 
                    <div className="pagination">
                        <Pagination currentPage={currentOrganizationsPage} totalPages={totalOrganizationsPages}
                                    changeCurrentPage={this.changeCurrentPage}
                                    changePagedUp={this.changePagedUp}
                                    changePagedDown={this.changePagedDown}/>
                    </div>}
            </div>
        );
    }  
}

export default withRouter(OrganizationUnits);