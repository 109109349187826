const DASHBOARD = 'Dashboard';
const ADDRESS_BOOK = 'Address Book';
const KNOWLEDGE_BASE = 'Knowledge Base';
const ORGANIZATION_UNITS = 'Organization Units';
const TOTAL_COUNT_OF_USERS = 'Total counts of users';
const TOTAL_COUNT_OF_UNITS = 'Total count of offices / units';
const TOTAL_GROUPS_IN_GOOGLE = 'Total groups in Google';
const TOTAL_MAILCHIMP_LIST = 'Total Mailchimp lists';
const ACTIVITY_LOG = 'Activity Log';
const NUMBER_OF_LOGINS = 'Number of logins:';
const USERS_UPDATES_THEIR_INFO = 'Users updates their info:';
const ACTIVE_USERS_IN_THE_SYSTEM = 'Active users in the system:';
const FILE_FORMAT = 'File format';
const CSV = 'CSV';
const PDF = 'PDF';
const VCF = 'VCF';
const VCF_EXPORT_CONDITION = '(1 user should be selected)';
const ORGANIZATION = 'Organization';
const ORGANIZATIONS = 'organizations';
const SUBORG = 'Sub-Org';  //changed labels
const CHAPTERS = 'chapters';
const DEPARTMENT = 'Department';  //changed labels
const OFFICES = 'offices';
const COMMITTEE = 'Committee';  //changed labels
const POSITION = 'Position';
const POSITIONS = 'positions';
const TAG = 'Tags';
const TAGS = 'tags';
const CREATE_GROUP = 'Create Group';
const DELETE = 'Delete';
const IMPORT = 'Import';
const EXPORT = 'Export';
const ADD_NEW_USER = '+ Add New User';
const NAME = 'Name';
const EMAIL = 'Email';
const ROLE = 'Role';
const ACTION = 'Action';
const TYPE = 'Type';
const TITLE = 'Title';
const DESCRIPTION = 'Description';
const TOTAL_MEMBERS = 'Total Members';
const STATUS = 'Status';
const CREATED_AT = 'Created At';
const NO_RECORDS_FOUND = 'No records found';
const CREATE_GROUP_PRIMARY = '+ Create Group';
const PARENT_UNIT = 'Parent Unit';
const ADD_UNIT = '+ Add Unit';
const ALL_UNITS = 'All Units';
const LINK = 'link';
const LINKED = 'linked';
const SUCCESS = 'Success';
const ADD = 'Add';
const REMOVE = 'Remove';
const ACTIVE = 'active';
const INACTIVE = 'inactive';
const ARCHIVED = 'archived';
const LOAD_MORE = 'Load More';
const CHANGES_WERE_SAVED = 'Changes were saved.';
const ARE_YOU_SURE_TO_DELETE_THE_LINE = 'Are you sure you want to delete the line?';
const INFO_WAS_SAVED_SUCCESSFULLY = 'Info was saved successfully.'
const EDIT_GROUP = 'Edit Group';
const CREATE = 'Create';
const EDIT = 'Save';
const IMPORT_WAS_SUCCESSFUL = 'Import was successfull';
const IS_INCORRECT = ' is incorrect';
const IS_INVALID = ' is invalid';
const MUST_BE_SELECTED = ' must be selected';
const NEXT = 'Next';
const BACK = 'Back';
const USER_DETAILS = 'User Details';
const ADDRESS = 'Address';
const ADD_PRIMARY = '+ Add';
const UPLOAD_PHOTO = 'Upload Photo';
const INFORMATION = 'Information';

export {
    DASHBOARD,
    ADDRESS_BOOK,
    KNOWLEDGE_BASE,
    ORGANIZATION_UNITS,
    TOTAL_COUNT_OF_USERS,
    TOTAL_COUNT_OF_UNITS,
    TOTAL_GROUPS_IN_GOOGLE,
    TOTAL_MAILCHIMP_LIST,
    ACTIVITY_LOG,
    NUMBER_OF_LOGINS,
    USERS_UPDATES_THEIR_INFO,
    ACTIVE_USERS_IN_THE_SYSTEM,
    FILE_FORMAT,
    CSV,
    PDF,
    VCF,
    VCF_EXPORT_CONDITION,
    ORGANIZATION,
    ORGANIZATIONS,
    SUBORG,
    CHAPTERS,
    DEPARTMENT,
    OFFICES,
    COMMITTEE,
    POSITION,
    POSITIONS,
    TAG,
    TAGS,
    CREATE_GROUP,
    DELETE,
    IMPORT,
    EXPORT,
    ADD_NEW_USER,
    NAME,
    EMAIL,
    ROLE,
    ACTION,
    TYPE,
    TITLE,
    DESCRIPTION,
    TOTAL_MEMBERS,
    STATUS,
    CREATED_AT,
    NO_RECORDS_FOUND,
    CREATE_GROUP_PRIMARY,
    PARENT_UNIT,
    ADD_UNIT,
    ALL_UNITS,
    LINK,
    LINKED,
    SUCCESS,
    ADD,
    REMOVE,
    ACTIVE,
    INACTIVE,
    ARCHIVED,
    LOAD_MORE,
    CHANGES_WERE_SAVED,
    ARE_YOU_SURE_TO_DELETE_THE_LINE,
    INFO_WAS_SAVED_SUCCESSFULLY,
    EDIT_GROUP,
    CREATE,
    EDIT,
    IMPORT_WAS_SUCCESSFUL,
    IS_INCORRECT,
    IS_INVALID,
    MUST_BE_SELECTED,
    NEXT,
    BACK,
    USER_DETAILS,
    ADDRESS,
    ADD_PRIMARY,
    UPLOAD_PHOTO,
    INFORMATION
}