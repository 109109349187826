import { jsonParse } from './storageHelpers';

const storageAccessToken = jsonParse(localStorage.getItem('auth_token'));
const storageCurrentUserId = JSON.parse(localStorage.getItem('user_id'));
const storageCurrentUserRole = JSON.parse(localStorage.getItem('user_role_id'));
const storageCurrentUser = JSON.parse(localStorage.getItem('user'));
const storageOrganizations = JSON.parse(localStorage.getItem('organizations'));
const storageChapters = JSON.parse(localStorage.getItem('chapters'));
const storageOffices = JSON.parse(localStorage.getItem('offices'));
const storagePositions = JSON.parse(localStorage.getItem('positions'));
const storageRoles = JSON.parse(localStorage.getItem('roles'));

export {
    storageAccessToken,
    storageCurrentUserId,
    storageCurrentUserRole,
    storageCurrentUser,
    storageOrganizations,
    storageChapters,
    storageOffices,
    storagePositions,
    storageRoles
}