import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormInformation from './FormInformation/FormInformation';
import FormAddress from './FormAddress/FormAddress';
import Spinner from '../Spinner';
import store from '../../stores';
import './accountSettings.scss';

@observer
class AccountSettings extends Component {

  state = {
    checkValidation: true,
  }

  componentDidMount() {
    store.getOrganizationsTree();
  }

  onClick = async(e) => {
    if (store.formInfoValid && store.addressesValid && store.phonesValid && store.emailsValid) {
      e.preventDefault();
      const id = store.current_user_id;
      await store.updateCurrentUser(id);
      store.getCurrentUser(id);
      this.setState((state) => {return {...state, checkValidation: false}})
    } else {
      this.setState((state) => {return {...state, checkValidation: true}})
      this.onShowValidationErrors()
    }
  }

  onShowValidationErrors = () => {
    if (!store.addressesValid) {
        store.showAddressesValidationErrors = true
    }
    if (!store.phonesValid) {
        store.showPhonesValidationErrors = true
    }
    if (!store.emailsValid) {
        store.showEmailsValidationErrors = true
    }
}

  render() {
    return (
      <div className="account-details-container">
          <div className="title">
              Account Settings
          </div>
          {(store.isInitialLoading || store.isLoading) ? <Spinner /> : 
            <form className="form">    
              <FormInformation user={store.user ? store.user : store.defaultUser} accountSettingsMode={true} checkValidation={this.state.checkValidation} />
              <FormAddress user={store.user ? store.user : store.defaultUser} accountSettingsMode={true} />            
              <div className="submit-btn-container">
                <button type="submit" className="submit-btn" onClick={this.onClick}>Update</button>
              </div>
            </form>
          }
      </div>
    );
  }
}

export default AccountSettings;