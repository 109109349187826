import React, { Fragment } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import AccountSettings from '../AccountSettings/AccountSettings';
import Dashboard from '../Dashboard';
import AddressBook from '../AddressBook/AddressBook';
import KnowledgeBase from '../KnowledgeBase';
import OrganizationUnits from '../OrganizationUnits';
import store from '../../stores';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
          store.isAuthenticated ?
                store.current_user_role_id === 3 ? 
                (<Fragment>
                  {props.location.pathname === "/" ? <Redirect to="/account_settings" component={AccountSettings} /> : <Redirect to={props.location.pathname} />}
                  <Component {...props} >
                    <Route path="/account_settings" component={AccountSettings} />
                    <Route path="/address_book" component={AddressBook} />
                  </Component>
                </Fragment>)
                :
                (<Fragment>
                  {props.location.pathname === "/" ? <Redirect to="/dashboard" component={Dashboard} /> : <Redirect to={props.location.pathname} />}
                  <Component {...props} >
                    <Route path="/account_settings" component={AccountSettings} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/address_book" component={AddressBook} />
                    <Route path="/knowledge_base" component={KnowledgeBase} />
                    <Route path="/organization_units" component={OrganizationUnits} />
                  </Component>
                </Fragment>)
            : <Redirect to="/login" />
        )} />
    );
};

export default withRouter(PrivateRoute);