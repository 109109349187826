import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import LoginPage from '../LoginPage';
import Spinner from  '../Spinner';
import AdminPage from '../pages/AdminPage';
import ViewUsersModal from '../Modal/ViewUsersModal';
import ViewPositionsModal from '../Modal/ViewPositionsModal';
import Portal from '../Modal/Portal';
import store from '../../stores';

@observer
class MainRouter extends Component {
  render() {
    return (
        <Fragment>
        {window.location.href.includes("code=") && store.error && <Redirect to='/login' />}
        {store.isAuthenticated === true && <Redirect to='/' />}
         <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/auth_callback" render={Spinner} />
            <PrivateRoute path="/" component={AdminPage} />
          </Switch>
          <Route path={`${this.props.match.url}/view_user`} render={()=><Portal onClick={()=>this.props.history.push(this.props.match.url)}><ViewUsersModal /></Portal>} />
          <Route path={`${this.props.match.url}/view_position`} render={()=><Portal onClick={()=>this.props.history.push(this.props.match.url)}><ViewPositionsModal /></Portal>} />
        </Fragment>
      );
  }
}

export default withRouter(MainRouter);
