import React, { Component } from 'react';
import { observer } from 'mobx-react';
import UsersData from './UsersData';

import store from '../../../stores/Store';

@observer
class UsersDropdown extends Component {

    state = {
        isUsersOpen: true,
    }

    onToggle = () => {
        this.setState({...this.state, isUsersOpen: !this.state.isUsersOpen})
    }
    
    render() {
        const { isUsersOpen } = this.state;

        return (
            <div className="users">
                <div className="toggler">
                    <div className={`toggle-checkbox ${isUsersOpen ? 'open' : ''}`} onClick={this.onToggle}></div>
                    <div className="label">Users ({this.props.users.length})</div>
                </div>
                <div className={`opened-info ${isUsersOpen ? 'open' : ''}`}>
                    <div className="graphic-indicator">
                        <div></div>
                        <div></div>
                    </div>
                    <UsersData users={this.props.users} orgId={this.props.orgId} positionList={store.editedOrganizationPositions} addedUsersBeforeUnitCreate={store.addedUsersBeforeUnitCreate} />
                </div>       
            </div>
        )
    }
}

export default UsersDropdown;