import React, {Component} from 'react';
import {observer} from 'mobx-react';
import ModalHeader from './ModalWindowElements/ModalHeader';


import Spinner from '../Spinner';
import Portal from './Portal';

import './modal.scss';
import OrganizationFilter from '../AccountSettings/FormAddress/OrganizationsFilter';

@observer
class GroupDetailsModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            chapters: [],
            offices: [],
            positions: [],
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isShown !== this.props.isShown) {
            if(this.props.isShown && this.props.item) {
                const { filters } = this.props.item;

                const organizations = filters.filter((org) => org.org_type === 'organization').map((org) => org.title);
                const chapters = filters.filter((org) => org.org_type === 'chapter').map((org) => org.title);
                const offices = filters.filter((org) => org.org_type === 'office').map((org) => org.title);
                const positions = filters.map((org) => org.position && org.position.title);
                 
                this.setState({...this.state, organizations: organizations, chapters: chapters, offices: offices, positions: positions})
            }
        }
    }

    render() {
        const {isShown, toggler, item, loaded, formatDate} = this.props;

        if (isShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className="modal-window">
                            <div className='modal-body'>
                                <div>
                                    <div>
                                        <ModalHeader value="Group Details" onModalClose={() => toggler(null)}/>
                                        {loaded ? (
                                            <div className="scroll-block-units">
                                                <div className="add-edit-body">
                                                    <div className="group-information">
                                                        <div className="block-title">Information</div>
                                                        <div className="content">{item.title}</div>
                                                        <div className="grid-group-information">
                                                            <div className="grid-item">
                                                                <div className="details-label">Type</div>
                                                                <div className="content">{item.source}</div>
                                                            </div>
                                                            <div className="grid-item">
                                                                <div className="details-label">Status</div>
                                                                <div className="content">{item.status}</div>
                                                            </div>
                                                            <div className="grid-item">
                                                                <div className="details-label">Total Members</div>
                                                                <div className="content">{item.users.length}</div>
                                                            </div>
                                                        </div>
                                                        {item.source === "google" && 
                                                            <div>
                                                                <div className="details-label">Group Email</div>
                                                                <div className="description">{item.email}</div>
                                                            </div>
                                                        }
                                                        <div>
                                                            <div className="details-label">Description / Notes</div>
                                                            <div className="description">{item.description}</div>
                                                        </div>
                                                    </div>
                                                    <div className="group-filters">
                                                        <div className="block-title">Filters</div>
                                                        <OrganizationFilter dataset={this.props.item.filters} viewDetailGroup={true} />
                                                        <div>
                                                            <div className="details-label">Tags</div>
                                                            <div className="tags">
                                                                <div className="items">
                                                                    {item.tags.map((_item, index)=><div key={index}>{_item.name}</div>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-group-information">
                                                            <div className="grid-item">
                                                                <div className="details-label">Created At</div>
                                                                <div className="content">{formatDate(item.created_at)}</div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="grid-group-filters date">
                                                            <div className="grid-item">
                                                                <div className="details-label">Last Message Sent</div>
                                                                <div>2019/03/03 1:00pm</div>
                                                            </div>
                                                            <div className="grid-item">
                                                                <div className="details-label">Last Activity</div>
                                                                <div>2019/03/03 1:00pm</div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (<Spinner/>)}
                                        <div className="add-edit-footer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
        return null;
    }
}

export default GroupDetailsModal;