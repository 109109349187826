import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Input, Button, Checkbox, ActionsMenu, StatusMenu} from '../../helper/components';
import Pagination from '../Pagination/PaginationV2';
import Spinner from '../Spinner';
import GroupModal from '../Modal/GroupModal';
import GroupDetailsModal from '../Modal/GroupDetailsModal';
import DeleteGroupModal from '../Modal/DeleteGroupModal';

import KnowledgeBaseStore from '../../stores/KnowledgeBaseStore';

import filter from '../../images/filter.svg';
import switcher from '../../images/switcher.svg';
import ooo from '../../images/ooo.svg';
// import {IKnowledgeItem, IKnowledgeList} from './InterfaceKnowledgeBase';
import store from '../../stores/Store';
import {
    KNOWLEDGE_BASE,
    TYPE,
    TITLE,
    DESCRIPTION,
    TOTAL_MEMBERS,
    STATUS,
    CREATED_AT,
    ACTION,
    NO_RECORDS_FOUND,
    DELETE,
    CREATE_GROUP_PRIMARY
} from '../../helper/strings';

import './knowledgeBase.scss';

@observer
class KnowledgeBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isStatusListOpen: false,
            isShownModalCreate: false,
            isShownModalEdit: false,
            isShownModalDetail: false,
            isShownModalDeleteGroup: false,
            deleteGroupId: null,
            checkedIds: [], 
            order_by_members: 'asc',
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    }

    componentDidMount() {
        KnowledgeBaseStore.list();
        store.getOrganizationsTree();
    }

    componentWillUnmount() {
        KnowledgeBaseStore.searchValue = '';
    }

    handleCheckboxChange = (id) => {
        const {checkedIds} = this.state;
        if (checkedIds.includes(id)) {
            const index = checkedIds.findIndex((item) => {
                return item === id
            });
            checkedIds.splice(index, 1);
        } else {
            checkedIds.push(id)
        }

        this.setState({checkedIds: checkedIds, deleteGroupId: null})
    }

    handleCheckboxChangeAll = () => {
        const {checkedIds} = this.state;
        const {items} = KnowledgeBaseStore;

        let newCheck = []

        if (checkedIds.length <= 0) {
            newCheck = items.map((item) => item.id)
        }

        this.setState({...this.state, checkedIds: newCheck, deleteGroupId: null})
    };

    onChange = (e) => {
        KnowledgeBaseStore.searchValue = e.target.value;
    };

    onCreateModalToggle = () => {
        KnowledgeBaseStore.success = false;
        KnowledgeBaseStore.defaultGroup.filters = [];
        this.setState({...this.state, isShownModalCreate: !this.state.isShownModalCreate})
    };

    onEditModalToggle = () => {
        KnowledgeBaseStore.success = false;
        this.setState({...this.state, isShownModalEdit: !this.state.isShownModalEdit})
    };

    onDeleteModalToggle = () => {
        KnowledgeBaseStore.success = false;
        this.setState({...this.state, isShownModalDeleteGroup: !this.state.isShownModalDeleteGroup})
    };

    onEditLinePopup = async (id = null) => {
        let isShownModalEdit = false;
        if (id !== null) {
            isShownModalEdit = true;
            await this.getDetailsInfo(id);
        }
        this.setState({...this.state, isShownModalEdit: isShownModalEdit})
    };

    onDeleteSingleLinePopup = (id) => {
        let isShownModalDeleteGroup = false;
        if (id !== null) {
            isShownModalDeleteGroup = true;
        }
        this.setState({...this.state, isShownModalDeleteGroup: isShownModalDeleteGroup, deleteGroupId: id, checkedIds: []})
    };

    onDeleteMultipleLinePopup = (arr) => {
        let isShownModalDeleteGroup = false;
        if (arr.length) {
            isShownModalDeleteGroup = true;
        }
        this.setState({...this.state, isShownModalDeleteGroup: isShownModalDeleteGroup, deleteGroupId: null, checkedIds: arr})
    };

    onViewLineDetails = async (id = null) => {
        let isShownModalDetail = false;
        if (id !== null) {
            isShownModalDetail = true;
            this.getDetailsInfo(id);
        }
        this.setState({...this.state, isShownModalDetail: isShownModalDetail})
    };

    getDetailsInfo(id) {
        return KnowledgeBaseStore.show(id);
    }

    onToggle = () => {
        this.setState({...this.state, isStatusListOpen: !this.state.isStatusListOpen})
    };

    changeCurrentPage = (page) => {
        KnowledgeBaseStore.list({page})
    }

    changePagedUp = () => {
        this.changeCurrentPage(KnowledgeBaseStore.page + 1);
    }

    changePagedDown = () => {
        this.changeCurrentPage(KnowledgeBaseStore.page - 1);
    }

    onDeleteGroups = async() => {
        const { deleteGroupId, checkedIds } = this.state;
        const { deleteGroup, list } = KnowledgeBaseStore;

        async function asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
              await callback(array[index], index, array);
            }
        }

        const deleteGroups = async(arr) => {
            await asyncForEach(arr, async (id) => {
                await deleteGroup(id)
            })
            list();
            KnowledgeBaseStore.success = true;
        }
        
        if (deleteGroupId) {
            await deleteGroup(deleteGroupId)
            list();
            KnowledgeBaseStore.success = true;
        } else if (checkedIds.length){
            await deleteGroups(checkedIds);
        }
    }

    orderByMembers = (order) => {
        KnowledgeBaseStore.list({order_by_members: order});
        if (order === 'asc') {
            this.setState({...this.state, order_by_members: 'desc'})
        } else {
            this.setState({...this.state, order_by_members: 'asc'})
        }
    }

    onStatusFilter = (filter) => {
        KnowledgeBaseStore.list({filter_status: filter});
        this.setState({...this.state, isStatusListOpen: false})
    }

    formatDate = (date) => {
        const d = new Date(date);
        const newDate = d.getUTCDate();
        const newMonth = d.getUTCMonth()+1;
        const newYear = d.getUTCFullYear();
        const newHours = d.getUTCHours();
        let newMinutes = d.getUTCMinutes();

        function addZeroBefore(n) {
            return (n < 10 ? '0' : '') + n;
        }

        return `${newMonth}/${newDate}/${newYear} ${newHours}:${addZeroBefore(newMinutes)}`;
    }

    render() {
        const {isLoading, page, totalPages, items, singleItem, singleItemLoaded, searchValue, defaultGroup, totalGroupsCount, shownGroupsCount} = KnowledgeBaseStore;
        const {checkedIds, isShownModalCreate, isShownModalEdit, isShownModalDetail, isShownModalDeleteGroup, deleteGroupId, order_by_members} = this.state;

        return (
            <>
                <div className='knowledge-base-container'>
                        <>
                            <div className="title">{KNOWLEDGE_BASE}</div>
                            <div style={{textTransform: 'lowercase'}}>{shownGroupsCount} of {totalGroupsCount} groups shown</div>
                            <div className="panel">
                                <div className="search-input">
                                    <Input type="text" onChange={this.onChange} placeholder='Search' value={searchValue} />
                                </div>
                                <div className="control-buttons">
                                    <Button className="button-secondary" value={DELETE}
                                            disabledDelete={!checkedIds.length}
                                            onClick={()=>this.onDeleteMultipleLinePopup(checkedIds)}/>
                                    <Button className="button-primary" value={CREATE_GROUP_PRIMARY}
                                            onClick={this.onCreateModalToggle}/>
                                </div>
                            </div>
                            <table className="user-list">
                                <thead>
                                <tr>
                                    <th>
                                        <Checkbox
                                            id="checkedAllUsers"
                                            className='checkbox_checkedAll'
                                            checked={checkedIds.length > 0}
                                            onChange={this.handleCheckboxChangeAll}
                                        />
                                    </th>
                                    <th>{TYPE}</th>
                                    <th>{TITLE}</th>
                                    <th>{DESCRIPTION}</th>
                                    <th>
                                        <span>{TOTAL_MEMBERS}</span>
                                        <img src={switcher} alt='role_filter' onClick={()=>this.orderByMembers(order_by_members)} />
                                    </th>
                                    <th className="status">
                                        <span>{STATUS}</span>
                                        <img src={filter} alt='filter' onClick={this.onToggle}/>
                                        <StatusMenu isOpen={this.state.isStatusListOpen} onStatusFilter={this.onStatusFilter} />
                                    </th>
                                    <th>{CREATED_AT}</th>
                                    {/* <th>Last Message Sent</th>
                                    <th>Bounce Rate</th> */}
                                    <th className="actions">{ACTION}</th>
                                </tr>
                                </thead>
                                {isLoading ?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner />
                                            </td>
                                        </tr>  
                                    </tbody>
                                    :
                                    items.length ?
                                    <tbody>
                                        {items.map((item, index) => {
                                            const {source, title, description, count_users, status, created_at, 
                                                  //last_message_sent, bounce_rate,
                                                  } = item;
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Checkbox
                                                            id={index}
                                                            className="checkbox"
                                                            checked={checkedIds.includes(item.id)}
                                                            onChange={(e) => this.handleCheckboxChange(item.id)}
                                                        />
                                                    </td>
                                                    <td>{source}</td>
                                                    <td>{title}</td>
                                                    <td>{description}</td>
                                                    <td>{count_users}</td>
                                                    <td>{status}</td>
                                                    <td>{this.formatDate(created_at)}</td>
                                                    {/* <td>{last_message_sent}</td>
                                                    <td>{bounce_rate}</td> */}
                                                    <td className="actions">
                                                        <img src={ooo} alt='toogle-actions'/>
                                                        <ActionsMenu onViewDetails={() => this.onViewLineDetails(item.id)}
                                                                    onEditPopup={() => this.onEditLinePopup(item.id)}
                                                                    onDeletePopup={() => this.onDeleteSingleLinePopup(item.id)} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    :
                                    <tbody className="no-results-body">
                                        <tr>
                                            <td colSpan="8">
                                                <div className="no-results">{NO_RECORDS_FOUND}</div>
                                            </td>
                                        </tr>  
                                    </tbody>
                                }
                            </table>
                            {totalPages>0 && !isLoading && <div className="pagination">
                                <Pagination currentPage={page} totalPages={totalPages}
                                            changeCurrentPage={this.changeCurrentPage}
                                            changePagedUp={this.changePagedUp}
                                            changePagedDown={this.changePagedDown}/>
                            </div>}
                            <GroupModal isShown={isShownModalCreate}
                                        toggler={this.onCreateModalToggle}
                                        editedGroup={false}
                                        item={defaultGroup}
                                        loaded={true}
                                        createGroupMode={true}
                                        filters={defaultGroup && KnowledgeBaseStore.defaultGroup.filters} 
                                        users={[]}
                                        />
                            <GroupModal isShown={isShownModalEdit}
                                        toggler={this.onEditModalToggle}
                                        editedGroup={true}
                                        item={singleItem}
                                        loaded={singleItemLoaded}
                                        editGroupMode={true} 
                                        filters={singleItem && KnowledgeBaseStore.singleItem.filters} />
                            <GroupDetailsModal isShown={isShownModalDetail}
                                               toggler={this.onViewLineDetails}
                                               item={singleItem}
                                               loaded={singleItemLoaded}
                                               formatDate={this.formatDate} />
                            <DeleteGroupModal isShown={isShownModalDeleteGroup}
                                              toggler={this.onDeleteModalToggle}
                                              deleteGroups={this.onDeleteGroups}
                                              deleteId={deleteGroupId}
                                              deleteIds={checkedIds} />
                        </>
                </div>
            </>)
    }
}

export default KnowledgeBase;