import React, { Component } from 'react';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import "react-dates/initialize";
import 'react-dates/lib/css/_datepicker.css';

import './datePickerBlock.scss';
  
class DatePickerBlock extends Component {

constructor() {
  super();
  this.state = {
    focused: false,
    focusedInput: null,
    singlePickerSelected: false,
    rangePickerSelected: false,
  };
}

componentDidMount() {
  window.addEventListener('click', this.clickHandler)
}

componentWillUnmount() {
  window.removeEventListener('click', this.clickHandler)
}

clickHandler = (e) => {
  const { disabledSingle, disabledRange } = this.props;
  if (e.target.id === 'your_unique_id' && !disabledSingle) {
    this.setState({...this.state, singlePickerSelected: true, rangePickerSelected: false})
  }
  if ((e.target.id === 'your_unique_start_date_id' || e.target.id === 'your_unique_end_date_id') && !disabledRange) {
    this.setState({...this.state, singlePickerSelected: false, rangePickerSelected: true})
  }
}

onHandleCheckbox = (e) => {
  const { disabledSingle, disabledRange } = this.props;
  if (e.target.id === 'radio-1' && !disabledSingle) {
    this.setState({...this.state, singlePickerSelected: true, rangePickerSelected: false})
  }
  if (e.target.id === 'radio-2' && !disabledRange) {
    this.setState({...this.state, singlePickerSelected: false, rangePickerSelected: true})
  }
}

render() {
  const { singlePickerSelected, rangePickerSelected,  } = this.state;
  const { onChangeSingleDate, index, date, onChangeDateRange, startDate, endDate, disabledSingle, disabledRange } = this.props;

  return (
      <>
        <div className="details-label">Date Joined</div>
        <div className="datepicker-container">
        <div className="radio" >
            <input id="radio-1" name="radio" type="radio" checked={singlePickerSelected} onChange={(e)=>this.onHandleCheckbox(e)} />
            <label htmlFor="radio-1" className="radio-label">
                <SingleDatePicker
                    date={date}
                    onDateChange={(date) => onChangeSingleDate(date, index)}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    id="your_unique_id"
                    showDefaultInputIcon
                    inputIconPosition="after"
                    numberOfMonths={1}
                    daySize={20}
                    monthFormat='YYYY MMMM'
                    isOutsideRange={() => false}
                    disabled={disabledSingle}
                    keepOpenOnDateSelect
                />
            </label>
            </div>
            <div className="radio">
            <input id="radio-2" name="radio" type="radio" checked={rangePickerSelected} onChange={(e)=>this.onHandleCheckbox(e)} />
            <label htmlFor="radio-2" className="radio-label">
                <DateRangePicker
                    startDate={startDate}
                    startDateId="your_unique_start_date_id" 
                    endDate={endDate} 
                    endDateId="your_unique_end_date_id" 
                    onDatesChange={({ startDate, endDate }) => onChangeDateRange(startDate, endDate, index)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    showDefaultInputIcon
                    inputIconPosition="after"
                    monthFormat='YYYY MMMM'
                    isOutsideRange={() => false}
                    daySize={25}
                    disabled={disabledRange}
                    keepOpenOnDateSelect
                    anchorDirection={window.innerWidth > 1550 ? "right" : "left"}
                />
            </label>
            </div>
        </div>
    </>
  );
} 
}

export default DatePickerBlock;



  
  