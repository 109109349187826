import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { PaginationButton } from '../../helper/components';
import store from '../../stores';

import './pagination.scss';

@observer
class Pagination extends Component {

    componentDidMount() {
        const {totalPages} = this.props;
        if (totalPages > 5) {
            store.endUserPage = 5;
        } else {
            store.endUserPage = totalPages;
        }
    }

    componentDidUpdate() {
        const {currentPage, totalPages} = this.props;
            if (totalPages <= 5) {
                // less than 5 total pages so show all
                    store.startUserPage = 1;
                    store.endUserPage = totalPages;
            } else {
                // more than 5 total pages so calculate start and end pages
                if (currentPage <= 3) {
                    store.startUserPage = 1;
                    store.endUserPage = 5;
                } else if (currentPage === totalPages-2){
                    store.startUserPage = currentPage - 2;
                    store.endUserPage = totalPages;
                } else if (currentPage === totalPages-1){
                    store.startUserPage = currentPage - 3;
                    store.endUserPage = totalPages;
                } else if (currentPage === totalPages){
                    store.startUserPage = currentPage - 4;
                    store.endUserPage = totalPages;
                } else if (currentPage > totalPages-2){
                    store.endUserPage = totalPages;
                } else {
                    store.startUserPage = currentPage - 2;
                    store.endUserPage = currentPage + 2;
                }
            }
        }
     

    buttonIcon = (className) => 
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            {className === 'back' && <path d="M5.85254 11.1719C5.71712 11.3073 5.57324 11.3073 5.4209 11.1719L0.114258 5.83984C-0.0380859 5.70443 -0.0380859 5.56901 0.114258 5.43359L5.4209 0.101562C5.57324 -0.0338542 5.71712 -0.0338542 5.85254 0.101562L6.36035 0.609375C6.5127 0.744792 6.5127 0.888672 6.36035 1.04102L1.76465 5.63672L6.36035 10.2324C6.5127 10.3848 6.5127 10.5286 6.36035 10.6641L5.85254 11.1719Z" fill="#353334"/>}
            {className === 'forward' && <path d="M0.62207 0.101562C0.757487 -0.0338542 0.901367 -0.0338542 1.05371 0.101562L6.36035 5.43359C6.5127 5.56901 6.5127 5.70443 6.36035 5.83984L1.05371 11.1719C0.901367 11.3073 0.757487 11.3073 0.62207 11.1719L0.114258 10.6641C-0.0380859 10.5286 -0.0380859 10.3848 0.114258 10.2324L4.70996 5.63672L0.114258 1.04102C-0.0380859 0.888672 -0.0380859 0.744792 0.114258 0.609375L0.62207 0.101562Z" fill="#353334"/>}
        </svg>

    render() {
        const {currentPage, totalPages, changeCurrentPage, changePagedUp, changePagedDown} = this.props;
        const {pageNumbers, startUserPage, endUserPage} = store;
        const backDisabled = currentPage === 1 ? 'disabled' : '';
        const forwardDisabled = currentPage === totalPages ? 'disabled' : '';

        return (
            <div>
                <PaginationButton className={`back ${backDisabled}`} value={this.buttonIcon('back')} disabled={`${backDisabled}`} onClick={() => changePagedDown()} />
                {pageNumbers.map((number, index) => {
                    if(number >= startUserPage && number <= endUserPage) {
                        return <PaginationButton key={index} value={number} className={number === currentPage ? 'active' : ''} onClick={()=> changeCurrentPage(number)} disabled={`${currentPage === number ? 'disabled' : ''}`} />
                    } else return null;
                })
                }
                <PaginationButton className={`forward ${forwardDisabled}`} value={this.buttonIcon('forward')} disabled={`${forwardDisabled}`} onClick={() => changePagedUp()} />
            </div>
        )
    }
}

export default Pagination;