import React, {Component} from 'react';
import {observer} from 'mobx-react';
import ModalHeader from './ModalWindowElements/ModalHeader';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
import GroupTagInput from '../AccountSettings/TagInput/GroupTagInput';
import {Input, SelectGroupItem, Button, Textarea} from '../../helper/components';
import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
import { EMAIL_DOMAIN_PRIMARY, EMAIL_DOMAIN_SECONDARY, EMAIL_FORMAT } from '../../helper/variables';
import KnowledgeBaseStore from '../../stores/KnowledgeBaseStore';
import OrganizationFilter from '../AccountSettings/FormAddress/OrganizationsFilter';
import store from '../../stores/Store';
import { 
    INFO_WAS_SAVED_SUCCESSFULLY, 
    CREATE_GROUP, 
    EDIT_GROUP, 
    EDIT,
    CREATE,
    ACTIVE,
    INACTIVE,
    ARCHIVED
} from '../../helper/strings';

import './modal.scss';

@observer
class GroupModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupId: 0,
            groupTitle: '',
            groupType: '',
            groupStatus: '',
            groupEmail: '',
            groupNotes: '',
            groupTags: [],
            input: '',
            formErrors: {groupTitle: '', groupType: '', groupStatus: '', groupEmail: '', groupNotes: ''},
            groupTitleValid: false,
            groupTypeValid: false,
            groupStatusValid: false,
            groupEmailValid: false,
            groupNotesValid: false,
            formValid: false,
            showErrors: false,
        };
      
        this.handleTagInputChange = this.handleTagInputChange.bind(this);
        this.handleTagInputKeyDown = this.handleTagInputKeyDown.bind(this);
        this.handleRemoveTagItem = this.handleRemoveTagItem.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isShown !== this.props.isShown) {
            if(this.props.isShown && this.props.item) {
                const { id, title, source, status, email, description, tags } = this.props.item;
                const mappingTags = tags.map((tag) => tag.name);
                this.checkValidate();

                this.setState({
                    ...this.state, 
                    groupId: id ? id : 0, 
                    groupTitle: title ? title : '', 
                    groupType: source ? source : '', 
                    groupStatus: status ? status : '', 
                    groupEmail: email ? email : '',  // change depend on response field
                    groupNotes: description ? description : '', 
                    groupTags: mappingTags.length ? mappingTags : [],
                    groupEmailValid: this.props.editedGroup && true,
                })
            }
            if(!this.props.isShown) {
                this.setState({...this.state, showErrors: false, formValid: false, formErrors: {groupTitle: '', groupType: '', groupStatus: '', groupEmail: '', groupNotes: ''}}, ()=>this.checkValidate())
            } 
        }
        if(prevProps.filters !== this.props.filters) {
            this.checkValidate();
        }
    }

    checkValidate = () => {
        const groupTitleValid = this.state.groupTitle.length >= 2;
        const groupTypeValid = this.state.groupType;
        const groupStatusValid = this.state.groupStatus;
        const groupNotesValid = this.state.groupNotes.length > 0;

        let groupEmailValid;
        let groupEmailError;
       
        if (!this.state.groupEmail.match(EMAIL_FORMAT)) {
            groupEmailValid = false;
            groupEmailError = ' is invalid';
        } else if (this.state.groupEmail.match(EMAIL_FORMAT)) {
            if (this.state.groupEmail.split('@')[1] !== EMAIL_DOMAIN_PRIMARY &&
                this.state.groupEmail.split('@')[1] !== EMAIL_DOMAIN_SECONDARY) {
                groupEmailValid = false;
                groupEmailError = ` domain doesn't match @${EMAIL_DOMAIN_PRIMARY} ${EMAIL_DOMAIN_SECONDARY ? ` or @${EMAIL_DOMAIN_SECONDARY}` : ''}`
            } else {
                groupEmailValid = true;
                groupEmailError = '';
            }
        }

        const groupTitleError = groupTitleValid ? '' : ' is invalid';        
        const groupTypeError = groupTypeValid ? '' : ' must be selected';       
        const groupStatusError = groupStatusValid ? '': ' must be selected';
        const groupNotesError = groupNotesValid ? '' : ' is invalid'; 

        this.setState({
            ...this.state,
            formErrors: {groupTitle: groupTitleError, groupType: groupTypeError, groupStatus: groupStatusError, groupEmail: groupEmailError, groupNotes: groupNotesError},
            groupTitleValid: groupTitleValid,
            groupTypeValid: groupTypeValid,
            groupStatusValid: groupStatusValid,
            groupEmailValid: this.props.editedGroup ? true : groupEmailValid,
            groupNotesValid: groupNotesValid,
            showErrors: false,
        }, this.validateForm);
    }

    onChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({...this.state, [name]: value}, () =>  this.validateField(name, value));
    }

    validateField = (fieldName, value) => {
        let fieldValidationErrors = this.state.formErrors;
        let groupTitleValid = this.state.groupTitleValid;
        let groupTypeValid = this.state.groupTypeValid;
        let groupStatusValid = this.state.groupStatusValid;
        let groupEmailValid = this.state.groupEmailValid;
        let groupNotesValid = this.state.groupNotesValid;

      switch(fieldName) {
          case 'groupTitle':
            groupTitleValid = value.length >= 2;
            fieldValidationErrors.groupTitle = groupTitleValid ? '' : ' is invalid';
            break;
          case 'groupType':
            groupTypeValid = value;
            fieldValidationErrors.groupType = groupTypeValid ? '' : ' must be selected';
            break;
          case 'groupStatus':
            groupStatusValid = value;        
            fieldValidationErrors.groupStatus = groupStatusValid ? '' : ' must be selected';
            break;
          case 'groupEmail':
            if (!value.match(EMAIL_FORMAT)) {
                groupEmailValid = false;
                fieldValidationErrors.groupEmail = ' is invalid';
            } else if (value.match(EMAIL_FORMAT)) {
                if (this.state.groupEmail.split('@')[1] !== EMAIL_DOMAIN_PRIMARY &&
                    this.state.groupEmail.split('@')[1] !== EMAIL_DOMAIN_SECONDARY) {
                    groupEmailValid = false;
                    fieldValidationErrors.groupEmail = ` domain doesn't match @${EMAIL_DOMAIN_PRIMARY} ${EMAIL_DOMAIN_SECONDARY ? ` or @${EMAIL_DOMAIN_SECONDARY}` : ''}`
                } else {
                    groupEmailValid = true;
                    fieldValidationErrors.groupEmail = '';
                }
            }
            break;
          case 'groupNotes':
            groupNotesValid = value;
            fieldValidationErrors.groupNotes = groupNotesValid ? '' : ' is invalid';
            break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        groupTitleValid: groupTitleValid,
                        groupTypeValid: groupTypeValid,
                        groupStatusValid: groupStatusValid,
                        groupEmailValid: groupEmailValid,
                        groupNotesValid: groupNotesValid,
                      }, this.validateForm);
      }

      validateForm() {
          if(this.state.groupType === 'google') {
            this.setState({formValid: this.state.groupTitleValid &&
                this.state.groupTypeValid &&
                this.state.groupStatusValid &&
                this.state.groupEmailValid &&
                this.state.groupNotesValid
            });
          } else {
            this.setState({formValid: this.state.groupTitleValid &&
                this.state.groupTypeValid &&
                this.state.groupStatusValid &&
                this.state.groupNotesValid
            });
          }
        
        }
    

    handleTagInputChange = (e) => {       
        this.setState({ input: e.target.value });
    }
    
    handleTagInputKeyDown = (e) => {
        if (e.keyCode === 13) {
            const {value} = e.target;
            e.preventDefault();

            this.setState(state => ({
                groupTags: [...state.groupTags, value],
                input: ''
            }));
        }

        if (this.state.groupTags.length && e.keyCode === 8 && !this.state.input.length) {
            this.setState(state => ({
                groupTags: state.groupTags.slice(0, state.groupTags.length - 1)
            }));
        }        
    }
    
    handleRemoveTagItem = (index) => {
        return () => {
            this.setState(state => ({
                groupTags: state.groupTags.filter((item, i) => i !== index)
        }));
        }
    }

    onCreateGroup = () => {
        const data = this.groupObject();
        if(this.state.formValid) {
            KnowledgeBaseStore.defaultGroup.filters = [];
            KnowledgeBaseStore.createGroup(data)
            this.setState({
                groupId: 0,
                groupTitle: '',
                groupType: '',
                groupStatus: '',
                groupEmail: '',
                groupNotes: '',
                groupTags: [],
                input: '',
                formErrors: {groupTitle: '', groupType: '', groupStatus: '', groupEmail: '', groupNotes: ''},
                groupTitleValid: false,
                groupTypeValid: false,
                groupStatusValid: false,
                groupEmailValid: false,
                groupNotesValid: false,
                formValid: false,
                showErrors: false})
        } else {
            this.setState({...this.state, showErrors: true})
        }
    }

    onEditGroup = (groupId) => {
        const data = this.groupObject();
        if(this.state.formValid) {
            KnowledgeBaseStore.editGroup(groupId, data)
        } else {
            this.setState({...this.state, showErrors: true})
        }
    }

    groupObject = () => {
        const {groupTitle, groupType, groupStatus, groupEmail, groupNotes, groupTags} = this.state;

        const groupFilters = this.props.filters && this.props.filters.map((item) => {
            if (!item.position) {
                return {
                    organization_id: item.organization_id, 
                    joined: item.joined,
                    period_start: item.period_start,
                    period_end: item.period_end,
                }
            } else {
                return {
                    organization_id: item.organization_id, 
                    position_id: item.position.id,
                    joined: item.joined,
                    period_start: item.period_start,
                    period_end: item.period_end,
                }
            }
        })
      
        const object = {
            title: groupTitle,
            source: groupType,
            status: groupStatus,
            description: groupNotes,
            tags: groupTags,
            filters: groupFilters,  
        }

        const users = (this.props.users && this.props.users.length) ? this.props.users : [store.current_user_id]
        const randomPart = Math.floor(1000 + Math.random() * 9000);
        const domain = EMAIL_DOMAIN_PRIMARY || EMAIL_DOMAIN_SECONDARY;
        const mailchimpEmail = `mailchimp${randomPart}@${domain}`;

        const data = this.props.editedGroup === true ? object : groupType === 'google' ? {...object, email: groupEmail, users: users} : {...object, email: mailchimpEmail, users: users};
        return data;
    }

    render() {
        const {isShown, toggler, editedGroup, loaded } = this.props;
        const {groupId, groupTags, formErrors, showErrors, groupType} = this.state;
        const {isLoading, success} = KnowledgeBaseStore;
        const stepHeader = editedGroup ? EDIT_GROUP : CREATE_GROUP;

        if (isShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className={`modal-window ${success ? 'success' : ''}`}>
                            <div className='modal-body'>
                                {(!loaded || isLoading) ?
                                    <div className='spinner-block'>
                                        <Spinner/>
                                    </div>
                                    :
                                    <div>
                                        {success ?
                                            <SuccessWindow onModalClose={toggler} value={INFO_WAS_SAVED_SUCCESSFULLY}/>
                                            :
                                            <div>
                                                <ModalHeader value={stepHeader} onModalClose={toggler}/>
                                                <div className="scroll-block-units">
                                                    <div className="add-edit-body">
                                                        <div className="group-information">
                                                            <div className="block-title">Information</div>
                                                            <Input label="Title" id="groupTitle" type="text"
                                                                   onChange={(e) => this.onChange(e)}
                                                                   value={this.state.groupTitle}
                                                                   validationError={formErrors.groupTitle}
                                                                   showError={showErrors}
                                                                   />
                                                            <div className="select-group">
                                                                <SelectGroupItem label="Type" id="groupType"
                                                                            value={this.state.groupType}
                                                                            options={['google', 'mailchimp']}
                                                                            onChange={(e) => this.onChange(e)}
                                                                            validationError={formErrors.groupType}
                                                                            showError={showErrors}
                                                                            disabled={editedGroup ? 'disabled' : ''}
                                                                            />
                                                                <SelectGroupItem label="Status" id="groupStatus"
                                                                            value={this.state.groupStatus}
                                                                            options={[ACTIVE, INACTIVE, ARCHIVED]}
                                                                            onChange={(e) => this.onChange(e)}
                                                                            validationError={formErrors.groupStatus}
                                                                            showError={showErrors}
                                                                            />
                                                            </div>
                                                            {groupType === 'google' && <Input label="Group Email" id="groupEmail" type="text"
                                                                   onChange={(e) => this.onChange(e)}
                                                                   value={this.state.groupEmail}
                                                                   validationError={formErrors.groupEmail}
                                                                   showError={showErrors}
                                                                   disabled={editedGroup ? 'disabled' : ''}
                                                                   />
                                                            }
                                                            <div className="notes-group">
                                                                <Textarea label="Description / Notes" id="groupNotes"
                                                                          placeholder="Text here..."
                                                                          onChange={(e) => this.onChange(e)}
                                                                          defaultValue={this.state.groupNotes ? this.state.groupNotes : ''}
                                                                          validationError={formErrors.groupNotes}
                                                                          showError={showErrors}
                                                                          />
                                                            </div>
                                                        </div>
                                                        <div className="group-filters">
                                                            <div className="block-title">Filters</div>
                                                            <OrganizationFilter dataset={this.props.filters} createGroupMode={this.props.createGroupMode} editGroupMode={this.props.editGroupMode} groupId={groupId} />
                                                            
                                                            <GroupTagInput tags={groupTags} handleInputChange={this.handleTagInputChange} handleInputKeyDown={this.handleTagInputKeyDown} handleRemoveItem={this.handleRemoveTagItem} input={this.state.input} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="add-edit-footer">
                                                    {editedGroup ?
                                                        <Button className="primary-btn"
                                                                onClick={() => this.onEditGroup(groupId)}
                                                                value={EDIT}/> :
                                                        <Button className="primary-btn"
                                                                onClick={() => this.onCreateGroup()}
                                                                value={CREATE}/>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
        return null;
    }
}

export default GroupModal;