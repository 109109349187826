import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PositionsInput from './PositionsInput';
import { Input, InputList } from '../../../helper/components';
import store from '../../../stores';
import CatalogServices from '../../../services/CatalogServices';

@observer
class PositionsDropdown extends Component {

    state = {
        isPositionsOpen: true,
        searchPositionsValue: '',
        warningClean: false,
    }

    onChange = (e) => {
        this.setState({...this.state, searchPositionsValue: e.target.value});
    }

    onWarningClean = () => {
        this.setState({warningClean: false})
    }

    onHandleKeyDown = async(e) => {

        const positions = store.editedOrganizationPositions.filter((position) => position.title.toLowerCase() === this.state.searchPositionsValue.toLowerCase())
        if (e.keyCode === 13 && !positions.length) {
            e.preventDefault();
            const filteredPositions = store.userPositions.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.title.toLowerCase() === thing.title.toLowerCase()
                ))
            ).filter((filteredItem) => filteredItem.title.toLowerCase() === this.state.searchPositionsValue.toLowerCase()).map((position) => {return {...position, existPosition: false}});
            const updatedPositions = [...store.editedOrganizationPositions, ...filteredPositions];

            if(store.editedOrgId) {
                const updatedPositionsIds = updatedPositions.map((pos) => pos.id);
                const updatedOrganization = {...store.editedOrganizationData, positions: updatedPositionsIds };
                await CatalogServices.updateOrganization(store.editedOrgId, updatedOrganization);
                store.getEditedOrganization(store.editedOrgId, false);
            }
            
            store.editedOrganizationPositions = updatedPositions;
            this.setState({...this.state, searchPositionsValue: '', warningClean: true})
        }
    }


    onToggle = () => {
        this.setState({...this.state, isPositionsOpen: !this.state.isPositionsOpen})
    }

    render() {
        const { isPositionsOpen } = this.state;
        const { isViewPositionsModalShown, editedOrganizationPositions, viewedOrgPositionsList } = store;

        const titles = viewedOrgPositionsList.filter((pos)=>pos.title.toLowerCase().includes(this.state.searchPositionsValue.toLowerCase())).map((pos)=>pos.title); 
        const filteredPositions = store.userPositions.filter((filteredItem) => filteredItem.title.toLowerCase().includes(this.state.searchPositionsValue.toLowerCase())).filter(o1 => !store.editedOrganizationPositions.some(o2 => o1.id === o2.id || o1.title === o2.title));
        
        return (
            <div className="positions-list">
                <div className="toggler">
                    <div className={`toggle-checkbox ${isPositionsOpen ? 'open' : ''}`} onClick={this.onToggle}></div>
                    <div className="label">Positions ({isViewPositionsModalShown ? titles.length : editedOrganizationPositions.length})</div>
                </div>
                <div className={`opened-info ${isPositionsOpen ? 'open' : ''}`}>
                    <div className="graphic-indicator">
                        <div></div>
                        <div></div>
                    </div>
                    {isViewPositionsModalShown ?
                        <div className="data">
                            <div className="search-input">
                                <Input type="text" onChange={this.onChange} placeholder='Search' />
                            </div>
                            <div className="position-titles">
                                <div className="tags">
                                    <div className="items">
                                        {titles.map((title, index) => <div key={index}>{title}</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                    <div className="data">
                        <div className="search-input">
                            <InputList type="text" onChange={this.onChange} placeholder='Search' list="positions-search" onKeyDown={this.onHandleKeyDown} value={this.state.searchPositionsValue} />
                            <datalist id="positions-search">
                                {this.state.searchPositionsValue.length && filteredPositions.slice(0, 5).map((option) => {
                                    return <option key={option.id}>{option.title}</option>
                                })}
                            </datalist>
                        </div>
                        <PositionsInput 
                            id="org_positions" 
                            showError={this.props.showError} 
                            validationError={this.props.validationError}
                            onWarningClean={this.onWarningClean}
                            warningClean={this.state.warningClean} 
                        />
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default PositionsDropdown;