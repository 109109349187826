import { Component } from 'react';
import ReactDOM from 'react-dom';

class Portal extends Component {
    el = document.createElement('div');
    
    componentDidMount() {
        this.el.addEventListener('click', ()=>this.props.onClick)
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        this.el.removeEventListener('click', ()=>this.props.onClick)
        document.body.removeChild(this.el);
    }

    render() {
        const { children } = this.props;

        return ReactDOM.createPortal(children, this.el)
    }
}

export default Portal;
