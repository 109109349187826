import React, { Component } from 'react';
import { observer } from 'mobx-react';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
import Portal from './Portal';
import store from '../../stores/Store';
import { CHANGES_WERE_SAVED } from '../../helper/strings';

import './modal.scss';

@observer
class SuccessModal extends Component {

    onSuccessModalClose = () => {
        store.updateDetailsSuccess = false;

        store.deleteUserId = null;
        store.deleteOrgId = null;
    }

    render() {
        const { updateDetailsSuccess } = store;

        if (updateDetailsSuccess) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className='modal-window success'>
                            <div className='modal-body'>
                                <SuccessWindow onModalClose={this.onSuccessModalClose} value={CHANGES_WERE_SAVED} />
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
            return null;
    }
}

export default SuccessModal;