import React from 'react';
import { observer } from 'mobx-react';
import Spinner from '../Spinner';
import MainRouter from '../MainRouter/MainRouter';
import UserModal from '../Modal/UserModal';
import OrganizationModal from '../Modal/OrganizationModal';

import ViewUsersModal from '../Modal/ViewUsersModal';
import ViewPositionsModal from '../Modal/ViewPositionsModal';
import SuccessModal from '../Modal/SuccessModal';
import DeleteModal from '../Modal/DeleteModal';
import ImportMessageModal from '../Modal/ImportMessageModal';
import store from '../../stores';
import querystring from 'querystring';

import './app.scss';

const App = observer(() => {

  if (window.location.href.includes("code=")) {
      const myURL = querystring.parse(window.location.href.split('?')[1]);
      store.code = myURL.code;
  }

  if (store.isInitialLoading) {
    return <Spinner />
  }
  
  return (
    <div>
      <MainRouter />
      <UserModal />
      <OrganizationModal 
        editedOrganizationData={store.editedOrganizationData}
        orgType={store.editedOrganizationData.org_type} 
        isOrganizationModalShown={store.isOrganizationModalShown}
        editedOrganizationPositions={store.editedOrganizationPositions}
      />
      <ViewPositionsModal />
      <ViewUsersModal />
      <ImportMessageModal />
      <SuccessModal />
      <DeleteModal /> 
    </div>
  );
})

export default App;
