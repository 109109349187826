import React, { Component } from 'react';
import { observer } from 'mobx-react';
import removeButton from '../../../images/remove-tag.svg';
import store from '../../../stores';

@observer
class TagInput extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        tags: this.props.tags,
        focused: false,
        input: ''
      };
  
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
    }

    userTagsUpdater = () => {
        store.updatedUserAddress.tags_users = this.state.tags.map((tag) => {
            if (tag.id) {
                return {
                    id: tag.id,
                    active: tag.active,
                    name: tag.name,
                }
            } else {
                return {
                    active: tag.active,
                    name: tag.name,
                }
            }
        });
    }

    handleInputChange = (e) => {       
        this.setState({ input: e.target.value });
    }
    
    handleInputKeyDown = (e) => {
        if (e.keyCode === 13) {
            const {value} = e.target;
            e.preventDefault();
            const tag = {
                active: 1,
                name: value,
                user_id: this.props.id
            }

            this.setState(state => ({
                tags: [...state.tags, tag],
                input: ''
            }), ()=>this.userTagsUpdater());
        }

        if (this.state.tags.length && e.keyCode === 8 && !this.state.input.length) {
            const activeTags = this.state.tags.filter((tag) => tag.active);
            const inactiveTags = this.state.tags.filter((tag) => !tag.active);
            const lastActiveElem = activeTags[activeTags.length-1];
            const updatedlastActiveElem = [{...lastActiveElem, active: 0}];

            const newTags = activeTags.slice(0, activeTags.length-1);

            this.setState({...this.state, tags: [...newTags, ...updatedlastActiveElem, ...inactiveTags]}, ()=>this.userTagsUpdater());
        }        
    }
    
    handleRemoveItem = (index) => {
        return () => {
            this.setState(state => ({
                tags: state.tags.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            active: 0
                        }
                    } else return {...item}
                })
            }), ()=>this.userTagsUpdater());
        }
    }
  
    render() {
        const { id } = this.props;

        return (
                <label className="tags-block">
                    {id === "some_id" ? null : <label htmlFor="list">Tags (type tag and press Enter)</label>}
                    <ul className='tags-container' id='list'>
                    {this.state.tags.map((tag, i) => {
                       if (tag.active) {
                            return (
                                <li key={i} className='tag' onClick={this.handleRemoveItem(i, tag.id)}>
                                    {tag.name}
                                    <span><img src={removeButton} alt='remove'/></span>
                                </li>
                            )
                        } else return null;
                    })}
                    <input
                        className='tags-input'
                        value={this.state.input}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => this.handleInputKeyDown(e)} />
                    </ul>
                </label>
        );
    }
}

export default TagInput;