import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { OrganizationUnitsGrid } from '../OrganizationUnits/OrganizationUnits';
import { Button } from '../../helper/components';
import Spinner from '../Spinner';
import store from '../../stores';
import {
  DASHBOARD,
  ORGANIZATION_UNITS,
  TOTAL_COUNT_OF_USERS,
  TOTAL_COUNT_OF_UNITS,
  TOTAL_GROUPS_IN_GOOGLE,
  TOTAL_MAILCHIMP_LIST,
  ACTIVITY_LOG,
  NUMBER_OF_LOGINS,
  USERS_UPDATES_THEIR_INFO,
  ACTIVE_USERS_IN_THE_SYSTEM,
} from '../../helper/strings';

import './dashboard.scss';

@observer
class Dashboard extends Component {

  state = {
    page: 1,
    renderedItems: []
  }

  async componentDidMount() {
    await store.getDashboard();
    await store.getOrganizationsTree();
    const renderedItems = await store.getDashboardOrganizations(this.state.page);
    this.setState({renderedItems: renderedItems})
  }

  onClick = async () => {
    const page = this.state.page+1;
    const newItems = await store.getDashboardOrganizations(page);
    const renderedItems = [...this.state.renderedItems, ...newItems];
    this.setState({renderedItems: renderedItems, page: page})
  }
 
  render() {
    const {totalDashboardUsers, 
      totalDashboardUnits, 
      totalDashboardGoogleGroups, 
      totalDashboardMailchimpGroups,
      totalDashboardLogins, 
      totalDashboardEditedUsers,
      totalDashboardActiveUsers, 
      isLoading
    } = store;

    if (isLoading) {
      return <Spinner />
    } else {
      return (
        <div className='dashboard-container'>
            <div className="title">{DASHBOARD}</div>
            <div>
              <ul className="results">
                <li>
                  <div className="value">{totalDashboardUsers}</div>
                  <div className="index">{TOTAL_COUNT_OF_USERS}</div>
                </li>
                <li>
                  <div className="value">{totalDashboardUnits}</div>
                  <div className="index">{TOTAL_COUNT_OF_UNITS}</div>
                </li>
                <li>
                  <div className="value">{totalDashboardGoogleGroups}</div>
                  <div className="index">{TOTAL_GROUPS_IN_GOOGLE}</div>
                </li>
                <li>
                  <div className="value">{totalDashboardMailchimpGroups}</div>
                  <div className="index">{TOTAL_MAILCHIMP_LIST}</div>
                </li>
              </ul>
              <div className="units_log">
                <div className="organization_units">
                  <div className="label">{ORGANIZATION_UNITS}</div>
                  <OrganizationUnitsGrid 
                    dashboardView={true} 
                    match={this.props.match} 
                    items={this.state.renderedItems} 
                  />
                  {this.state.renderedItems.length !== totalDashboardUnits ?
                    <Button className="load-more-btn" value='Load More' onClick={()=>this.onClick()}/>
                    :
                    null
                  }
                </div>
                <div className="activity_log">
                  <div className="label">{ACTIVITY_LOG}</div>
                  <ul>
                    <li>
                      <span className="index">{NUMBER_OF_LOGINS}</span>
                      <span className="value">{totalDashboardLogins}</span>
                    </li>
                    <li>
                      <span className="index">{USERS_UPDATES_THEIR_INFO}</span>
                      <span className="value">{totalDashboardEditedUsers}</span>
                    </li>
                    <li>
                      <span className="index">{ACTIVE_USERS_IN_THE_SYSTEM}</span>
                      <span className="value">{totalDashboardActiveUsers}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      );
    }
  }
}

export default withRouter(Dashboard);