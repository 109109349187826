import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input, Button, Checkbox, ActionsMenu } from '../../helper/components';

import UserDetailsModal from '../Modal/ViewUserDetailsModal';
import GroupModal from '../Modal/GroupModal';

import Pagination from '../Pagination/Pagination';
import ExpandableList from '../ExpandableList/ExpandableList';
import FilterBar from './FilterBar/FilterBar';
import ExportDropdown from './ExportDropdown/ExportDropdown';
import ImportDropdown from './ImportDropdown/ImportDropdown';
import Spinner from '../Spinner';

import store from '../../stores/Store';
import KnowledgeBaseStore from '../../stores/KnowledgeBaseStore';

// import remove from '../../images/remove-filter.svg';
import filter from '../../images/filter.svg';
import switcher from '../../images/switcher.svg';
import ooo from '../../images/ooo.svg';
import {
    ADDRESS_BOOK,
    CSV,
    PDF,
    VCF,
    CREATE_GROUP,
    DELETE,
    IMPORT,
    EXPORT,
    ADD_NEW_USER,
    NAME,
    ORGANIZATION,
    SUBORG,
    DEPARTMENT,
    COMMITTEE,
    POSITION,
    TAG,
    EMAIL,
    ROLE,
    ACTION,
    NO_RECORDS_FOUND,
} from '../../helper/strings';
import './addressBook.scss';

const addressBookButtons = [CREATE_GROUP, DELETE, IMPORT, EXPORT];
const exportOptions = [CSV, VCF, PDF];

@observer
class AddressBook extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            organizationsFilterBarIsOpen: false,
            chapterFilterBarIsOpen: false,
            officeFilterBarIsOpen: false,
            positionsFilterBarIsOpen: false,
            tagsFilterBarIsOpen: false,
            removedOrganizationFilters: false,
            isExportListOpen: false,
            isImportListOpen: false,
            isShownUserDetails: false,
            isShownModalCreate: false,
        }

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.onImportChange = this.onImportChange.bind(this)
    }

    orgRef = React.createRef();
    cptRef = React.createRef();
    ofcRef = React.createRef();
    posRef = React.createRef();
    tagRef = React.createRef();
    exportRef = React.createRef();
    importRef = React.createRef();

    componentDidMount() {
        store.getOrganizations();
        store.getPositions();
        store.getTags();
        store.getUsersList();
        store.getOrganizationsTree();
        window.addEventListener('click', this.onFilterMenuClickHandler)
    }

    componentWillUnmount() {
        store.searchInputValue = '';
        window.removeEventListener('click', this.onFilterMenuClickHandler)
    }

    onFilterMenuClickHandler = (e) => {
        if (!this.orgRef.current.contains(e.target)) {
            this.setState({
                ...this.state,
                organizationsFilterBarIsOpen: false,
            });
        } 
        if (!this.cptRef.current.contains(e.target)) {
            this.setState({
                ...this.state,
                chapterFilterBarIsOpen: false,
            });
        }
        if (!this.ofcRef.current.contains(e.target)) {
            this.setState({
                ...this.state,
                officeFilterBarIsOpen: false,
            });
        }  
        if (!this.posRef.current.contains(e.target)) {
            this.setState({
                ...this.state,
                positionsFilterBarIsOpen: false,
            });
        }    
        if (!this.tagRef.current.contains(e.target)) {
            this.setState({
                ...this.state,
                tagsFilterBarIsOpen: false,
            });
        } 
        if (this.exportRef.current.previousSibling.id !== EXPORT || e.target.id !== EXPORT) {
            this.setState({
                ...this.state,
                isExportListOpen: false,
            });
        }
        if (store.current_user_role_id !== 3 && (this.importRef.current.previousSibling.id !== IMPORT || e.target.id !== IMPORT)) {
            this.setState({
                ...this.state,
                isImportListOpen: false,
            });
        } 
    }

    onCreateModalToggle = () => {
        KnowledgeBaseStore.isLoading = false;
        KnowledgeBaseStore.success = false;
        this.setState({...this.state, isShownModalCreate: !this.state.isShownModalCreate})
    };

    onViewLineDetails = async (id = null) => {
        let isShownUserDetails = false;
        if (id !== null) {
            isShownUserDetails = true;
            await store.getEditedUser(id);
        }
        this.setState({...this.state, isShownUserDetails: isShownUserDetails})
    };

    handleCheckboxChange = (e) => {
        const id = e.target.id;
        const checkedUsers = store.users.map((user, index) => {
            if (parseInt(id) === index) {
                return {...user, checked: !user.checked} 
            } else return {...user}           
        })
        store.users = checkedUsers;
    }

    handleCheckboxChangeAll = () => {
        const checkedUsers = store.users.map((user) => {
            if (store.users.filter((user)=>user.checked).length !== store.users.length) {
                return {...user, checked: true}
            } else return {...user, checked: false}
        })
        store.users = checkedUsers;
    }

    onChange = (e) => {
        store.currentUsersPage = 1;
        store.searchInputValue = e.target.value;
    }
    
    onCreateUserPopup = () => {
        store.createUserMode = true;
        store.editUserMode = false;

        store.isUserModalShown = true;
    }

    onEditUserPopup = async(id) => {
        await store.getEditedUser(id);
        store.createUserMode = false;
        store.editUserMode = true;

        store.isUserModalShown = true;
    }
  
    onDeleteUserPopup = (id) => {
        store.createUserMode = false;
        store.editUserMode = false;

        store.deleteUserId = id;
        store.isDeleteModalShown = true;
    }
    
    onRoleSort = () => {
        const { filterRole } = store;
        if(filterRole === 'asc') {
            store.filterRole = 'desc';
        } else if (filterRole === 'desc') {
            store.filterRole = 'asc';
        }
    }

    onClick = (value) => {
        if (value === DELETE) {
            this.onDeleteUserPopup();
        }
        if (value === EXPORT) {
            this.setState({...this.state, isExportListOpen: !this.state.isExportListOpen, isImportListOpen: false})
        }
        if (value === CREATE_GROUP) {
           this.onCreateModalToggle();
        }
        if (value === IMPORT) {
            this.setState({...this.state, isImportListOpen: !this.state.isImportListOpen, isExportListOpen: false})
         }
    }

    changeCurrentPage = (page) => {
        store.currentUsersPage = page;
    }

    changePagedUp = () => {
        store.currentUsersPage = store.currentUsersPage + 1;
    }

    changePagedDown = () => {
        store.currentUsersPage = store.currentUsersPage - 1;
    }

    exportContacts = (format) => {
        store.exportContacts(format);
    }

    onImportChange = (e) => {
        const file = e.target.files[0];
        store.importContacts(file);
    }

    matchUnits = (itemUnits) => {
        const userOrganizations = store.userOrganizations ? [...store.userOrganizations.organizations, ...store.userOrganizations.chapters, ...store.userOrganizations.offices, ...store.userOrganizations.departments] : [];
        const organizationsTree = userOrganizations.length ? store.unitsTree.filter(o1 => userOrganizations.some(o2 => o1.id === o2.id)) : [];

        let childrenUnits = [];
        organizationsTree.map((unit) => childrenUnits.push([...unit.child_ids]));
        const merged = [].concat.apply([], childrenUnits);
        const allowedUnits = [...new Set(merged)];

        const matchedUnits = allowedUnits.filter((org1) => itemUnits.some((org2) => org1 === org2.id));
        return matchedUnits.length;
    }

    render() {
        const { users, isAddressBookLoading, totalUsersCount, currentUsersPage, shownCount, totalUsersPages } = store;
        const { defaultGroup } = KnowledgeBaseStore;
        const selectedAdmins = users.filter((user)=>user.role_id === 1 && user.checked);
        const selectedUsersDelete = store.current_user_role_id !== 1 && selectedAdmins.length ? [] : users.filter((user)=>user.checked);
        const selectedUsersCreate = users.filter((user)=>user.checked).map((user) => user.id);

        return (
            <>
            {users && currentUsersPage && 
                <div className='address-book-container'>
                    <div className="title">{ADDRESS_BOOK}</div>
                    <div>{shownCount} of {totalUsersCount} employees shown</div>
                    <div className="panel">
                        <div className="search-input">
                            <Input type="text" onChange={this.onChange} placeholder='Search' disabled={isAddressBookLoading} />
                        </div>
                        <div className="control-buttons">
                            {store.current_user_role_id === 3 ? 
                                <div className="export-view">
                                    <Button className="button-secondary" value={EXPORT} onClick={() => this.onClick(EXPORT)} disabledDelete={!selectedUsersDelete.length} disabledCreate={!selectedUsersCreate.length} id={EXPORT} />
                                    <div className={`export-list-container ${this.state.isExportListOpen ? 'open' : ''}`} ref={this.exportRef}>
                                        <ExportDropdown value={EXPORT} isExportListOpen={this.state.isExportListOpen} exportOptions={exportOptions} exportContacts={this.exportContacts} selectedUsers={selectedUsersCreate} />
                                    </div>
                                </div>
                                :
                                addressBookButtons.map((button, index) => {
                                    return (
                                        <div key={index}>
                                            <Button className="button-secondary" value={button} onClick={() => this.onClick(button)} disabledDelete={!selectedUsersDelete.length} disabledCreate={!selectedUsersCreate.length} id={button} />
                                            {button === EXPORT && 
                                                <div className={`export-list-container ${this.state.isExportListOpen ? 'open' : ''}`} ref={this.exportRef}>
                                                    <ExportDropdown value={button} isExportListOpen={this.state.isExportListOpen} exportOptions={exportOptions} exportContacts={this.exportContacts} selectedUsers={selectedUsersCreate} />
                                                </div>}
                                            {button === IMPORT && 
                                                <div className={`import-list-container ${this.state.isImportListOpen ? 'open' : ''}`} ref={this.importRef}>
                                                    <ImportDropdown value={button} isImportListOpen={this.state.isImportListOpen} onImportChange={this.onImportChange} />
                                                </div>}
                                        </div>
                                    )
                                })}
                            {store.current_user_role_id !== 3 && <Button className="button-primary" value={ADD_NEW_USER} onClick={this.onCreateUserPopup} />}
                        </div>
                    </div>
                    <table className="user-list">
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox 
                                        id="checkedAllUsers"
                                        className='checkbox_checkedAll' 
                                        checked={store.users.filter((user)=>user.checked).length === store.users.length}
                                        onChange={this.handleCheckboxChangeAll}
                                    />
                                </th>
                                <th>{NAME}</th>
                                <th ref={this.orgRef}>
                                    <span>{ORGANIZATION}</span>
                                    <img id='filter-image' src={filter} alt='filter' onClick={() => this.setState({...this.state, 
                                        organizationsFilterBarIsOpen: !this.state.organizationsFilterBarIsOpen,
                                        chapterFilterBarIsOpen: false,
                                        officeFilterBarIsOpen: false,
                                        positionsFilterBarIsOpen: false,
                                        tagsFilterBarIsOpen: false,
                                        isExportListOpen: false,})} />
                                    {/* <img src={remove} alt='filter' /> */}
                                    {this.state.organizationsFilterBarIsOpen && 
                                        <div className='organizations-filter-container'>
                                            <FilterBar options={store.uniqueOrganizationOptions} selectedOptions={store.organizationFilterOptionsSelected} id="organizations" />
                                        </div>
                                    }
                                </th>
                                <th ref={this.cptRef}>
                                    <span>{SUBORG}</span>
                                    <img id='filter-image' src={filter} alt='filter' onClick={() => this.setState({...this.state, 
                                        chapterFilterBarIsOpen: !this.state.chapterFilterBarIsOpen,
                                        organizationsFilterBarIsOpen: false,
                                        officeFilterBarIsOpen: false,
                                        positionsFilterBarIsOpen: false,
                                        tagsFilterBarIsOpen: false,
                                        isExportListOpen: false,})} />
                                    {/* <img src={remove} alt='filter' /> */}
                                    {this.state.chapterFilterBarIsOpen && 
                                        <div className='chapters-filter-container'>
                                            <FilterBar options={store.uniqueChapterOptions} selectedOptions={store.chapterFilterOptionsSelected} id="chapters" />
                                        </div>
                                    }
                                </th>
                                <th ref={this.ofcRef}>
                                    <span>{DEPARTMENT}</span>
                                    <img id='filter-image' src={filter} alt='filter' onClick={() => this.setState({...this.state, 
                                        officeFilterBarIsOpen: !this.state.officeFilterBarIsOpen,
                                        organizationsFilterBarIsOpen: false,
                                        chapterFilterBarIsOpen: false,
                                        positionsFilterBarIsOpen: false,
                                        tagsFilterBarIsOpen: false,
                                        isExportListOpen: false,})} />
                                    {/* <img src={remove} alt='filter' /> */}
                                    {this.state.officeFilterBarIsOpen && 
                                        <div className='offices-filter-container'>
                                            <FilterBar options={store.uniqueOfficeOptions} selectedOptions={store.officeFilterOptionsSelected} id="offices" />
                                        </div>
                                    }
                                </th>
                                <th>{COMMITTEE}</th>
                                <th ref={this.posRef}>
                                    {POSITION}
                                    <img id='filter-image' src={filter} alt='filter' onClick={() => this.setState({...this.state, 
                                        positionsFilterBarIsOpen: !this.state.positionsFilterBarIsOpen,
                                        organizationsFilterBarIsOpen: false,
                                        chapterFilterBarIsOpen: false,
                                        officeFilterBarIsOpen: false,
                                        tagsFilterBarIsOpen: false,
                                        isExportListOpen: false,})} />
                                    {/* <img src={remove} alt='filter' /> */}
                                    {this.state.positionsFilterBarIsOpen && 
                                        <div className='positions-filter-container'>
                                            <FilterBar options={store.uniquePositionOptions} selectedOptions={store.positionFilterOptionsSelected} id="positions" />
                                        </div>
                                    }
                                </th>
                                <th ref={this.tagRef}>
                                    {TAG}
                                    <img id='filter-image' src={filter} alt='filter' onClick={() => this.setState({...this.state, 
                                        tagsFilterBarIsOpen: !this.state.tagsFilterBarIsOpen,
                                        organizationsFilterBarIsOpen: false,
                                        chapterFilterBarIsOpen: false,
                                        officeFilterBarIsOpen: false,
                                        positionsFilterBarIsOpen: false,
                                        isExportListOpen: false,})} />
                                    {/* <img src={remove} alt='filter' /> */}
                                    {this.state.tagsFilterBarIsOpen && 
                                        <div className='tags-filter-container'>
                                            <FilterBar options={store.uniqueTagOptions} selectedOptions={store.tagFilterOptionsSelected} id="tags" />
                                        </div>
                                    }
                                </th>
                                <th className="emails">{EMAIL}</th>
                                {store.current_user_role_id !== 3 && 
                                <th>
                                    <span>{ROLE}</span>
                                    <img src={switcher} alt='role_filter' onClick={this.onRoleSort} />
                                </th>}
                                <th className="actions">{ACTION}</th>
                            </tr>
                        </thead>
                        {isAddressBookLoading ?
                            <tbody>
                                <tr>
                                    <td colSpan={store.current_user_role_id !== 3 ? "11" : "10"}>
                                        <Spinner />
                                    </td>
                                </tr>  
                            </tbody>
                             :
                            store.users.length ? 
                                <tbody>
                                    {store.users.map((user, index) => {
                                        const {checked, first_name, last_name, organizations, positions, tags_users, primary_email, role, id} = user;
                                        const name = `${first_name} ${last_name}`;
                                    
                                        const uniqueOrganizations = organizations.filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                            t.id === item.id
                                            ))
                                        );
                                        const newArray = [];
                                        let organization = {};
                                        let chapter = {}
                                        let office = {}
                                        let department = {}

                                        uniqueOrganizations.filter((item) => item.org_type === 'organization').forEach((item) => {
                                            organization = item;
                                            newArray.push({
                                                organization: organization,
                                                chapter: chapter,
                                                office: office,
                                                department: department,
                                            })
                                        })
                                        uniqueOrganizations.filter((item) => item.org_type === 'chapter').forEach((item) => {
                                            chapter = item;
                                            organization = chapter && store.organizationList.filter((org) => org.id === chapter.parent_id)[0];
                                            newArray.push({
                                                organization: organization,
                                                chapter: chapter,
                                                office: office,
                                                department: department,
                                            })
                                        })
                                        uniqueOrganizations.filter((item) => item.org_type === 'office').forEach((item) => {
                                            office = item;
                                            chapter = office && store.organizationList.filter((org) => org.id === office.parent_id)[0];
                                            organization = chapter && store.organizationList.filter((org) => org.id === chapter.parent_id)[0];
                                            newArray.push({
                                                organization: organization,
                                                chapter: chapter,
                                                office: office,
                                                department: department,
                                            })
                                        })

                                        uniqueOrganizations.filter((item) => item.org_type === 'department').forEach((item) => {
                                            department = item;
                                            office = department && store.organizationList.filter((org) => org.id === department.parent_id)[0];;
                                            chapter = office && store.organizationList.filter((org) => org.id === office.parent_id)[0];
                                            organization = chapter && store.organizationList.filter((org) => org.id === chapter.parent_id)[0];
                                            newArray.push({
                                                organization: organization,
                                                chapter: chapter,
                                                office: office,
                                                department: department,
                                            })
                                        })

                                        const userOrganizations = newArray.map((item) => item.organization ? item.organization : {}).filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                item.title && t.title === item.title
                                            ))
                                        );
                                        const userChapters = newArray.map((item) => item.chapter ? item.chapter : {}).filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                item.title && t.title === item.title
                                            ))
                                        );
                                        const userOffices = newArray.map((item) => item.office ? item.office : {}).filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                item.title && t.title === item.title
                                            ))
                                        );
                                        const userDepartments = newArray.map((item) => item.department ? item.department : {}).filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                item.title && t.title === item.title
                                            ))
                                        );
                                        const userPositions = positions.filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.id === item.id
                                            ))
                                        );
                                        
                                        const matchedResult = role.id === 1 ? 0 : this.matchUnits(organizations);

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Checkbox
                                                        id={index}
                                                        className="checkbox"
                                                        checked={checked}
                                                        onChange={(e)=>this.handleCheckboxChange(e)}
                                                    />
                                                </td>
                                                <td>{name}</td>
                                                <td>
                                                    <ExpandableList list={userOrganizations} className="organizations" />
                                                </td>
                                                <td>
                                                    <ExpandableList list={userChapters} className="organizations" />
                                                </td>
                                                <td>
                                                    <ExpandableList list={userOffices} className="organizations" />
                                                </td>
                                                <td>
                                                    <ExpandableList list={userDepartments} className="organizations" />
                                                </td>
                                                <td>
                                                    <ExpandableList list={userPositions} className="positions" />
                                                </td>
                                                <td>
                                                    <ExpandableList list={tags_users} className="tags" />
                                                </td>
                                                <td className="emails">{primary_email}</td>
                                                {store.current_user_role_id !== 3 && <td>{role.title}</td>}
                                                <td>
                                                {store.current_user_id !== id && 
                                                    <div className="actions">
                                                        <img src={ooo} alt='toogle-actions' />
                                                        <ActionsMenu 
                                                            onViewDetails={()=>this.onViewLineDetails(id)}
                                                            onEditPopup={()=>this.onEditUserPopup(id)} 
                                                            onDeletePopup={()=>this.onDeleteUserPopup(id)} 
                                                            role_id={store.current_user_role_id} 
                                                            containUser={matchedResult}
                                                        />
                                                    </div>
                                                }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                :
                                <tbody className="no-results-body">
                                    <tr>
                                        <td colSpan={store.current_user_role_id !== 3 ? "11" : "10"}>
                                            <div className="no-results">{NO_RECORDS_FOUND}</div>
                                        </td>
                                    </tr>  
                                </tbody>
                            }
                    </table>
                    {users.length && !isAddressBookLoading ?
                        <div className="pagination">
                            <Pagination currentPage={currentUsersPage} totalPages={totalUsersPages} changeCurrentPage={this.changeCurrentPage} changePagedUp={this.changePagedUp} changePagedDown={this.changePagedDown} />
                        </div>
                        :
                        null                        
                    }
                </div>}
                <UserDetailsModal isShown={this.state.isShownUserDetails}
                                  toggler={this.onViewLineDetails}
                                  />
                <GroupModal isShown={this.state.isShownModalCreate}
                            toggler={this.onCreateModalToggle}
                            editedGroup={false}
                            item={defaultGroup}
                            loaded={true}
                            createGroupMode={true}
                            filters={defaultGroup && KnowledgeBaseStore.defaultGroup.filters} 
                            users={selectedUsersCreate}
                            />
            </>)  
    }
}

export default AddressBook;