import React from 'react';
import cancel from '../../../images/cancel.svg';

const ModalHeader = ({onModalClose, value}) => {
    return (
        <div className="add-edit-header">
            <span>{value}</span>
            <span onClick={onModalClose}><img src={cancel} alt="cancel" /></span>
        </div>
    )
}

export default ModalHeader;