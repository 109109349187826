import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import store from '../../../stores/Store';
import { url } from '../../../services/http/index';
import { FILE_FORMAT } from '../../../helper/strings';

@observer
class ImportDropdown extends Component {
    render() {
        const { value, onImportChange } = this.props;

        return (
            <Fragment>
                <div>
                    <form>
                        <label className="file-import">
                            <input type="file" onChange={(e) => onImportChange(e)} onClick={(e)=> {e.target.value = null}} accept=".csv" />{value}
                        </label>
                    </form>
                </div>
                <a href={`${url}/v1/export?format=template&access_token=${store.token}`} download="file-format">
                    <div>{FILE_FORMAT}</div>
                </a>
            </Fragment>
        )
    }
}

export default ImportDropdown;