import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
    Select,
    SelectOrganization,
    Button,
  } from '../../../helper/components';
import DatePickerBlock from '../../Modal/DatePickerBlock/DatePickerBlock';
import Spinner from '../../Spinner';
import store from '../../../stores/Store';
import KnowledgeBaseStore from '../../../stores/KnowledgeBaseStore';
import CatalogServices from '../../../services/CatalogServices';
import KnowledgeBaseService from '../../../services/KnowledgeBaseService';
import moment from 'moment';
import { 
    ORGANIZATION, 
    SUBORG, 
    DEPARTMENT, 
    COMMITTEE, 
    POSITION, 
    REMOVE, 
    ADD_PRIMARY
} from '../../../helper/strings';

@observer
class OrganizationFilter extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        organizations: [{
            organization: '',
            chapter: '',
            office: '',
            department: '',
            positions: [],
            organizationOptions: [],
            chapterOptions: [],
            officeOptions: [],
            departmentOptions: [],
            primary: true,
            disabledOrg: false,
            organizationValid: false,
            positionValid: false,
            joined: null,
            period_start: null,
            period_end: null,
        }],
        organizationsCount: 1,
      };
    }

    async componentDidMount(){
        this.mounted = true;

        const { dataset } = this.props;
        let positionsArray = [];

        if(dataset && dataset.length && !this.props.accountSettingsMode) {
            const updatedDataset = dataset.map((org) => {
                if(this.props.createGroupMode || this.props.editGroupMode || this.props.viewDetailGroup) {
                    if(org.position && org.filter_id) {
                        return {
                            organization_id: org.id || org.organization_id,
                            position: {...org.position, entity_id: org.filter_id},
                            joined: org.joined,
                            period_start: org.period_start,
                            period_end: org.period_end,
                            disabledSingle: org.joined, 
                            disabledRange: org.period_end || org.period_end,
                        }
                    } else if (!org.position && org.filter_id) {
                        return {
                            organization_id: org.id || org.organization_id,
                            position: {},
                            entity_id: org.filter_id,
                            joined: org.joined,
                            period_start: org.period_start,
                            period_end: org.period_end,
                            disabledSingle: org.joined, 
                            disabledRange: org.period_end || org.period_end,
                        }
                    } else {
                        return {
                            organization_id: org.id || org.organization_id,
                            position: org.position ? {...org.position} : {},
                            joined: org.joined,
                            period_start: org.period_start,
                            period_end: org.period_end,
                            disabledSingle: org.joined, 
                            disabledRange: org.period_end || org.period_end,
                        }
                    }
                } else {
                    if(org.position && org.organization_user_position_id) {
                        return {
                            organization_id: org.id || org.organization_id,
                            position: {...org.position, entity_id: org.organization_user_position_id},
                        }
                    } else if (!org.position && org.organization_user_position_id) {
                        return {
                            organization_id: org.id || org.organization_id,
                            position: {},
                            entity_id: org.organization_user_position_id,
                        }
                    } else {
                        return {
                            organization_id: org.id || org.organization_id,
                            position: org.position ? {...org.position} : {},
                        }
                    }
                }
                
            })

            const updatedDatasetUnique = dataset.map((org) => org && (org.id || org.organization_id)).filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                    t === thing
                ))
            )

            async function asyncForEach(array, callback) {
                for (let index = 0; index < array.length; index++) {
                  await callback(array[index], index, array);
                }
            }
            
            const logOrgPositions = async(updatedDatasetUnique) => {
                await asyncForEach(updatedDatasetUnique, async (unit) => {
                    await CatalogServices.getOrganizationPositions(unit)
                    .then((res) => {
                        const positions = res.data.result
                        const orgPositions = {organization_id: unit, positions: positions};
                        positionsArray.push(orgPositions);
                    })
                    .catch(err=>console.log("error: ", err))
                })
            }
 
            await logOrgPositions(updatedDatasetUnique);          
           
            let result = {};
            if(this.props.createGroupMode || this.props.editGroupMode || this.props.viewDetailGroup) {
                result = updatedDataset.reduce(function(r, o){
                    const k = o.organization_id; // unique `loc` key
                    const data = {
                        position: o.position,
                        entity_id: o.entity_id,
                        joined: o.joined,
                        period_start: o.period_start,
                        period_end: o.period_end,
                        disabledSingle: o.disabledSingle,
                        disabledRange: o.disabledRange
                    }
                    if (r[k] || (r[k]=[])) r[k].push(data);
                    return r;
                }, {});
            } else {
                result = updatedDataset.reduce(function(r, o){
                    const k = o.organization_id; // unique `loc` key
                    const data = {
                        position: o.position,
                        entity_id: o.entity_id,
                    }
                    if (r[k] || (r[k]=[])) r[k].push(data);
                    return r;
                }, {});
            }
            
            const newArray = Object.entries(result);
            let updateArray = [];

            if(this.props.createGroupMode || this.props.editGroupMode || this.props.viewDetailGroup) {
                updateArray = updatedDataset.map((obj) => {
                    const units = store.unitsTree; //[...store.userOrganizations.organizations, ...store.userOrganizations.chapters, ...store.userOrganizations.offices, ...store.userOrganizations.departments];
                    const unit = units.filter((org) => org.id === obj.organization_id)[0];

                    if(unit) {
                        let organization = '';
                        let chapter = '';
                        let office = '';
                        let department = '';
                        let positions = [];
                        let chapterOptions = [];
                        let officeOptions = [];
                        let departmentOptions = [];
                        let positionsList = positionsArray.filter((item) => item.organization_id === unit.id)[0].positions;

                        if (unit.org_type === "department") {
                            department = unit;
                            office = department && units.filter((org) => org.id===department.parent_id)[0];
                            chapter = office && units.filter((org) => org.id===office.parent_id)[0];
                            organization = chapter && units.filter((org) => org.id===chapter.parent_id)[0];
                            departmentOptions = office ? units.filter((org) => org.parent_id === office.id) : [];
                            officeOptions = chapter ? units.filter((org) => org.parent_id === chapter.id) : [];
                            chapterOptions = organization ? units.filter((org) => org.parent_id === organization.id) : [];
                        } else if (unit.org_type === "office") {
                            office = unit;
                            chapter = office && units.filter((org) => org.id===office.parent_id)[0];
                            organization = chapter && units.filter((org) => org.id===chapter.parent_id)[0];
                            officeOptions = chapter ? units.filter((org) => org.parent_id === chapter.id) : [];
                            chapterOptions = organization ? units.filter((org) => org.parent_id === organization.id) : [];
                        } else if (unit.org_type === "chapter") {
                            chapter = unit;
                            organization = chapter && units.filter((org) => org.id===chapter.parent_id)[0];
                            chapterOptions = organization ? units.filter((org) => org.parent_id === organization.id) : [];
                        } else if (unit.org_type === "organization") {
                            organization = unit;
                        }
                        
                        const groupPositions = [obj.position];
                        positions = this.getPositionsList(positionsList, groupPositions)
                        const selectedPositions = positions.filter((pos)=>pos.selected);

                        return {
                            organization: organization,
                            chapter: chapter,
                            office: office,
                            department: department,
                            positions: positions,
                            organizationOptions: store.userOrganizations.organizations,
                            chapterOptions: chapterOptions,
                            officeOptions: officeOptions,
                            departmentOptions: departmentOptions,
                            primary: false,
                            disabledOrg: true,
                            organizationValid: organization,
                            positionValid: selectedPositions.length,
                            entity_id: obj.entity_id,
                            joined: obj.joined,
                            period_start: obj.period_start,
                            period_end: obj.period_end,
                            disabledSingle: obj.disabledSingle,
                            disabledRange: obj.disabledRange,
                        }        
                    } else return;
                })
            } else {
                updateArray = newArray.map((arr) => {
                    const units = store.unitsTree; //[...store.userOrganizations.organizations, ...store.userOrganizations.chapters, ...store.userOrganizations.offices, ...store.userOrganizations.departments];
                    const unit = units.filter((org) => org.id === +arr[0])[0];
                    
                    if(unit) {
                        let organization = '';
                        let chapter = '';
                        let office = '';
                        let department = '';
                        let positions = [];
                        let chapterOptions = [];
                        let officeOptions = [];
                        let departmentOptions = [];
                        let positionsList = positionsArray.filter((item) => item.organization_id === unit.id)[0].positions;

                        if (unit.org_type === "department") {
                            department = unit;
                            office = department && units.filter((org) => org.id===department.parent_id)[0];
                            chapter = office && units.filter((org) => org.id===office.parent_id)[0];
                            organization = chapter && units.filter((org) => org.id===chapter.parent_id)[0];
                            departmentOptions = office ? units.filter((org) => org.parent_id === office.id) : [];
                            officeOptions = chapter ? units.filter((org) => org.parent_id === chapter.id) : [];
                            chapterOptions = organization ? units.filter((org) => org.parent_id === organization.id) : [];
                        } else if (unit.org_type === "office") {
                            office = unit;
                            chapter = office && units.filter((org) => org.id===office.parent_id)[0];
                            organization = chapter && units.filter((org) => org.id===chapter.parent_id)[0];
                            officeOptions = chapter ? units.filter((org) => org.parent_id === chapter.id) : [];
                            chapterOptions = organization ? units.filter((org) => org.parent_id === organization.id) : [];
                        } else if (unit.org_type === "chapter") {
                            chapter = unit;
                            organization = chapter && units.filter((org) => org.id===chapter.parent_id)[0];
                            chapterOptions = organization ? units.filter((org) => org.parent_id === organization.id) : [];
                        } else if (unit.org_type === "organization") {
                            organization = unit;
                        }
                     
                        const userPositions = arr[1].map((item) => item.position);
                        positions = this.getPositionsList(positionsList, userPositions)
                        const selectedPositions = positions.filter((pos)=>pos.selected);

                        return {
                            organization: organization,
                            chapter: chapter,
                            office: office,
                            department: department,
                            positions: positions,
                            organizationOptions: store.userOrganizations.organizations,
                            chapterOptions: chapterOptions,
                            officeOptions: officeOptions,
                            departmentOptions: departmentOptions,
                            primary: false,
                            disabledOrg: true,
                            organizationValid: organization,
                            positionValid: selectedPositions.length,
                            entity_id: arr[1][0].entity_id,
                        } 
                    } else return;
                })
            }            

            if(this.mounted) {
                this.setState({...this.state,
                    organizations: updateArray, 
                }, ()=>this.storeOrganizationsUpdater())
            }
            

        } else {
            const updateArray = this.state.organizations.map((org) => {
                return {
                    ...org,
                    organizationOptions: store.userOrganizations && store.userOrganizations.organizations,
                }
            })
            this.setState({...this.state,
                organizations: updateArray, 
            }, ()=>this.storeOrganizationsUpdater())
        }        
    }

    componentWillUnmount() {
        this.mounted = false;
        store.isLocalLoading = false;
    }

    getPositionsList = (orgPositions, currentPositions) => {
        if(orgPositions.length) {
            const selectedPositions = currentPositions.length ? currentPositions.filter(o1 => orgPositions.some(o2 => o1.id === o2.id)).map((item) => {return {...item, selected: true}}) : orgPositions.map((item) => {return {...item, selected: false}})

            const unSelectedPositions = currentPositions.length ? orgPositions.filter(o1 => currentPositions.some(o2 => o1.id !== o2.id)).map((item) => {return {...item, selected: false}}) : orgPositions.map((item) => {return {...item, selected: false}})                  
            const mergedPos = selectedPositions.length ? unSelectedPositions.filter(o1 => selectedPositions.some(o2 => o1.id !== o2.id)) : unSelectedPositions;
            const positions = [...selectedPositions, ...mergedPos].filter((thing, index, self) =>
                index === self.findIndex((t) => (
                t.id === thing.id
                ))
            );
            return positions;
        }
        else return [];
        
    }

    handleOrganizationChange = async(e, name, parentId) => {
        const units = [...store.userOrganizations.organizations, ...store.userOrganizations.chapters, ...store.userOrganizations.offices, ...store.userOrganizations.departments];
        
        if(name === "organization") {
            const { value } = e.target;
            const { id } = e.target;
            const unit = units.filter((org) => org.title === value)[0];
            const unitId = unit.id;
            await store.getOrganizationPositions(unitId);
            const chapters = units.filter((org) => org.parent_id === unit.id);
            const newOrganizations = this.state.organizations.map((org, index) => {
                if(id === `organization${index}`) {
                    const filtered = this.props.dataset.filter((org) => org.id === unitId).map((org) => org.position).filter((org) => org !== null);
                    const positions = this.getPositionsList(store.viewedOrgPositionsList, filtered)
                    const positionValid = positions.filter((pos) => pos.selected)
                    return {
                        ...org,
                        organization: unit,
                        chapter: '',
                        office: '',
                        department: '',
                        positions: positions,
                        chapterOptions: chapters,
                        officeOptions: [],
                        departmentOptions: [],
                        organizationValid: unit,
                        positionValid: positionValid.length,
                    }
                } else {
                    return {...org}
                }
            })
            this.setState({
                ...this.state, 
                organizations: newOrganizations}, ()=>this.storeOrganizationsUpdater());
        } else if(name === "chapter") {
            const { value } = e.target;
            const { id } = e.target;
            const unit = units.filter((org) => org.title === value && org.parent_id === parentId)[0];
            const unitId = unit.id;
            await store.getOrganizationPositions(unitId);
            const offices = units.filter((org) => org.parent_id === unit.id);
            const newOrganizations = this.state.organizations.map((org, index) => {
                if(id === `chapter${index}`) {
                    const filtered = this.props.dataset.filter((org) => org.id === unitId).map((org) => org.position).filter((org) => org !== null);
                    const positions = this.getPositionsList(store.viewedOrgPositionsList, filtered)
                    return {
                        ...org,
                        chapter: unit,
                        office: '',
                        department: '',
                        positions: positions,
                        officeOptions: offices,
                        departmentOptions: [],
                    }
                } else {
                    return {...org}
                }
            })
            this.setState({
                ...this.state, 
                organizations: newOrganizations}, ()=>this.storeOrganizationsUpdater());
        } else if(name === "office") {
            const { value } = e.target;
            const { id } = e.target;
            const unit = units.filter((org) => org.title === value && org.parent_id === parentId)[0];
            const unitId = unit.id;
            await store.getOrganizationPositions(unitId);
            const departments = units.filter((org) => org.parent_id === unit.id);
            const newOrganizations = this.state.organizations.map((org, index) => {
                if(id === `office${index}`) {
                    const filtered = this.props.dataset.filter((org) => org.id === unitId).map((org) => org.position).filter((org) => org !== null);
                    const positions = this.getPositionsList(store.viewedOrgPositionsList, filtered)
                    return {
                        ...org,
                        office: unit,
                        department: '',
                        positions: positions,
                        departmentOptions: departments
                    }
                } else {
                    return {...org}
                }
            })
            this.setState({
                ...this.state, 
                organizations: newOrganizations}, ()=>this.storeOrganizationsUpdater());
        } else if(name === "department") {
            const { value } = e.target;
            const { id } = e.target;
            const unit = units.filter((org) => org.title === value && org.parent_id === parentId)[0];
            const unitId = unit.id;
            await store.getOrganizationPositions(unitId);
            const newOrganizations = this.state.organizations.map((org, index) => {
                if(id === `department${index}`) {
                    const filtered = this.props.dataset.filter((org) => org.id === unitId).map((org) => org.position).filter((org) => org !== null);
                    const positions = this.getPositionsList(store.viewedOrgPositionsList, filtered)
                    return {
                        ...org,
                        department: unit,
                        positions: positions,
                    }
                } else {
                    return {...org}
                }
            })
            this.setState({
                ...this.state, 
                organizations: newOrganizations}, ()=>this.storeOrganizationsUpdater());
        }
    }

    handlePositionChange = (e, options, i, orgId) => {
        const id = e.target.id;
        const selectedOptions = options.map((option, index) => {

            if (id === `position${i}${option.id}${index}`) {
                if(option.selected && !this.props.editGroupMode && option.entity_id) {
                    CatalogServices.deletePositionFromUser(option.entity_id)
                } else if (option.selected && this.props.editGroupMode && option.entity_id) {
                    KnowledgeBaseService.deleteFilter(this.props.groupId, option.entity_id)
                }
                return {...option, selected: !option.selected} 
            } else return {...option}           
        })

        const newOrganizations = this.state.organizations.map((org, index) => {
            if(`position${i}` === `position${index}`) {
                return {
                    ...org,
                    positions: selectedOptions,
                    positionValid: selectedOptions.length,
                }
            } else {
                return {...org}
            }
        })

        this.setState({
            ...this.state, 
            organizations: newOrganizations}, ()=>this.storeOrganizationsUpdater());
    }

    addOrganizationItem = (event) => {
        event.preventDefault();
        store.showOrgValidationErrors = false;
        store.showPosValidationErrors = false;
        store.organizationsValid = false;
        store.positionsValid = false;
        store.formAddressValid = false;
        const newCount = this.state.organizationsCount+1;

        const newItem = {
            organization: '',
            chapter: '',
            office: '',
            department: '',
            positions: [],
            organizationOptions: store.userOrganizations.organizations,
            chapterOptions: [],
            officeOptions: [],
            departmentOptions: [],
            primary: false,
            disabledOrg: false,
            joined: null,
            period_start: null,
            period_end: null,
        };
        this.setState({...this.state, organizationsCount: newCount, organizations: [...this.state.organizations, newItem]}, ()=>this.storeOrganizationsUpdater())
    }

    removeOrganizationItem = async (event, index) => {
        event.preventDefault();
        store.isLocalLoading = true;

        const newArr = [
            ...this.state.organizations.slice(0, index),
            ...this.state.organizations.slice(index+1),
        ]
        const {entity_id} = this.state.organizations[index];
        const removeEntity = this.state.organizations[index].positions;

        if (entity_id && !this.props.editGroupMode) {
            await CatalogServices.deletePositionFromUser(entity_id)
        } else if (entity_id && this.props.editGroupMode) {
            await KnowledgeBaseService.deleteFilter(this.props.groupId, entity_id)
        }

        for(let i=0; i < removeEntity.length; i++) {
            if (removeEntity[i].entity_id && !this.props.editGroupMode) {
                await CatalogServices.deletePositionFromUser(removeEntity[i].entity_id)
            } else if (removeEntity[i].entity_id && this.props.editGroupMode) {
                await KnowledgeBaseService.deleteFilter(this.props.groupId, removeEntity[i].entity_id)
            }
        }
                
        const newItem = {
            organization: '',
            chapter: '',
            office: '',
            department: '',
            positions: [],
            organizationOptions: store.userOrganizations.organizations,
            chapterOptions: [],
            officeOptions: [],
            departmentOptions: [],
            primary: true,
            organizationValid: false,
            positionValid: false,
            joined: null,
            period_start: null,
            period_end: null,
        };

        let organizations = [];
        if (newArr.length) {
            organizations = newArr;
        } else {
            organizations = [newItem]
        }
        store.isLocalLoading = false;
        this.setState({...this.state, organizations: organizations}, ()=>this.storeOrganizationsUpdater())
    }

    storeOrganizationsUpdater = () => {
        let orgPositions=[];
        this.state.organizations.map((org) => {
            if (org) {
                const { organization, chapter, office, department, positions, joined, period_start, period_end } = org;
                const selectedPositions = positions.filter((pos)=>pos.selected)
                if (organization && chapter && office && department) {
                    if((this.props.createGroupMode || this.props.editGroupMode) && !selectedPositions.length) {
                        orgPositions.push({
                            organization_id: department.id,
                            joined: joined && moment(joined).format('YYYY-MM-DD'),
                            period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                            period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                        })
                    } else if ((this.props.createGroupMode || this.props.editGroupMode) && selectedPositions.length) {
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: department.id,
                                position: pos,
                                joined: joined && moment(joined).format('YYYY-MM-DD'),
                                period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                                period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                            })
                        })
                    } else {
                        if (org.entity_id && !positions.filter((pos)=>pos.selected).length) {
                            orgPositions.push({
                                organization_id: department.id,
                                organization_user_position_id: org.entity_id,
                            })
                        }
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: department.id,
                                position: pos,
                            })
                        })
                    }
                } else if (organization && chapter && office) {
                    if((this.props.createGroupMode || this.props.editGroupMode) && !selectedPositions.length) {
                        orgPositions.push({
                            organization_id: office.id,
                            joined: joined && moment(joined).format('YYYY-MM-DD'),
                            period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                            period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                        })
                    } else if ((this.props.createGroupMode || this.props.editGroupMode) && selectedPositions.length) {
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: office.id,
                                position: pos,
                                joined: joined && moment(joined).format('YYYY-MM-DD'),
                                period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                                period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                            })
                        })
                    } else {
                        if (org.entity_id && !positions.filter((pos)=>pos.selected).length) {
                            orgPositions.push({
                                organization_id: office.id,
                                organization_user_position_id: org.entity_id,
                            })
                        }
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: office.id,
                                position: pos,
                            })
                        })
                    }
                } else if (organization && chapter) {
                    if((this.props.createGroupMode || this.props.editGroupMode) && !selectedPositions.length) {
                        orgPositions.push({
                            organization_id: chapter.id,
                            joined: joined && moment(joined).format('YYYY-MM-DD'),
                            period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                            period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                        })
                    } else if ((this.props.createGroupMode || this.props.editGroupMode) && selectedPositions.length) {
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: chapter.id,
                                position: pos,
                                joined: joined && moment(joined).format('YYYY-MM-DD'),
                                period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                                period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                            })
                        })
                    } else {
                        if (org.entity_id && !positions.filter((pos)=>pos.selected).length) {
                            orgPositions.push({
                                organization_id: chapter.id,
                                organization_user_position_id: org.entity_id,
                            })
                        }
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: chapter.id,
                                position: pos,
                            })
                        })
                    }
                } else if (organization) {
                    if((this.props.createGroupMode || this.props.editGroupMode) && !selectedPositions.length) {
                        orgPositions.push({
                            organization_id: organization.id,
                            joined: joined && moment(joined).format('YYYY-MM-DD'),
                            period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                            period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                        })
                    } else if ((this.props.createGroupMode || this.props.editGroupMode) && selectedPositions.length) {
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: organization.id,
                                position: pos,
                                joined: joined && moment(joined).format('YYYY-MM-DD'),
                                period_start: period_start && moment(period_start).format('YYYY-MM-DD'),
                                period_end: period_end && moment(period_end).format('YYYY-MM-DD'),
                            })
                        })
                    } else {
                        if (org.entity_id && !positions.filter((pos)=>pos.selected).length) {
                            orgPositions.push({
                                organization_id: organization.id,
                                organization_user_position_id: org.entity_id,
                            })
                        }
                        positions.filter((pos)=>pos.selected).forEach((pos) => {
                            orgPositions.push({
                                organization_id: organization.id,
                                position: pos,
                            })
                        })
                    }
                } 
            } else return null;
        })

        this.organizationsValidate();
        this.positionsValidate();

        if(this.props.createGroupMode) {
            KnowledgeBaseStore.defaultGroup.filters = [...orgPositions];
        } else if (this.props.editGroupMode) {
            KnowledgeBaseStore.singleItem.filters = [...orgPositions];
        } else {
            store.updatedUserAddress.organizations = orgPositions;
        }
    }

    organizationsValidate = () => {
        const organizationsInvalid = this.state.organizations.filter((org) => org && !org.organizationValid);
        store.organizationsValid = organizationsInvalid.length ? false : true; 
    }

    positionsValidate = () => {
        const positionsInvalid = this.state.organizations.filter((org) => org && !org.positionValid)
        store.positionsValid = positionsInvalid.length ? false : true; 
    }

    onChangeSingleDate = (date, index) => {
        const newArray = this.state.organizations.map((org, i) => {
            if(i === index) {
                return {
                    ...org,
                    joined: date,
                }
            } else return org;
        })
        this.setState({...this.state, organizations: newArray}, ()=>this.storeOrganizationsUpdater())
    }

    onChangeDateRange = (startDate, endDate, index) => {
        const newArray = this.state.organizations.map((org, i) => {
            if(i === index) {
                return {
                    ...org,
                    period_start: startDate,
                    period_end: endDate,
                }
            } else return org;
        })
        this.setState({...this.state, organizations: newArray}, ()=>this.storeOrganizationsUpdater())
    }

  
    render() {
        if(this.props.viewDetailGroup || this.props.accountSettingsMode || this.props.viewUserDetailsMode) {
            return (
                <div>
                    {this.state.organizations.map((field, index) => {
                        const { organization, chapter, office, department, positions, joined, period_start, period_end } = field;
                        const selectedPositions = positions ? positions.filter((pos)=>pos.selected) : [];
                        return (
                            <div className="grid-group-filters" key={index}>
                                
                                <div className="grid-item">
                                    <div className="details-label">{ORGANIZATION}</div>
                                    {organization ? <div className="content">{organization.title}</div> : <div>{'-'}</div>}
                                </div>
                                <div className="grid-item">
                                    <div className="details-label">{SUBORG}</div>
                                    {chapter ? <div className="content">{chapter.title}</div> : <div>{'-'}</div>}
                                </div>
                                <div className="grid-item">
                                    <div className="details-label">{DEPARTMENT}</div>
                                    {office ? <div className="content">{office.title}</div> : <div>{'-'}</div>}
                                </div>
                                <div className="grid-item">
                                    <div className="details-label">{COMMITTEE}</div>
                                    {department ? <div className="content">{department.title}</div> : <div>{'-'}</div>}
                                </div>

                                <div className="grid-item">
                                    <div className="details-label">{POSITION}</div>
                                    {selectedPositions.length ? selectedPositions.map((pos, index)=> {
                                        return <div key={index} className="content">{pos.title}</div>
                                    }) : <div>{'-'}</div>}
                                </div>
                                {this.props.viewDetailGroup && <div className="grid-item">
                                    <div className="details-label">Data Joined</div>
                                    {!joined && !period_start && !period_end && <div>{'-'}</div>}
                                    {joined && <div className="content">{moment(joined).format('YYYY/MM/DD')};</div>}
                                    {period_start && period_end && <div className="content">{moment(period_start).format('YYYY/MM/DD')} - {moment(period_end).format('YYYY/MM/DD')};</div>}
                                </div>}
                            </div>)
                })}
                </div>
            )
        }
        return (
            <div className="organization-select-container">
            {store.isLocalLoading &&
                <div className="filter-loading">
                    <Spinner />
                </div>
            }
            {this.state.organizations.map((field, index) => {
                if(field) {
                    const { organization, chapter, office, department, organizationOptions, chapterOptions, officeOptions, departmentOptions, positions, primary, disabledOrg, organizationValid, positionValid, joined, period_start, period_end, disabledSingle, disabledRange } = field;
                    let orgId = 0;
                    if(organization && chapter && office && department) {
                        orgId = department.id
                    } else if(organization && chapter && office) {
                        orgId = office.id
                    } else if (organization && chapter) {
                        orgId = chapter.id
                    } else if (organization) {
                        orgId = organization.id
                    }

                    return (
                        <div className="orgaization-item" key={index}>
                            <div className="with-datepicker">
                                <div className="without-datepicker">
                                    <div className="org-selects">
                                        <SelectOrganization id={`organization${index}`} label={ORGANIZATION} value={organization && organization.title} options={organizationOptions} disabled={disabledOrg} onChange={(e)=>this.handleOrganizationChange(e, 'organization')} valid={organizationValid} showError={store.showOrgValidationErrors} />
                                        {(chapterOptions.length>0 || chapter) && <SelectOrganization id={`chapter${index}`} label={SUBORG} value={chapter && chapter.title} disabled={!chapterOptions.length || disabledOrg} options={chapterOptions} onChange={(e)=>this.handleOrganizationChange(e, 'chapter', organization.id)} valid={true} />}
                                        {(officeOptions.length>0 || office)  && <SelectOrganization id={`office${index}`} label={DEPARTMENT} value={office && office.title} disabled={!officeOptions.length || disabledOrg} options={officeOptions} onChange={(e)=>this.handleOrganizationChange(e, 'office', chapter.id)} valid={true} />}
                                        {(departmentOptions.length>0 || department)  && <SelectOrganization id={`department${index}`} label={COMMITTEE} value={department && department.title} disabled={!departmentOptions.length || disabledOrg} options={departmentOptions} onChange={(e)=>this.handleOrganizationChange(e, 'department', office.id)} valid={true} />}
                                    </div>
                                    <Select id={`position${index}`} label={POSITION} options={positions} onChange={(e)=>this.handlePositionChange(e, positions, index, orgId)} valid={positionValid} showError={store.showPosValidationErrors} /> 
                                </div>
                                {(this.props.createGroupMode || this.props.editGroupMode) && <DatePickerBlock createGroupMode={this.props.createGroupMode} editGroupMode={this.props.editGroupMode} filters={this.props.dataset} date={joined ? moment(joined) : null} startDate={period_start ? moment(period_start) : null} endDate={period_end ? moment(period_end) : null} index={index} onChangeSingleDate={this.onChangeSingleDate} onChangeDateRange={this.onChangeDateRange} disabledSingle={disabledSingle ? true : false} disabledRange={disabledRange ? true : false}/>}
                            </div>
                            <div className="buttons">
                                {primary ? 
                                    <Button className="add-btn" value={ADD_PRIMARY} onClick={(event) => this.addOrganizationItem(event)} /> 
                                    : 
                                    index === 0 ?
                                        <div className="group-btn">
                                            <Button className="add-btn" value={ADD_PRIMARY} onClick={(event) => this.addOrganizationItem(event)} />
                                            <Button className="remove-btn" value={REMOVE} onClick={(event) => this.removeOrganizationItem(event, index)} />
                                        </div>
                                        :
                                        <Button className="remove-btn" value={REMOVE} onClick={(event) => this.removeOrganizationItem(event, index)} />}
                                </div>
                        </div>
                    )
                } else return null;
            })
            }
        </div>
        );
    }
}

export default OrganizationFilter;