export const inputFields = [
    {
      label: 'First Name',
      id: 'first_name',
      validationError: '',
      valid: false,
      required: 'required'
    },
    {
      label: 'Middle Name (optional)',
      id: 'middle_name'
    },
    {
      label: 'Last Name',
      id: 'last_name',
      validationError: '',
      valid: false,
      required: 'required'
    },
    {
      label: 'Maiden Name (optional)',
      id: 'maiden_name'
    },
    {
      label: 'Nickname (optional)',
      id: 'nickname',
    },
    {
      label: 'Preffered First Name (optional)',
      id: 'preferred_first_name'
    },
    {
      label: 'Preffered Last Name (optional)',
      id: 'preferred_last_name'
    },
    {
      label: 'Prefix/Suffix for names',
      id: 'prefix'
    }
];