import React, { Component } from 'react';
import store from '../../stores';
import { observer } from 'mobx-react'; 

import logo from '../../images/logo.svg';
import './loginPage.scss';

@observer
class LoginPage extends Component {

componentDidMount() {
   store.getLink();
}

  render() {
    const { link } = store;
    return (
      <div>
          <div className='login-container'>
              <div className='login-block'>
                  <div className="logo-container">
                      <img src={logo} alt="logo"/>
                  </div>
                  <h2>Log In</h2>
                  <h5>Welcome to NATAS!</h5>
                  {link && 
                    <a href={link} className="login-with-google">
                      <i className="fab fa-google"></i>
                      <span>Log In with Google</span>    
                    </a>
                  }
              </div>    
          </div>
      </div>
    );
  }
}

export default LoginPage;
