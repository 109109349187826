import React, { Component } from 'react';
import { observer } from 'mobx-react';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
import ModalHeader from './ModalWindowElements/ModalHeader';
import { Button } from '../../helper/components';
import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
import deletion from '../../images/delete.svg';
import store from '../../stores/Store';
import { 
    CHANGES_WERE_SAVED, 
    ARE_YOU_SURE_TO_DELETE_THE_LINE, 
    DELETE
} from '../../helper/strings';

import './modal.scss';

@observer
class DeleteModal extends Component {

    onDeleteModalClose = async() => {
        store.isDeleteModalShown = false;
        store.success = false;
    }

    onDeleteUsers = async() => {
        const { deleteUser, deleteUserId, users, getUsersList } = store;
        const selectedUsers = users.filter((user)=>user.checked);

        async function asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
              await callback(array[index], index, array);
            }
        }

        const deleteUsers = async(arr) => {
            await asyncForEach(arr, async (user) => {
                await deleteUser(user.id)
            })
            getUsersList();
            store.success = true;
        }

        if (deleteUserId) {
            await deleteUser(deleteUserId)
            getUsersList();
            store.success = true;
        } else if (selectedUsers.length){
            await deleteUsers(selectedUsers);
        }
        store.deleteUserId = null;
    }

    onDeleteOrganization = async() => {
        const { deleteOrgId, deleteOrganization, getFilteredOrganizations, currentOrganizationsPage } = store;
        await deleteOrganization(deleteOrgId)
        getFilteredOrganizations({page: currentOrganizationsPage});
        store.success = true;
        store.deleteOrgId = null;
    }

    render() {
        const { isDeleteModalShown, isLoading, success, deleteOrgId } = store;

        if (isDeleteModalShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className='modal-window deletion'>
                            <div className='modal-body'>
                                {isLoading ? 
                                <div className='spinner-block'>
                                    <Spinner />
                                </div>   :
                                <div>
                                    {success ? 
                                        <SuccessWindow onModalClose={this.onDeleteModalClose} value={CHANGES_WERE_SAVED} />
                                        :
                                        <div>
                                            <ModalHeader value="Delete" onModalClose={this.onDeleteModalClose} />
                                            <div className='add-edit-body deletion'>
                                                <img src={deletion} alt="delete-the-line" />
                                                <div>{ARE_YOU_SURE_TO_DELETE_THE_LINE}</div>
                                            </div>
                                            <div className="add-edit-footer">
                                                {deleteOrgId ?
                                                    <Button className="primary-btn" onClick={() => this.onDeleteOrganization()} value={DELETE} />
                                                    :
                                                    <Button className="primary-btn" onClick={() => this.onDeleteUsers()} value={DELETE} />
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
            return null;
    }
}

export default DeleteModal;