import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input } from '../../../helper/components';
import { inputFields } from '../../../helper/form-data/info-input-fields';
import store from '../../../stores';
import { 
    IS_INCORRECT, 
    UPLOAD_PHOTO, 
    INFORMATION 
} from '../../../helper/strings';
import { NAME_FORMAT } from '../../../helper/variables';

@observer
class FormInformation extends Component {

    state = {
        avatar_url: this.props.user.avatar_url,
        avatar_data: this.props.user.avatar_data,
        first_name: this.props.user.first_name,
        middle_name: this.props.user.middle_name,
        last_name: this.props.user.last_name,
        maiden_name: this.props.user.maiden_name,
        nickname: this.props.user.nickname,
        preferred_first_name: this.props.user.preferred_first_name,
        preferred_last_name: this.props.user.preferred_last_name,
        prefix: this.props.user.prefix,
        inputFields: inputFields,
        isFormValid: false,
        changeAvatar: false,
    }

    componentDidMount() {
        store.updatedUserInfo.avatar_data = this.state.avatar_data;
        this.storeUpdater();
        this.onFormValidate();
        if(this.props.accountSettingsMode) {
            this.onCheckValidation();  
        }

        if(this.props.checkValidation || store.editedUser) {
            this.onCheckValidation();   
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.checkValidation !== this.props.checkValidation) {
            if(this.props.checkValidation) {
                this.onCheckValidation(); 
            }
        }
    }

    storeUpdater = () => {
        const updateObject = {
            avatar_url: this.state.avatar_url,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            maiden_name: this.state.maiden_name,
            nickname: this.state.nickname,
            preferred_first_name: this.state.preferred_first_name,
            preferred_last_name: this.state.preferred_last_name,
            prefix: this.state.prefix,
        }
        if(this.state.avatar_data) {
            store.updatedUserInfo = {
                ...updateObject,
                avatar_data: this.state.avatar_data,
            }
        } else {
            store.updatedUserInfo = updateObject;
        }
    }

    onChange(e) {
        const file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            const base64 = reader.result.split(',')[1];
            
            this.setState({avatar_data: base64, changeAvatar: true}, () => store.updatedUserInfo.avatar_data = this.state.avatar_data);
        }

        reader.readAsDataURL(file); 
    }

    onChangeTextInput = (e) => {
        if (e.target.id === 'first_name') {
            const isFirstNameValid = e.target.value.match(NAME_FORMAT);
            const isValidationError = isFirstNameValid ? '' : IS_INCORRECT;

            const updatedInputFields = this.state.inputFields.map((item) => {
                if(item.id === 'first_name') {
                    return {
                        ...item,
                        validationError: isValidationError,
                        valid: isValidationError.length === 0,
                    }
                } else return {...item}
            })

            this.setState({...this.state, inputFields: updatedInputFields}, this.onCheckValidation);
        }

        if (e.target.id === 'last_name') {
            const isLastNameValid = e.target.value.match(NAME_FORMAT);
            const isValidationError = isLastNameValid ? '' : IS_INCORRECT;

            const updatedInputFields = this.state.inputFields.map((item) => {
                if(item.id === 'last_name') {
                    return {
                        ...item,
                        validationError: isValidationError,
                        valid: isValidationError.length === 0,
                    }
                } else return {...item}
            })

            this.setState({...this.state, inputFields: updatedInputFields}, this.onCheckValidation);
        }

        this.setState({[e.target.id]: e.target.value}, () => this.storeUpdater());
    }

    onFormValidate = () => {
        const isInvalidFields = this.state.inputFields.filter((field)=>field.valid===false);
        if(isInvalidFields.length === 0) {
            store.formInfoValid = true;
        } else {
            store.formInfoValid = false;
        }
    }

    onCheckValidation = () => {
        const updatedInputFields = this.state.inputFields.map((item) => {
            if(item.id === 'first_name') {
                const isFirstNameValid = this.state.first_name ? this.state.first_name.match(NAME_FORMAT) : false;
                const isValidationError = isFirstNameValid ? '' : IS_INCORRECT;
                return {
                    ...item,
                    validationError: isValidationError,
                    valid: isValidationError.length === 0,
                }
            } else if(item.id === 'last_name') {
                const isLastNameValid = this.state.last_name ? this.state.last_name.match(NAME_FORMAT) : false;
                const isValidationError = isLastNameValid ? '' : IS_INCORRECT;
                return {
                    ...item,
                    validationError: isValidationError,
                    valid: isValidationError.length === 0,
                }
            } else return {...item}
        })
        this.setState({...this.state, inputFields: updatedInputFields}, this.onFormValidate);
    }

    render() {
        const { viewUserDetailsMode } = this.props;
        return (
            <div className="information">
                <div className="block-title">{INFORMATION}</div>
                <div className="image-loader">
                    <div className="image-container">                  
                        {!this.state.changeAvatar ?
                            this.state.avatar_url ?
                                <img src={`${this.state.avatar_url}`} alt="user_image"/> : this.state.avatar_data ? <img src={`data:image/jpeg;base64,${this.state.avatar_data}`} alt="user_image"/> : null
                            : 
                            <img src={`data:image/jpeg;base64,${this.state.avatar_data}`} alt="user_image"/> 
                        }
                    </div>
                    {viewUserDetailsMode ? 
                        null 
                        :
                        <label className="image-upload">
                            <input type="file" onChange={(e) => this.onChange(e)} />
                            {UPLOAD_PHOTO}
                        </label>}
                </div>
                <div className="text-inputs-container">
                    {this.state.inputFields.map((field, index) => {
                        const { label, id, validationError, required } = field;
                        return <Input key={index} label={label} id={id} type="text" onChange={(e) => this.onChangeTextInput(e)} value={this.state[id] ? this.state[id] : ''} validationError={validationError} required={required} showError={viewUserDetailsMode? false : true} viewUserDetailsMode={viewUserDetailsMode} />
                    })}
                </div>
            </div>
        )
    }
}

export default FormInformation;