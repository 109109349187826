import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormInformation from '../AccountSettings/FormInformation/FormInformation';
import FormAddress from '../AccountSettings/FormAddress/FormAddress';
import ModalHeader from './ModalWindowElements/ModalHeader';
import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
import store from '../../stores/Store';
import { defaultUserInfo, defaultUserAddress } from '../../stores/Store';
import { USER_DETAILS } from '../../helper/strings';

import './modal.scss';

@observer
class UserDetailsModal extends Component {

    onClose = () => {
        store.showAddressesValidationErrors = false;
        store.showPhonesValidationErrors = false;
        store.showEmailsValidationErrors = false;
        store.showOrgValidationErrors = false;
        store.showPosValidationErrors = false;

        store.formAddressValid = false;
        store.addressesValid = false;
        store.phonesValid = false;
        store.emailsValid = false;
        store.organizationsValid = false;
        store.positionsValid = false;

        store.editedUser = null;
        store.updatedUserInfo = defaultUserInfo;
        store.updatedUserAddress = defaultUserAddress;
    }

    onModalClose = () => {
        this.onClose();
        this.props.toggler(null)
    }

    render() {
        const { isShown } = this.props;
        const { isLoading } = store;
 
        if (isShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className="modal-window">
                            <div className='modal-body'>
                                {isLoading ? 
                                    <div className='spinner-block'>
                                        <Spinner />
                                    </div>   
                                        :
                                    <div>
                                        <ModalHeader value={USER_DETAILS} onModalClose={this.onModalClose} />
                                        <div className="scroll-block-users">
                                            <div className="add-edit-body">
                                                <FormInformation user={store.updatedUserInfo} viewUserDetailsMode={true} /> 
                                                <FormAddress user={store.updatedUserAddress} viewUserDetailsMode={true} />
                                            </div>
                                        </div>
                                        <div className="add-edit-footer">
                                        </div>
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
            return null;
    }
}

export default UserDetailsModal;