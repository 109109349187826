import { http } from './http';
import { URL_ELEMS } from '../helper/variables';


class CatalogServices {

    static get currentAddress() {
        return URL_ELEMS;
    }

    static getAuthLink = () => {
        return http.get('/v1/auth/get_link', {
            params: {
                redirect_uri: `${CatalogServices.currentAddress}/auth_callback`,
            }
        })
    }

    static getCodeFromGoogle = (code) => {
        return http.get('/v1/auth/oauth2_callback', {
            params: {
                code
            }
        })
    }

    static getListOfUsers = (config) => {
        return http.get('/v1/users', {
            params: config
        })
    }

    static getListOfUsersUnit = (config) => {
        return http.get('/v1/users', {
            params: config
        })
    }

    static getOrganizations = (config) => {
        return http.get('v1/organizations', {
            params: config
        })
    }

    static getPositions = () => {
        return http.get('v1/positions')
    }

    static getRoles = () => {
        return http.get('v1/roles')
    }

    static getTags = () => {
        return http.get('v1/users/tags')
    }

    static getDashboardData = () => {
        return http.get(`/v1/dashboard`)
    }

    static getUser = (id) => {
        return http.get(`/v1/users/${id}`)
    }

    static updateUser = (id, data) => {
        return http.put(`/v1/users/${id}`, data)
    }

    static updateUserAvatar = (id, avatarData) => {
        return http.put(`/v1/users/${id}`, avatarData)
    }

    static createUser = (data) => {
        return http.post(`/v1/users`, data)
    }

    static deleteUser = (id) => {
        return http.delete(`/v1/users/${id}`)
    }

    static getEditedOrganization = (id) => {
        return http.get(`v1/organizations/${id}`)
    }

    static getOrganizationPositions = (id) => {
        return http.get(`/v1/organizations/${id}/positions`, {
            params: {
                limit: 100,
            }
        })
    }

    static updateOrganizationPositions = (id) => {
        return http.put(`/v1/organizations/${id}/positions`)
    }

    static getOrganizationUsers = (id, config) => {
        return http.get(`/v1/organizations/${id}/users`, {
            params: config
        })
    }

    static updateOrganization = (id, data) => {
        return http.put(`/v1/organizations/${id}`, data)
    }

    static createOrganization = (data) => {
        return http.post(`/v1/organizations`, data)
    }

    static createPosition = (data) => {
        return http.post(`/v1/positions`, data)
    }

    static deletePositionFromOrg = (orgId, posId) => {
        return http.delete(`/v1/organizations/${orgId}/positions/${posId}`)
    }

    static deletePositionFromUser = (entId) => {
        return http.delete(`/v1/users/organizations/${entId}`)
    }

    static deleteOrganization = (id) => {
        return http.delete(`/v1/organizations/${id}`)
    }

    static deletePositionGroupFilter = (groupId, organizationId, positionId) => {
        return http.delete(`/v1/knowledges/${groupId}/organization/${organizationId}/position/${positionId}`)
    }  

    static exportContacts = (format) => {
        return http.get(`/v1/export`, {
            params: {
                format: format
            }
        })
    }

    static importContacts = (formData) => {
        return http.post(`/v1/import`, formData)
    }

    static getOrganizationsTree = () => {
        return http.get(`/v1/organizations/tree`)
    }

    static logOut = () => {
        return http.get(`/v1/auth/logout`)
    }
}


export default CatalogServices;