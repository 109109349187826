import { http } from './http';

export default class KnowledgeBaseService {

    static list = (config = {}) => {
        return http.get('/v1/knowledges', { params: config });
    };

    static show = (id) => {
        return http.get(`/v1/knowledges/${id}`);
    };

    static create = (data) => {
        return http.post('/v1/knowledges/', data);
    }

    static edit = (id, data) => {
        return http.put(`/v1/knowledges/${id}`, data);
    }

    static delete = (id) => {
        return http.delete(`/v1/knowledges/${id}`);
    }

    static organizationList = (id) => {
        return http.get(`/v1/organizations/${id}/knowledges`);
    }

    static deleteFilter = (groupId, filterId) => {
        return http.delete(`/v1/knowledges/${groupId}/filter/${filterId}`);
    }
}