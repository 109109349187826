import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PositionsDropdown from './PositionsDropdown/PositionsDropdown';
import UsersDropdown from './UsersDropdown/UsersDropdown';
import ListsDropdown from './ListsDropdown/ListsDropdown';
import ModalHeader from './ModalWindowElements/ModalHeader';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
import { Input, SelectType, SelectObject, Button } from '../../helper/components';
import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
import store from '../../stores/Store';
import KnowledgeBaseStore from '../../stores/KnowledgeBaseStore';
import {
    INFO_WAS_SAVED_SUCCESSFULLY,
    EDIT,
    CREATE
} from '../../helper/strings';

import './modal.scss';

@observer
class OrganizationModal extends Component {

    state = {
        selectedItem: '',
        formErrors: {unitTitle: '', unitType: '', unitPositions: ''},
        unitTitleValid: false,
        unitTypeValid: false,
        unitPositionsValid: false,
        formValid: false,
        showErrors: false,
    }

    componentDidUpdate(prevProps) {
        if(prevProps.orgType !== this.props.orgType) {

            if(this.props.orgType === 'organization') {
                this.setState({selectedItem: 'organization'})
            } else if (this.props.orgType === 'chapter'){
                this.setState({selectedItem: 'sub-org'})
            } else if (this.props.orgType === 'office'){
                this.setState({selectedItem: 'department'})
            } else if (this.props.orgType === 'department'){
                this.setState({selectedItem: 'committee'})
            }

            this.onParentList();
        }
     
        if(prevProps.isOrganizationModalShown !== this.props.isOrganizationModalShown ||
            prevProps.editedOrganizationData !== this.props.editedOrganizationData) {
            if(this.props.isOrganizationModalShown) {
                const title = this.props.editedOrganizationData.title ? this.props.editedOrganizationData.title : '';
                const type = this.props.editedOrganizationData.org_type ? this.props.editedOrganizationData.org_type : '';

                const unitTitleValid = title.length >= 2;
                const unitTypeValid = type !== "Not Selected";

                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.unitTitle = unitTitleValid ? '' : ' is invalid';
                fieldValidationErrors.unitType = unitTypeValid ? '' : ' must be selected';

                this.setState({
                    formErrors: fieldValidationErrors,
                    unitTitleValid: unitTitleValid,
                    unitTypeValid: unitTypeValid,
                    showErrors: false
                }, this.validateForm)
            }
        }
        if(prevProps.isOrganizationModalShown !== this.props.isOrganizationModalShown ||
            prevProps.editedOrganizationPositions !== this.props.editedOrganizationPositions) {
            if(this.props.isOrganizationModalShown) {
                const unitPositionsValid = this.props.editedOrganizationPositions.length > 0;
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.unitPositions = unitPositionsValid ? '' : ' must be applied (at least one should be added)';

                this.setState({
                    formErrors: fieldValidationErrors,
                    unitPositionsValid: unitPositionsValid,
                }, this.validateForm)
            }
        }
    }

    validateField = (fieldName, value) => {
 
        let fieldValidationErrors = this.state.formErrors;
        let unitTitleValid = this.state.unitTitleValid;
        let unitTypeValid = this.state.unitTypeValid;
        let unitPositionsValid = this.state.unitPositionsValid;

        switch(fieldName) {
            case 'title':
                unitTitleValid = value.length >= 2;
                fieldValidationErrors.unitTitle = unitTitleValid ? '' : ' is invalid';
                break;
            case 'organization_type':
                unitTypeValid = value !== "Not Selected";
                fieldValidationErrors.unitType = unitTypeValid ? '' : ' must be selected';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            unitTitleValid: unitTitleValid,
            unitTypeValid: unitTypeValid,
            unitPositionsValid: unitPositionsValid,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            ...this.state,
            formValid: this.state.unitTitleValid &&
                        this.state.unitTypeValid &&
                        this.state.unitPositionsValid
        }); 
    }

    onTitleChange = (e) => {
        const { id: name, value } = e.target;

        store.editedOrganizationData.title = value;
        store.editedOrganizationData.name = value;

        this.validateField(name, value);
        this.setState({showErrors: true})
    }

    onTypeChange = (e) => {
        const { id: name, value } = e.target;

        switch(value) {
            case 'organization':
                store.editedOrganizationData.org_type = value;
                break;
            case 'sub-org':
                store.editedOrganizationData.org_type = 'chapter';
                break;
            case 'department':
                store.editedOrganizationData.org_type = 'office';
                break;
            case 'committee':
                store.editedOrganizationData.org_type = 'department';
                break;

            default:
                return null;
        }

        this.onParentList();

        this.validateField(name, value);
        this.setState({selectedItem: value, showErrors: true})
    }

    onParentList = () => {
        const { editedOrganizationData, organizationList } = store;

        if (editedOrganizationData.org_type === "organization") {
            store.selectOrganizationList = [];
            store.selectedParent = {};
            store.editedOrganizationData.parent_id = 0;
        } else if (editedOrganizationData.org_type === "chapter") {
            const selectList = [...organizationList.filter((item) => item.org_type === "organization")];
            const selectedParent = selectList.length ? selectList[0] : null;
            store.selectedParent = selectedParent;
            const selectOrganizationList = selectList.map((item) => {
                const path = store.unitsTree.find((unit) => unit.id === item.id).path.slice(-1)[0];
                return {
                    ...item,
                    path: path
                }
            });
            store.selectOrganizationList = selectOrganizationList;
            store.editedOrganizationData.parent_id = selectedParent ? selectedParent.id : 0;
        } else if (editedOrganizationData.org_type === "office") {
            const selectList = [...organizationList.filter((item) => item.org_type === "chapter")];
            const selectedParent = selectList.length ? selectList[0] : null;
            store.selectedParent = selectedParent;
            const selectOrganizationList = selectList.map((item) => {
                const path = store.unitsTree.find((unit) => unit.id === item.id).path.slice(-2).join(' | ');
                return {
                    ...item,
                    path: path
                }
            });
            store.selectOrganizationList = selectOrganizationList;
            store.editedOrganizationData.parent_id = selectedParent ? selectedParent.id : 0;
        } else if (editedOrganizationData.org_type === "department") {
            const selectList = [...organizationList.filter((item) => item.org_type === "office")];
            const selectedParent = selectList.length ? selectList[0] : null;
            store.selectedParent = selectedParent;
            const selectOrganizationList = selectList.map((item) => {
                const path = store.unitsTree.find((unit) => unit.id === item.id).path.slice(-3).join(' | ');
                return {
                    ...item,
                    path: path
                }
            });
            store.selectOrganizationList = selectOrganizationList;
            store.editedOrganizationData.parent_id = selectedParent ? selectedParent.id : 0;
        } else if (editedOrganizationData.org_type === "other") {
            const selectList = [...organizationList.filter((item) => item.org_type === "department")];
            const selectedParent = selectList.length ? selectList[0] : null;
            store.selectedParent = selectedParent;
            store.selectOrganizationList = selectList;
            store.editedOrganizationData.parent_id = selectedParent ? selectedParent.id : 0;
        } else {
            store.selectOrganizationList = [];
            store.selectedParent = {};
            store.editedOrganizationData.parent_id = 0;
        }
    }

    onParentChange = (e) => {
        const selectedParent = store.selectOrganizationList.filter((item) => item.path === e.target.value);
        store.selectedParent = {...selectedParent};
        store.editedOrganizationData.parent_id = selectedParent[0].id;
    }

    onCreateUnit = async() => {
        if(this.state.formValid) {
            await store.createNewOrganization()
            store.createOrganizationMode = false;
            store.viewedOrgUsersList = [];
            store.viewedOrgUsersListPage = 1;
            store.viewedOrgUsersListTotalPages = 0;
            store.updatedOrgUsersListPage = 1;
            store.updatedOrgUsersListTotalPages = 0;
            store.editedOrganizationPositions = [];
            store.searchOrgUsersInputValue = '';
            store.addedUsersBeforeUnitCreate = [];
            KnowledgeBaseStore.unitList = [];
            KnowledgeBaseStore.unitListBeforeAddingUnit = [];
            store.getFilteredOrganizations({page: store.currentOrganizationsPage});
            this.setState({...this.state, showErrors: false})
        } else this.setState({...this.state, showErrors: true})
    }

    onEditUnit = async() => {
        if(this.state.formValid) {
            await store.updateOrganization();
            store.editOrganizationMode = false;
            store.viewedOrgUsersList = [];
            store.viewedOrgUsersListPage = 1;
            store.viewedOrgUsersListTotalPages = 0;
            store.updatedOrgUsersListPage = 1;
            store.updatedOrgUsersListTotalPages = 0;
            store.editedOrganizationPositions = [];
            store.searchOrgUsersInputValue = '';
            KnowledgeBaseStore.unitList = [];
            store.getFilteredOrganizations({page: store.currentOrganizationsPage});
            this.setState({...this.state, showErrors: false})
        } else this.setState({...this.state, showErrors: true});
    }

    onModalClose = () => {
        store.searchOrgUsersInputValue = '';
        store.selectOrganizationList = [];
        store.isOrganizationModalShown = false;
        KnowledgeBaseStore.searchValue = '';
        store.success = false;
        store.organizationFormValid = false;
        store.editedOrganizationData.org_type = 'Not Selected';
        store.editedOrganizationData.title = '';
        store.editedOrganizationData.name = '';
        store.editedOrgId = null;
        store.editOrganizationMode = false;
        store.viewedOrgUsersList = [];
        store.viewedOrgUsersListPage = 1;
        store.viewedOrgUsersListTotalPages = 0;
        store.updatedOrgUsersListPage = 1;
        store.updatedOrgUsersListTotalPages = 0;
        store.editedOrganizationPositions = [];
        store.addedUsersBeforeUnitCreate = [];
        KnowledgeBaseStore.unitList = [];
        KnowledgeBaseStore.unitListBeforeAddingUnit = [];

        this.setState({
            selectedItem: '',
            formErrors: {unitTitle: '', unitType: '', unitPositions: ''},
            unitTitleValid: false,
            unitTypeValid: false,
            unitPositionsValid: false,
            formValid: false,
            showErrors: false
        })
        store.getFilteredOrganizations();
    }

    render() {
        const {isOrganizationModalShown, 
            editOrganizationMode, 
            editedOrganizationData, 
            uniqueOrganizationTypes, 
            isLoading, 
            success, 
            selectOrganizationList,
            selectedParent } = store;
        const stepHeader = editOrganizationMode ? 'Edit Unit' : 'Add Unit';
        const title = selectedParent && selectedParent.path;
        const { selectedItem, formErrors, showErrors } = this.state;

        const usersList = store.updatedOrganizationUsersList.map((user) => {
            if (user.organizations.find((org) => org.id === store.editedOrgId)) {
                return {...user, includes: true}
            } else {
                return {...user, includes: false}
            }
        });
        
        let users = [];

        if (!store.editedOrgId) {
            users = store.searchOrgUsersInputValue.length ? usersList : store.addedUsersBeforeUnitCreate.map((item) => {return {...item, includes: true}});
        } else {
            users = store.searchOrgUsersInputValue.length ? usersList : store.viewedOrgUsersList.map((item) => {return {...item, includes: true}});
        }

        const unitList = store.editedOrgId ? KnowledgeBaseStore.unitList : KnowledgeBaseStore.unitListBeforeAddingUnit;
        
        if (isOrganizationModalShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className={`modal-window ${success ? 'success' : ''}`}>
                            <div className='modal-body'>
                                {isLoading ? 
                                <div className='spinner-block'>
                                    <Spinner />
                                </div>   
                                :
                                <div>
                                    {success ? 
                                        <SuccessWindow onModalClose={this.onModalClose} value={INFO_WAS_SAVED_SUCCESSFULLY} />
                                        :
                                        <div>
                                            <ModalHeader value={stepHeader} onModalClose={this.onModalClose} />
                                            <div className="scroll-block-units">
                                                <div className="add-edit-body">
                                                    <Input 
                                                        label="Title" 
                                                        id="title" 
                                                        type="text" 
                                                        onChange={(e) => this.onTitleChange(e)} 
                                                        value={editedOrganizationData.title ? editedOrganizationData.title : ''} 
                                                        validationError={formErrors.unitTitle}
                                                        showError={showErrors}
                                                    />
                                                    <div className="select-group">
                                                        <SelectType 
                                                            label="Type" 
                                                            id="organization_type" 
                                                            options={uniqueOrganizationTypes} 
                                                            onChange={(e) => this.onTypeChange(e)} 
                                                            value={selectedItem} 
                                                            validationError={formErrors.unitType}
                                                            showError={showErrors} 
                                                        />
                                                        <SelectObject 
                                                            label="Nested In" 
                                                            id="nested_in" 
                                                            options={selectOrganizationList} 
                                                            onChange={(e) => this.onParentChange(e)} 
                                                            value={title} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="add-edit-body-dropdowns">
                                                    <PositionsDropdown validationError={formErrors.unitPositions} showError={showErrors} />
                                                    <UsersDropdown users={users} orgId={store.editedOrgId} /> 
                                                    <ListsDropdown orgId={store.editedOrgId} unitList={unitList} /> 
                                                </div>
                                            </div>
                                            <div className="add-edit-footer">
                                                {editOrganizationMode ?
                                                    <Button className="primary-btn" onClick={() => this.onEditUnit()} value={EDIT} /> :
                                                    <Button className="primary-btn" onClick={() => this.onCreateUnit()} value={CREATE} />
                                                }
                                            </div>
                                        </div>   
                                    }
                                </div>
                            }   
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
            return null;
    }
}

export default OrganizationModal;