import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormInformation from '../AccountSettings/FormInformation/FormInformation';
import FormAddress from '../AccountSettings/FormAddress/FormAddress';
import ModalHeader from './ModalWindowElements/ModalHeader';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
import { Button } from '../../helper/components';
import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
//import cancel from '../../images/cancel.svg';
import store from '../../stores/Store';
import { defaultUserInfo, defaultUserAddress } from '../../stores/Store';
import { 
    INFO_WAS_SAVED_SUCCESSFULLY, 
    NEXT,
    BACK,
    EDIT, 
    CREATE 
} from '../../helper/strings';

import './modal.scss';

@observer
class UserModal extends Component {

    state = {
        step1: true,
        checkValidation: false,
        checkValidation2: false,
    }

    onScrollTop = () => {
        const scrollBody = document.getElementById('scroll-block');
        scrollBody.scrollTop = 0;
    }

    onHandleStepsNext = () => {
        this.onScrollTop();
        if (store.formInfoValid) {
            this.setState((state) => {return {...state, step1: false, checkValidation: false}})
        } else {
            this.setState((state) => {return {...state, checkValidation: true}})
        }    
    }

    onHandleStepsBack = () => {
        this.onScrollTop();
        if (store.formInfoValid) {
            this.setState((state) => {return {...state, step1: true, checkValidation: true}})
        } else {
            this.setState((state) => {return {...state, checkValidation: true}})
        }
        store.showAddressesValidationErrors = false;
        store.showPhonesValidationErrors = false;
        store.showEmailsValidationErrors = false;
        store.showOrgValidationErrors = false;
        store.showPosValidationErrors = false;
    }

    onShowValidationErrors = () => {
        if (!store.addressesValid) {
            store.showAddressesValidationErrors = true
        }
        if (!store.phonesValid) {
            store.showPhonesValidationErrors = true
        }
        if (!store.emailsValid) {
            store.showEmailsValidationErrors = true
        }
    }

    onCreateUser = async() => {
        if(store.formAddressValid) {
            await store.createNewUser();
            if(store.viewedOrganizationId) {
                await store.getOrganizationUsers(store.viewedOrganizationId);
            } else if(store.editedOrgId) {
                await store.getOrganizationUsers(store.editedOrgId);
            }
            this.onClose();
            this.setState({...this.state, checkValidation2: false})
        } else {
            this.onShowValidationErrors();
            store.onCheckboxOrgValidation();
            store.onCheckboxPosValidation();
            this.setState({...this.state, checkValidation2: true})
        }
    }

    onEditUser = () => {
        if(store.formAddressValid) {
            store.updateUserFromList(store.editedUser.id)
            this.onClose();
            this.setState({step1: true, checkValidation: false, checkValidation2: false}); 
        } else {
            this.onShowValidationErrors();
            store.onCheckboxOrgValidation();
            store.onCheckboxPosValidation();
            this.setState({...this.state, checkValidation2: true})
        }
    }

    onClose = () => {
        store.showAddressesValidationErrors = false;
        store.showPhonesValidationErrors = false;
        store.showEmailsValidationErrors = false;
        store.showOrgValidationErrors = false;
        store.showPosValidationErrors = false;

        store.formAddressValid = false;
        store.addressesValid = false;
        store.phonesValid = false;
        store.emailsValid = false;
        store.organizationsValid = false;
        store.positionsValid = false;

        store.editedUser = null;
        store.updatedUserInfo = defaultUserInfo;
        store.updatedUserAddress = defaultUserAddress;
    }

    onModalClose = async() => {
        store.isUserModalShown = false;
        this.onClose();

        store.success = false;
        this.setState({step1: true, checkValidation: false, checkValidation2: false});

        store.getOrganizations();
        store.getPositions();
    }

    render() {
        const {isUserModalShown, isLoading, success, editedUser, createUserMode, editUserMode } = store;
        const { step1 } = this.state;
        const stepHeader = editedUser ? 'Edit User ' : 'Add New User ';
        const headerValue = step1 ? `${stepHeader}(Step 1)` : `${stepHeader}(Step 2)`;
 
        if (isUserModalShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className={`modal-window user ${success ? 'success' : ''}`}>
                            <div className='modal-body'>
                                {isLoading ? 
                                <div className='spinner-block'>
                                    <Spinner />
                                </div>   
                                    :
                                <div>
                                    {success ? 
                                        <SuccessWindow onModalClose={this.onModalClose} editedUser={editedUser} value={INFO_WAS_SAVED_SUCCESSFULLY} />
                                        :
                                        <div>
                                            <ModalHeader value={headerValue} onModalClose={this.onModalClose} />
                                            <div className="scroll-block-users" id="scroll-block">
                                                <div className="add-edit-body">
                                                    {createUserMode ?
                                                        step1 ? <FormInformation user={store.updatedUserInfo} checkValidation={this.state.checkValidation} /> : <FormAddress user={store.updatedUserAddress} />
                                                        : null
                                                    }
                                                    {editUserMode ?
                                                        step1 ? <FormInformation user={store.updatedUserInfo} checkValidation={this.state.checkValidation} /> : <FormAddress user={store.updatedUserAddress} />
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="add-edit-footer">
                                                {step1 ? 
                                                <>
                                                    <Button className="primary-btn" onClick={() => this.onHandleStepsNext()} value={NEXT} />
                                                </>
                                                    :
                                                <>
                                                    <Button className="secondary-btn" onClick={()=>this.onHandleStepsBack()} value={BACK} />
                                                    {editedUser ? 
                                                        <Button className="primary-btn" onClick={() => this.onEditUser()} value={EDIT} />
                                                        : <Button className="primary-btn" onClick={() => this.onCreateUser()} value={CREATE} />
                                                    }
                                                </>}
                                            </div>
                                        </div> 
                                    }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
            return null;
    }
}

export default UserModal;

