import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PositionsDropdown from './PositionsDropdown/PositionsDropdown';
import ModalHeader from './ModalWindowElements/ModalHeader';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
//import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
import store from '../../stores/Store';
import { INFO_WAS_SAVED_SUCCESSFULLY } from '../../helper/strings';

import './modal.scss';

@observer
class ViewPositionsModal extends Component {

    onModalClose = async() => {
        store.isViewPositionsModalShown = false;
        store.success = false;
    }

    render() {
        const { isViewPositionsModalShown, isLoading, success } = store;

        if (isViewPositionsModalShown) {
            return (
                // <Portal>
                    <div className="modal-overlay">
                        <div className="modal-window">
                            <div className='modal-body'>
                                {isLoading ? 
                                <div className='spinner-block'>
                                    <Spinner />
                                </div>  
                                :
                                <div>
                                    {success ? 
                                        <SuccessWindow onModalClose={this.onModalClose} value={INFO_WAS_SAVED_SUCCESSFULLY} />
                                        :
                                        <div>
                                            <ModalHeader value='View Position' onModalClose={this.onModalClose} />
                                            <div className="scroll-block-units">
                                                <div className="add-edit-body-dropdowns">
                                                    <PositionsDropdown />
                                                </div>
                                            </div>
                                            <div className="add-edit-footer">
                                            </div>
                                        </div>
                                    }
                                </div>
                            }   
                            </div>
                        </div>
                    </div>
                // </Portal>
            )
        }
            return null;
    }
}

export default ViewPositionsModal;