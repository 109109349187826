import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input, Button } from '../../../helper/components';
import GroupDetailsModal from '../GroupDetailsModal';
import GroupModal from '../GroupModal';
import KnowledgeBaseStore from '../../../stores/KnowledgeBaseStore';
import KnowledgeBaseService from '../../../services/KnowledgeBaseService';
import Spinner from '../../Spinner';
import {
    LINK,
    LINKED,
    TYPE,
    TITLE,
    NO_RECORDS_FOUND
} from '../../../helper/strings';

@observer
class Group extends Component {

    onLinkGroup = async(id) => {
        const { orgId, item } = this.props;

        const data = {
            filters: [{
                organization_id: orgId,
            }],  
        }

        if(orgId) {
            await KnowledgeBaseService.edit(id, data)
            KnowledgeBaseStore.getUnitList(orgId);
        } else if (!orgId) {
            KnowledgeBaseStore.unitListBeforeAddingUnit = [...KnowledgeBaseStore.unitListBeforeAddingUnit, {...item, linked: true}]
        }
    }

    render() {
        const { source, title, id, linked } = this.props.item;
        const { onViewLineDetails } = this.props;
        
        return (
            <tr>
                <td>
                    {source}
                </td>
                <td style={{overflow: 'hidden'}}>
                    {title}
                </td>
                {linked ? 
                    <td className="group-link" onClick={() => onViewLineDetails(id)}>
                        {LINKED}
                    </td>
                    : 
                    <td className="group-link" onClick={() => this.onLinkGroup(id)}>
                        {LINK}
                    </td>
                }
            </tr>
        )
    }
}

@observer
class ListsDropdown extends Component {
    state = {
        isListsOpen: true,
        searchListsValue: '',
        isShownModalDetail: false,
        isShownModalCreate: false,
        unitList: [],
    }

    componentDidMount() {
        const { unitList } = this.props;

        const linkedGroups = unitList.map((group) => {return {...group, linked: true}})
        this.setState({...this.state, unitList: linkedGroups})
        KnowledgeBaseStore.isLoading = false;  
    }

    componentDidUpdate(prevProps) {
        if(prevProps.unitList !== this.props.unitList) {
            const linkedGroups = this.props.unitList.map((group) => {return {...group, linked: true}})
            this.setState({...this.state, unitList: linkedGroups})
        }
    }

    onChange = (e) => {
        const { value } = e.target;
        KnowledgeBaseStore.searchValue = value;
        this.setState({...this.state, searchListsValue: value})
    }

    onToggle = () => {
        this.setState({...this.state, isListsOpen: !this.state.isListsOpen})
    }

    onCreateModalToggle = () => {
        KnowledgeBaseStore.success = false;
        if (this.props.orgId) {
            KnowledgeBaseStore.defaultGroup.filters = [{organization_id: this.props.orgId, position_id: null}]
        }   
        this.setState({...this.state, isShownModalCreate: !this.state.isShownModalCreate})
    };

    onViewLineDetails = async (id = null) => {
        let isShownModalDetail = false;
        if (id !== null) {
            isShownModalDetail = true;
            this.getDetailsInfo(id);
        }
        this.setState({...this.state, isShownModalDetail: isShownModalDetail})
    };

    getDetailsInfo(id) {
        return KnowledgeBaseStore.show(id);
    }

    formatDate = (date) => {
        const d = new Date(date);
        const newDate = d.getUTCDate();
        const newMonth = d.getUTCMonth()+1;
        const newYear = d.getUTCFullYear();
        const newHours = d.getUTCHours();
        let newMinutes = d.getUTCMinutes();

        function addZeroBefore(n) {
            return (n < 10 ? '0' : '') + n;
        }

        return `${newMonth}/${newDate}/${newYear} ${newHours}:${addZeroBefore(newMinutes)}`;
    }

    
    render() {
        const { unitList, isListsOpen, isShownModalDetail, isShownModalCreate, searchListsValue } = this.state;
        const { items, isLoading, singleItem, singleItemLoaded, defaultGroup } = KnowledgeBaseStore;

        const linkedItems = unitList.length ? items.filter(o1 => unitList.some(o2 => o1.id === o2.id)).map((item) => {return {...item, linked: true}}) : items;
        const unLinkedItems = unitList.length ? items.filter(o1 => !unitList.some(o2 => o1.id === o2.id)).map((item) => {return {...item, linked: false}}) : items;

        const updatedItems = [...linkedItems, ...unLinkedItems].filter((thing, index, self) =>
            index === self.findIndex((t) => (
            t.id === thing.id
            ))
        );

        const list = searchListsValue.length ? updatedItems : unitList;

        return (
            <div className="lists">
                <div className="toggler">
                    <div className={`toggle-checkbox ${isListsOpen ? 'open' : ''}`} onClick={this.onToggle}></div>
                    <div className="label">Lists ({list.length})</div>
                </div>
                <div className={`opened-info ${isListsOpen ? 'open' : ''}`}>
                    <div className="graphic-indicator">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="data">
                        <div className="panel">
                            <div className="search-input">
                                <Input type="text" onChange={this.onChange} placeholder='Search' value={searchListsValue} />
                            </div>
                            <Button className='button-primary' value='+ Add New List' onClick={()=>this.onCreateModalToggle()} />
                        </div>
                        <table className="organization-modal-grid" style={{textTransform: "capitalize"}}>
                            <thead>
                                <tr>
                                    <th>{TYPE}</th>
                                    <th>{TITLE}</th>
                                    <th>Link</th>
                                </tr>
                            </thead>
                            {isLoading ? 
                                <tbody>
                                    <tr>
                                        <td colSpan="3">
                                            <Spinner />
                                        </td>
                                    </tr>  
                                </tbody>
                                :
                                list.length ? 
                                    <tbody>
                                        {list.map((item, index) => <Group item={item} key={index} onViewLineDetails={this.onViewLineDetails} orgId={this.props.orgId} />)}
                                    </tbody>
                                    :
                                    <tbody className="no-results-body">
                                        <tr>
                                            <td colSpan="3">
                                                <div className="no-results">{NO_RECORDS_FOUND}</div>
                                            </td>
                                        </tr>  
                                    </tbody>
                            }  
                        </table>
                    </div>
                </div>   
                <GroupDetailsModal isShown={isShownModalDetail}
                                    toggler={this.onViewLineDetails}
                                    item={singleItem}
                                    loaded={singleItemLoaded}
                                    formatDate={this.formatDate} 
                                    />
                <GroupModal isShown={isShownModalCreate}
                            toggler={this.onCreateModalToggle}
                            editedGroup={false}
                            item={defaultGroup}
                            loaded={true}
                            createGroupMode={true}
                            filters={KnowledgeBaseStore.defaultGroup.filters} 
                            users={[]}
                            />    
            </div>
            
        )
    }
}

export default ListsDropdown;