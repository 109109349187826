import React from 'react';
import { observer } from 'mobx-react';
import ModalHeader from './ModalHeader';
import { SUCCESS } from '../../../helper/strings';

const SuccessWindow = ({onModalClose, value}) => {
    return (
        <div className="success-window">
            <ModalHeader value={SUCCESS} onModalClose={onModalClose} />
            <div className='add-edit-body'>
                {value}
            </div>
            <div className="add-edit-footer">
            </div>
        </div>
    )
}

export default observer(SuccessWindow);