import React, { Component } from 'react';
import Header from '../Header'
import Sidebar from '../Sidebar';
import { observer } from 'mobx-react';

import './adminPage.scss';
@observer
class AdminPage extends Component {

    state = {
        isOpen: false,
        windowWidth: window.innerWidth
    }

    componentDidMount = async() => {
        this.additionalToggle();
        window.addEventListener('resize', this.onUpdateWidth)
    }
    
    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onUpdateWidth)
    }

    onToggleSidebar = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    onUpdateWidth = () => {
      this.setState({...this.state, windowWidth: window.innerWidth}, ()=> this.additionalToggle())
    }

    additionalToggle = () => {
        if(this.state.windowWidth > 900) {
            this.setState({...this.state, isOpen: true})
        } else if (this.state.windowWidth <= 900) {
            this.setState({...this.state, isOpen: false})
        }
    }

    render() {
        return (
            <div>
                <div className="main-container">
                    <div className={`sidebar-container ${this.state.isOpen ? '' : 'close'}`}>
                        <Sidebar onToggleSidebar={this.onToggleSidebar} windowWidth={this.state.windowWidth} />
                    </div>
                    <div className="data-container">
                        <Header onToggleSidebar={this.onToggleSidebar} isSidebarOpen={this.state.isOpen} />
                        <div className="data">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminPage;