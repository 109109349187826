import React, { Component } from 'react';

class ExpandableList extends Component {

    state = {
        isActive: false,
    }

    wrapper = React.createRef();

    handleClick = (e) => {
        if (this.wrapper.current.contains(e.target)) {
            this.setState({isActive: !this.state.isActive});
        }
    }

    render() {
        const { list, className } = this.props;
        const { isActive } = this.state;
        const activeItemsArray = className === 'tags' ? list.filter((item) => item.active) : list;

        if (activeItemsArray && activeItemsArray.length > 1) {
            return (
                <div ref={this.wrapper} className={className}>
                    {isActive ? 
                        <>
                            <div className="items">
                                {activeItemsArray.map((item, index) => {
                                    return <div key={index}>{item.title || item.name}</div>
                                })}
                            </div>
                            <span onClick={this.handleClick} className="toggler">
                                -
                            </span>
                        </>
                    : 
                    <>
                        <span className="items">
                            <div>{activeItemsArray.length ? (activeItemsArray[0].title || activeItemsArray[0].name) : null}</div>
                        </span>
                        <span onClick={this.handleClick} className="toggler">+{activeItemsArray.length-1}</span>
                    </> 
                }  
                </div>
            )
        } else if (activeItemsArray && activeItemsArray.length === 1) {
            return (
                <div ref={this.wrapper} className={className}>
                    <span className="items">
                        <div>{activeItemsArray.length ? (activeItemsArray[0].title || activeItemsArray[0].name) : null}</div>
                    </span>
                </div>
            )
        } else return null;
    }
}

export default ExpandableList;