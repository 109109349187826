import React from 'react';

import './spinner.scss';

const styleSpinner = {
    display: "flex",
    justifyContent: "center",
    margin: "25% 25%"
}

const Spinner = () => {
    return (
        <div className="lds-css ng-scope" style={styleSpinner}>
            <div className="lds-rolling">
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner;