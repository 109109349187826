import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    Input,
    SelectType,
    SelectObject,
    Textarea,
    Button,
    BlockInfo,
    Notes
  } from '../../../helper/components';
import OrganizationFilter from './OrganizationsFilter';
import TagInput from '../TagInput/TagInput';
import store from '../../../stores';
import { EMAIL_DOMAIN_PRIMARY, EMAIL_DOMAIN_SECONDARY, EMAIL_FORMAT } from '../../../helper/variables';
import { mailItem, phoneItem, emailItem, notes} from '../../../helper/form-data/address-fields';
import { 
    IS_INCORRECT, 
    ORGANIZATION, 
    SUBORG, 
    DEPARTMENT, 
    COMMITTEE, 
    ADDRESS, 
    REMOVE, 
    IS_INVALID, 
    POSITION,
    ROLE,
    TAG,
    ADD_PRIMARY
} from '../../../helper/strings';

//TODO REFACTOR IT
@observer
class FormAddress extends Component {
    state = {
        mailFields: [...this.props.user.addresses_users.map((address, index) => {
                    return {
                        id: address.id,
                        active: address.active,
                        label: 'Mailing Address',
                        input_id: `input_mailing_address${index}`,
                        select_id: `select_mailing_address${index}`,
                        options: ["personal", "working", "mailing"],
                        defaultField: address.primary,
                        input_value: address.value,
                        select_value: address.value_type ? address.value_type : '',
                        validationError: '',
                        valid: false,
                        required: 'required',
                    }
        })],
        phoneFields: [...this.props.user.phones_users.map((phone, index) => {
                    return {
                        id: phone.id,
                        active: phone.active,
                        label: 'Phone',
                        input_id: `input_phone${index}`,
                        select_id: `select_phone${index}`,
                        options: ["personal", "business", "mobile"],
                        defaultField: phone.primary,
                        input_value: phone.value,
                        select_value: phone.phone_type ? phone.phone_type : '',
                        validationError: '',
                        valid: false,
                        required: 'required',
                        placeholder: '',
                    }
        })],  
        primaryEmail: {
            label: 'Email',
            input_id: `input_email_main`,
            defaultField: true,
            validationError: '',
            input_value: this.props.user.primary_email,
            valid: false,
            required: 'required'
        },
        emailFields: [...this.props.user.emails_users.map((email, index) => {
                        return {
                            id: email.id,
                            active: email.active,
                            label: 'Email',
                            input_id: `input_email${index}`,
                            defaultField: email.primary,
                            validationError: '',
                            input_value: email.value,
                            valid: false,
                            required: 'required',
                        }
                    })
        ],
        mailsCount: 1,
        phonesCount: 1,
        emailsCount: 1,
        organizations: this.props.user.organizations,
        role: this.props.user.role,
        role_id: this.props.user.role_id,
        private_notes: this.props.user.private_notes,
        public_notes: this.props.user.public_notes,
        tags_users: this.props.user.tags_users,
    }

    componentWillMount() {
        this.onCheckPhoneValidation();
    }

    componentDidMount() {
        const { accountSettingsMode } = this.props;
        this.onCheckEmailValidation();

        if (!accountSettingsMode) {
            store.onCheckboxOrgValidationInitial();
            store.onCheckboxPosValidationInitial();
        }
        
        const updatedUserAddress = {
            addresses_users: this.state.mailFields,
            phones_users: this.state.phoneFields,
            emails_users: this.state.emailFields,
            private_notes: this.state.private_notes,
            public_notes: this.state.public_notes,
            organizations: this.state.organizations,
            role: this.state.role,
            role_id: this.state.role_id,
            tags_users: this.state.tags_users,
        }

        const updatedAccountUserAddress = {
            addresses_users: this.state.mailFields,
            phones_users: this.state.phoneFields,
            emails_users: this.state.emailFields,
        }

        if (accountSettingsMode) {
            store.updatedUserAddress = updatedAccountUserAddress;
        } else {           
            store.updatedUserAddress = updatedUserAddress;
        }
        
    }

    componentDidUpdate(prevState) {

        const filteredMails = this.state.mailFields.filter((item) => item.valid);  
        const filteredPhones = this.state.phoneFields.filter((item) => item.valid);
        const filteredEmails = this.state.emailFields.filter((item) => item.valid);       
        
        if(prevState.mailFields !== this.state.mailFields) {  
            store.updatedUserAddress.addresses_users = this.state.mailFields.map((field, index) => {
                const { input_value, defaultField, id, active, select_value } = field;
                if(id) {
                    return {
                        id: id,
                        active: active, 
                        value: input_value,
                        primary: defaultField,
                        value_type: select_value
                    }
                } else {
                    return {
                        active: active,
                        value: input_value,
                        primary: defaultField,
                        value_type: select_value
                    }
                }
            });
        }
        if(prevState.phoneFields !== this.state.phoneFields) {
            store.updatedUserAddress.phones_users = this.state.phoneFields.map((field, index) => {
                const { input_value, defaultField, select_value, id, active } = field;
                if(id) {
                    return {
                        id: id, 
                        active: active,
                        value: input_value,
                        primary: defaultField,
                        phone_type: select_value
                    }
                } else {
                    return {
                        active: active,
                        value: input_value,
                        primary: defaultField,
                        phone_type: select_value
                    }
                }
            });
        }
        if(prevState.emailFields !== this.state.emailFields) {
            store.updatedUserAddress.emails_users = this.state.emailFields.map((field, index) => {
                const { input_value, defaultField, id, active } = field;
                if(id) {
                    return {
                        id: id, 
                        active: active,
                        value: input_value,
                        primary: defaultField, 
                    }
                } else {
                    return {
                        active: active,
                        value: input_value,
                        primary: defaultField, 
                    }
                }
            });
        }

        if(prevState.role !== this.state.role) {
            const id = this.state.role.id;
            store.updatedUserAddress.role = this.state.role;
            store.updatedUserAddress.role_id = id;
        }

        if(!this.props.accountSettingsMode && prevState.private_notes !== this.state.private_notes) {
            store.updatedUserAddress.private_notes = this.state.private_notes;
        }

        if(!this.props.accountSettingsMode && prevState.public_notes !== this.state.public_notes) {
            store.updatedUserAddress.public_notes = this.state.public_notes;
        }               
        
        if (prevState.phoneFields && prevState.phoneFields.length !== this.state.phoneFields) {
            if(this.state.phoneFields.length === filteredPhones.length) {
                store.phonesValid = true;
            } else store.phonesValid = false;
        } else if (prevState.emailFields && prevState.emailFields.length !== this.state.emailFields) {
            if(this.state.emailFields.length === filteredEmails.length) {
                store.emailsValid = true;
            } else store.emailsValid = false;
        } else if (prevState.mailFields && prevState.mailFields.length !== this.state.mailFields) {
            if(this.state.mailFields.length === filteredMails.length) {
                store.addressesValid = true;
            } else store.addressesValid = false;
        } else if (prevState.role !== this.state.role) {
            if(this.state.role) {
                store.roleValid = true;
            } else store.roleValid = false;
        }

    }

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    onChangeOrganization = (e) => {
        this.setState({organization: e.target.value});
    }

    onChangeChapter = (e) => {
        this.setState({chapter: e.target.value});
    }

    onChangeOffice = (e) => {
        this.setState({office: e.target.value});
    }

    onChangePosition = (e) => {
        this.setState({position: e.target.value});
    }

    onChangeMailFields = (e) => {
        store.showAddressesValidationErrors = true;
        const mailFields = [...this.state.mailFields];
        const newMailFields = mailFields.map((item) => {

            let isValid = item.valid;
            let isValidationError = item.validationError;

            if (e.target.id === item.input_id) {
                isValid = e.target.value.length > 1;
                isValidationError = isValid ? '' : IS_INCORRECT;
                return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}    
            } else if (e.target.id === item.select_id) {
                return item = {...item, select_value: e.target.value}
            } else {
                return item = {...item, input_value: item.input_value, select_value: item.select_value}
            }
        })

        const isErrorItem = newMailFields.filter((item) => item.valid);

        if (isErrorItem.length === newMailFields.length) {
            this.setState({...this.state, mailFields: newMailFields, addressesValid: true}, ()=>store.addressesValid = true);
        } else {
            this.setState({...this.state, mailFields: newMailFields, addressesValid: false}, ()=>store.addressesValid = false);
        }
        
    }

    onChangePhoneFields = (e) => {
        store.showPhonesValidationErrors = true;
        const phoneFields = [...this.state.phoneFields];
        const newPhoneFields = phoneFields.map((item) => {
            
            let isValid = item.valid;
            let isValidationError = item.validationError;

            if (e.target.id === item.input_id) {
                isValid = e.target.value.match(/^[0-9+()#-]{12,}$/i);
                isValidationError = isValid ? '' : IS_INCORRECT;
                return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}    
            } else if (e.target.id === item.select_id) {
                return item = {...item, select_value: e.target.value}
            } else {
                return item = {...item, input_value: item.input_value, select_value: item.select_value}
            }
        })

        const isErrorItem = newPhoneFields.filter((item) => item.valid);

        if (isErrorItem.length === newPhoneFields.length) {
            this.setState({...this.state, phoneFields: newPhoneFields, phonesValid: true}, ()=>store.phonesValid = true);
        } else {
            this.setState({...this.state, phoneFields: newPhoneFields, phonesValid: false}, ()=>store.phonesValid = false);
        }
    }

    onChangePrimaryEmail = (e) => {
        this.setState({...this.state, primaryEmail: {...this.state.primaryEmail, input_value: e.target.value}})
    }

    onChangeEmailFields = (e) => {
        store.showEmailsValidationErrors = true;
        const emailFields = [...this.state.emailFields];
        
        const newEmailFields = emailFields.map((item, index) => {

            let isValid = item.valid;
            let isValidationError = item.validationError;

            if (!this.state.primaryEmail.input_value && e.target.id === item.input_id && index === 0) {
                if (!e.target.value.match(EMAIL_FORMAT)) {
                    isValid = false;
                    isValidationError = IS_INVALID;
                    return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}
                } else if (e.target.value.match(EMAIL_FORMAT)) {
                    if (e.target.value.split('@')[1] !== EMAIL_DOMAIN_PRIMARY &&
                        e.target.value.split('@')[1] !== EMAIL_DOMAIN_SECONDARY) {
                        isValid = false;
                        isValidationError = ` domain doesn't match @${EMAIL_DOMAIN_PRIMARY} ${EMAIL_DOMAIN_SECONDARY ? ` or @${EMAIL_DOMAIN_SECONDARY}` : ''}`;
                        return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}
                    } else {
                        isValid = true;
                        isValidationError = '';
                        return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}
                    }
                }
            } else if (!this.state.primaryEmail.input_value && e.target.id === item.input_id && index !== 0) {
                isValid = e.target.value.match(EMAIL_FORMAT);
                isValidationError = isValid ? '' : IS_INVALID;
                return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}    
            } else if (this.state.primaryEmail.input_value && e.target.id === item.input_id) {
                isValid = e.target.value.match(EMAIL_FORMAT);
                isValidationError = isValid ? '' : IS_INVALID;
                return item = {...item, input_value: e.target.value, valid: isValid, validationError: isValidationError}    
            } else {
                return item = {...item, input_value: item.input_value}
            }
        })

        const isErrorItem = newEmailFields.filter((item) => item.valid);

        if (isErrorItem.length === newEmailFields.length) {
            this.setState({...this.state, emailFields: newEmailFields}, ()=> store.emailsValid = true);
        } else {
            this.setState({...this.state, emailFields: newEmailFields}, ()=> store.emailsValid = false);
        }
    }

    onChangeRole = (e)  => {
        const selectedRole = store.userRoles.filter((item) => item.title === e.target.value)[0];
        if (selectedRole) {
            this.setState({...this.state, role: selectedRole}, () => {store.updatedUserInfo.role = selectedRole; store.updatedUserInfo.role_id = selectedRole.id; store.roleValid = true});
        }
    }

    onCheckPhoneValidation = () => {
        const updatedMailFields = this.state.mailFields.map((item, index) => {
            const isAddressValid = item.input_value.length > 1;
            const isValidationError = isAddressValid ? '' : IS_INCORRECT;
            if(item.active === 0) {
                return {
                    ...item,
                    validationError: '',
                    valid: true,
                }
            } else {
                return {
                    ...item,
                    validationError: isValidationError,
                    valid: isAddressValid,
                }
            }
            
        })
        const isErrorMailItem = updatedMailFields.filter((item) => item.valid);

        const updatedPhoneFields = this.state.phoneFields.map((item, index) => {      
            const isValid = item.input_value.match(/^[0-9+()#-]{12,}$/i);
            const isValidationError = isValid ? '' : IS_INCORRECT;
            if(item.active === 0) {
                return {
                    ...item,
                    validationError: '',
                    valid: true,
                }
            } else {
                return {
                    ...item,
                    validationError: isValidationError,
                    valid: isValid,
                }
            }
        })
        
        const isErrorPhoneItem = updatedPhoneFields.filter((item) => item.valid);

        if (isErrorMailItem.length !== updatedMailFields.length && isErrorPhoneItem.length !== updatedPhoneFields.length) {
            this.setState({...this.state, mailFields: updatedMailFields, phoneFields: updatedPhoneFields}, () => {store.addressesValid = false; store.phonesValid = false});
        } else if (isErrorMailItem.length !== updatedMailFields.length && isErrorPhoneItem.length === updatedPhoneFields.length) {
            this.setState({...this.state, mailFields: updatedMailFields, phoneFields: updatedPhoneFields}, () => {store.addressesValid = false; store.phonesValid = true});
        } else if (isErrorMailItem.length === updatedMailFields.length && isErrorPhoneItem.length !== updatedPhoneFields.length) {
            this.setState({...this.state, mailFields: updatedMailFields, phoneFields: updatedPhoneFields}, () => {store.addressesValid = true; store.phonesValid = false});
        } else if (isErrorMailItem.length === updatedMailFields.length && isErrorPhoneItem.length === updatedPhoneFields.length) {
            this.setState({...this.state, mailFields: updatedMailFields, phoneFields: updatedPhoneFields}, () => {store.addressesValid = true; store.phonesValid = true});
        }
    }

    onCheckEmailValidation = () => {
        const newEmailFields = this.state.emailFields.map((item, index) => {
            let isValid = item.input_value.match(EMAIL_FORMAT);
            let isValidationError = isValid ? '' : IS_INVALID;

            if(item.active === 0) {
                return {
                    ...item,
                    validationError: '',
                    valid: true,
                }
            } else {
                return {
                    ...item,
                    validationError: isValidationError,
                    valid: isValid,
                }
            } 
        });


        const isErrorItem = newEmailFields.filter((item) => item.valid);

        if (isErrorItem.length === newEmailFields.length) {
            this.setState({...this.state, emailFields: newEmailFields}, ()=> store.emailsValid = true);
        } else {
            this.setState({...this.state, emailFields: newEmailFields}, ()=> store.emailsValid = false);
        }
    }

    onRoleValidation = () => {
        if(!this.state.role) {
            store.roleValid = false; 
        } else {
            store.roleValid = true; 
        }
    }

    addMailItem = (event) => {
        event.preventDefault();
        store.showAddressesValidationErrors = false;
        store.addressesValid = false;
        store.formAddressValid = false;
        const newCount = this.state.mailFields.length+1;
        const newItem = mailItem(newCount);
        this.setState({...this.state, mailsCount: newCount, mailFields: [...this.state.mailFields, newItem]})
    }

    addPhoneItem = () => {
        store.showPhonesValidationErrors = false;
        store.phonesValid = false;
        store.formAddressValid = false;
        const newCount = this.state.phoneFields.length+1;
        const newItem = phoneItem(newCount);
        this.setState({...this.state, phonesCount: newCount, phoneFields: [...this.state.phoneFields, newItem]})
    }

    addEmailItem = (event) => {
        event.preventDefault();
        store.showEmailsValidationErrors = false;
        store.emailsValid = false;
        store.formAddressValid = false;
        const newCount = this.state.emailFields.length+1;
        const newItem = emailItem(newCount);
        this.setState({...this.state, emailsCount: newCount, emailFields: [...this.state.emailFields, newItem]})
    }

    randomInteger = (min, max) => {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }

    removeMailItem = (event, index) => {
        event.preventDefault();

        const newArr = [...this.state.mailFields];
        if (!newArr[index].hasOwnProperty('id') ){
            newArr.splice(index, 1);
        } else  {
            newArr[index].active = 0;
        }

        this.setState({...this.state, mailFields: newArr}, this.onCheckPhoneValidation)
    }

    removePhoneItem = (event, index) => {
        event.preventDefault();

        const newArr = [...this.state.phoneFields];
        if (!newArr[index].hasOwnProperty('id') ){
            newArr.splice(index, 1);
        } else  {
            newArr[index].active = 0;
        }

        this.setState({...this.state, phoneFields: newArr}, this.onCheckPhoneValidation)
    }

    removeEmailItem = (event, index) => {
        event.preventDefault();

        const newArr = [...this.state.emailFields];
        if (!newArr[index].hasOwnProperty('id') ){
            newArr.splice(index, 1);
        } else  {
            newArr[index].active = 0;
        }

        this.setState({...this.state, emailFields: newArr}, this.onCheckEmailValidation)
    }

    getOrganizationTree = (label) => {
        let organizations = [];
        let chapters = [];
        let offices = [];
        let departments = [];

        const organizationsTree = this.state.organizations.length ? store.unitsTree.filter(o1 => this.state.organizations.some(o2 => o1.id === o2.id)) : [];

        for(let i = 0; i < organizationsTree.length; i++) {
            if(organizationsTree[i].path.length === 4) {
                organizations.push(organizationsTree[i].path[0]);
                chapters.push(organizationsTree[i].path[1]);
                offices.push(organizationsTree[i].path[2]);
                departments.push(organizationsTree[i].path[3]);
            } else if (organizationsTree[i].path.length === 3) {
                organizations.push(organizationsTree[i].path[0]);
                chapters.push(organizationsTree[i].path[1]);
                offices.push(organizationsTree[i].path[2]);
            } else if (organizationsTree[i].path.length === 2) {
                organizations.push(organizationsTree[i].path[0]);
                chapters.push(organizationsTree[i].path[1]);
            } else if (organizationsTree[i].path.length === 1) {
                organizations.push(organizationsTree[i].path[0]);
            }
        }

        if (label === ORGANIZATION) {
            return organizations;
        } else if (label === SUBORG) {
            return chapters;
        } else if (label === DEPARTMENT) {
            return offices;
        } else if (label === COMMITTEE) {
            return departments;
        }
    }

    render() {
        const { accountSettingsMode, viewUserDetailsMode } = this.props;
        const { mailFields, phoneFields, emailFields } = this.state;

        const roleTitle = store.userRoles && store.userRoles.filter((item) => item.id === store.updatedUserAddress.role_id)[0];
        const title = roleTitle && roleTitle.title;

        return (
            <div className="address">
                <div className="block-title">{ADDRESS}</div>

                <div className="input-select-container">
                    {mailFields.map((field, index) => {
                        const { label, input_id, select_id, options, defaultField, active, input_value, validationError, select_value } = field;
                        if (active) {
                            return (
                                <div className="item" key={index}>
                                    <Input label={label} id={input_id} type="text" onChange={(e) => this.onChangeMailFields(e)} validationError={validationError} value={input_value} valid={store.addressesValid} showError={store.showAddressesValidationErrors} viewUserDetailsMode={viewUserDetailsMode} />
                                    <SelectType label="Type" id={select_id} options={options} onChange={(e) => this.onChangeMailFields(e)} value={select_value} viewUserDetailsMode={viewUserDetailsMode} />
                                    {viewUserDetailsMode ? 
                                        null
                                        : defaultField ? 
                                            <Button className="add-btn" value={ADD_PRIMARY} onClick={(event) => this.addMailItem(event)} /> 
                                            : <Button className="remove-btn" value={REMOVE} onClick={(event) => this.removeMailItem(event, index)} />}
                                </div>)
                        } else return null;
                    })}
                </div>
                <div className="input-select-container">
                    {phoneFields.map((field, index) => {
                        const { label, input_id, select_id, options, defaultField, validationError, required, active, input_value, placeholder, select_value } = field;
                        if (active) {
                            return (
                                <div className="item" key={index}>
                                    <Input label={label} id={input_id} type="text" onChange={(e) => this.onChangePhoneFields(e)} validationError={validationError} required={required} value={input_value} placeholder={placeholder} valid={store.phonesValid} showError={store.showPhonesValidationErrors} viewUserDetailsMode={viewUserDetailsMode} />
                                    <SelectType label="Type" id={select_id} options={options} onChange={(e) => this.onChangePhoneFields(e)} value={select_value} viewUserDetailsMode={viewUserDetailsMode} />
                                    {viewUserDetailsMode ? 
                                        null
                                        :
                                        defaultField ? 
                                            <Button className="add-btn" value={ADD_PRIMARY} onClick={this.addPhoneItem}/> 
                                            : <Button className="remove-btn" value={REMOVE} onClick={(event) => this.removePhoneItem(event, index)}/>}
                                </div>)
                        } else return null;  
                    })}
                </div>
                <div className="input-select-container">
                    {this.state.primaryEmail.input_value ?
                    <div className="item">
                        <Input label='Email' id='primary-email' type="email" value={this.state.primaryEmail.input_value} disabled='disabled' viewUserDetailsMode={viewUserDetailsMode} />
                        {viewUserDetailsMode ? null : <Button className="add-btn" value={ADD_PRIMARY} onClick={(event) => this.addEmailItem(event)}/>}
                    </div>
                    :
                    null}
                    {emailFields.map((field, index) => {
                    const { label, input_id, defaultField, validationError, required, input_value, active } = field;
                        if (active) {
                            return (
                                <div className="item" key={index}>
                                    <Input label={label} id={input_id} type="email" onChange={(e) => this.onChangeEmailFields(e)} validationError={validationError} required={required} value={input_value ? input_value : ''} valid={store.emailsValid} showError={store.showEmailsValidationErrors} viewUserDetailsMode={viewUserDetailsMode} />
                                    {viewUserDetailsMode ? 
                                        null
                                        :
                                        defaultField ? 
                                            <Button className="add-btn" value={ADD_PRIMARY} onClick={(event) => this.addEmailItem(event)}/> 
                                            : <Button className="remove-btn" value={REMOVE} onClick={(event) => this.removeEmailItem(event, index)}/>}
                                </div>)
                        } else return null;
                    })}
                </div>

                {accountSettingsMode ? 
                    <div className="block-information">
                        <BlockInfo label={ORGANIZATION} options={this.getOrganizationTree(ORGANIZATION)} />
                        <BlockInfo label={SUBORG} options={this.getOrganizationTree(SUBORG)} />
                        <BlockInfo label={DEPARTMENT} options={this.getOrganizationTree(DEPARTMENT)} />
                        <BlockInfo label={COMMITTEE} options={this.getOrganizationTree(COMMITTEE)} />
                        <BlockInfo label={POSITION} options={this.state.organizations.map((org) => org.position)} />
                    </div>
                    :
                    <Fragment>
                        <div>
                            <OrganizationFilter dataset={this.state.organizations} viewUserDetailsMode={viewUserDetailsMode} />
                        </div>
                        <div className="select-group role">
                            <SelectObject label={ROLE} options={store.userRoles} onChange={(e) => this.onChangeRole(e)} value={title} valid={store.roleValid} viewUserDetailsMode={viewUserDetailsMode} />
                        </div>
                    </Fragment>
                }
                
                {viewUserDetailsMode ?
                    <div className="tags-block">
                        <label>{TAG}</label>
                        <div className="tags">
                            <div className="items">
                                {this.state.tags_users.length ? this.state.tags_users.map((_item, index)=><div key={index}>{_item.name}</div>) : '-'}
                            </div>
                        </div>
                    </div>
                    :
                    <TagInput tags={this.state.tags_users} />
                }             
                
                {accountSettingsMode || viewUserDetailsMode ? 
                    <div className={`block-information ${store.current_user_role_id !== 1 ? 'private' : ''}`}>
                        {store.current_user_role_id !== 1 ? null : <Notes label='Private Notes' value={this.state.private_notes ? this.state.private_notes : '-'} />}
                        <Notes label='Public Notes' value={this.state.public_notes ? this.state.public_notes : '-'}  />
                    </div>
                    :
                    <div className={`notes-group ${store.current_user_role_id !== 1 ? 'partial' : ''}`}>
                        {notes.map((field, index) => {
                            const { label, id, placeholder } = field;
                            if (store.current_user_role_id !== 1 && label === 'Private Notes') {
                                return null;
                            } else return <Textarea key={index} label={label} id={id} placeholder={placeholder} onChange={(e) => this.onChange(e)} defaultValue={this.state[id] ? this.state[id] : ''} viewUserDetailsMode={viewUserDetailsMode} />
                        })}
                    </div>
                }
            </div>
        )
    }
}

export default FormAddress;