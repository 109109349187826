const URL_ELEMS = `${window.location.protocol}//${window.location.host}`
const EMAIL_DOMAIN_PRIMARY = process.env.REACT_APP_EMAIL_DOMAIN_PRIMARY;
const EMAIL_DOMAIN_SECONDARY = process.env.REACT_APP_EMAIL_DOMAIN_SECONDARY;
const EMAIL_FORMAT = /[a-z0-9]+[_a-z0-9\.-]*[a-z0-9]+@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})/i;
const NAME_FORMAT = /^[^0-9]{2,20}$/;


const generatePassword = (length, charSet) => {
    charSet = charSet ? charSet : '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-(#@)*';
    return Array.apply(null, Array(length || 10)).map(function() { 
        return charSet.charAt(Math.random() * charSet.length);
    }).join('');
}

export {
    URL_ELEMS,
    EMAIL_DOMAIN_PRIMARY,
    EMAIL_DOMAIN_SECONDARY,
    EMAIL_FORMAT,
    NAME_FORMAT,
    generatePassword,
}