import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ModalHeader from './ModalWindowElements/ModalHeader';
import Portal from './Portal';
import store from '../../stores/Store';
import { IMPORT_WAS_SUCCESSFUL } from '../../helper/strings';

import './modal.scss';

@observer
class ImportMessageModal extends Component {

    onModalClose = async() => {
        store.importMessageShow = false;
        store.fullSuccess = false;
        store.error = null;
    }

    render() {
        const { importTotalCount, successfulImportCount, importMessageShow, error, fullSuccess } = store;
        const importError = error && error.url ? JSON.parse(error.message) : error;

        if (importMessageShow) {
            if (importError && !fullSuccess) {
                return (
                    <Portal>
                        <div className="modal-overlay">
                            <div className="modal-window">
                                <div className='modal-body'>
                                    <div>
                                        <div>
                                            <ModalHeader value='Import' onModalClose={this.onModalClose} />
                                            <div className="scroll-block-units">
                                                <div className="add-edit-body import-notSuccess">
                                                    <div className="import-result">From {importTotalCount || importError.total} records {successfulImportCount || importError.successful} records have been imported successfully</div>
                                                    <div className="import-result">{importError.file_errors && importError.file_errors.length} records failed to import. Please check the format of the file and fields.</div>
                                                </div>
                                            </div>
                                            <div className="add-edit-footer">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portal>
                )
            } else if (fullSuccess) {
                return (
                    <Portal>
                        <div className="modal-overlay">
                            <div className="modal-window success">
                                <div className='modal-body'>
                                    <div>
                                        <div>
                                            <ModalHeader value='Import' onModalClose={this.onModalClose} />
                                            <div className="scroll-block-units">
                                                <div className="add-edit-body import-success">
                                                    <div className="import-result">{IMPORT_WAS_SUCCESSFUL}</div>
                                                </div>
                                            </div>
                                            <div className="add-edit-footer">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portal>
                )
            }
            
        }
            return null;
    }
}

export default ImportMessageModal;