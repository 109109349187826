import React, { Component } from 'react';
import { observer } from 'mobx-react';
import UsersData from './UsersDropdown/UsersData';
import ModalHeader from './ModalWindowElements/ModalHeader';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
//import Portal from './Portal';
import Spinner from '../Spinner/Spinner';
import store from '../../stores/Store';
import { INFO_WAS_SAVED_SUCCESSFULLY } from '../../helper/strings';

import './modal.scss';


@observer
class ViewUsersModal extends Component {

    onModalClose = async() => {
        store.searchOrgUsersInputValue = '';
        store.isViewUsersModalShown = false;
        store.viewedOrganizationId = null;
        store.editedOrganizationPositions = [];
        store.viewedOrgUsersList = [];
        store.updatedOrgUsersListPage = 1;
        store.viewedOrgUsersListPage = 1;
        store.success = false;
        
        store.getFilteredOrganizations({page: store.currentOrganizationsPage})    
    }

    render() {
        const { isViewUsersModalShown, isLoading, success } = store;

        const usersList = store.updatedOrganizationUsersList.map((user) => {
            if (user.organizations.find((org) => org.id === store.viewedOrganizationId)) {
                return {...user, includes: true}
            } else {
                return {...user, includes: false}
            }
        });
        
        const users=store.searchOrgUsersInputValue.length ? usersList : store.viewedOrgUsersList.map((item) => {return {...item, includes: true}});

        if (isViewUsersModalShown) {
            return (
                // <Portal>
                    <div className="modal-overlay">
                        <div className={`modal-window ${success ? 'success' : ''}`}>
                            <div className='modal-body'>
                                {isLoading ? 
                                <div className='spinner-block'>
                                    <Spinner />
                                </div>  
                                :
                                <div>
                                    {success ? 
                                        <SuccessWindow onModalClose={this.onModalClose} value={INFO_WAS_SAVED_SUCCESSFULLY} />
                                        :
                                        <div>
                                            <ModalHeader value='View User' onModalClose={this.onModalClose} />
                                            <div className="scroll-block-units view-users">
                                                <div className="add-edit-body-dropdowns">
                                                    <UsersData users={users} orgId={store.viewedOrganizationId} positionList={store.editedOrganizationPositions} />
                                                </div>
                                            </div>
                                            <div className="add-edit-footer">
                                            </div>
                                        </div>
                                    }
                                </div>
                            }   
                            </div>
                        </div>
                    </div>
                // </Portal>
            )
        }
            return null;
    }
}

export default ViewUsersModal;