import store from '../../stores';

export const mailItem = (mailsCount) => {
    return {
        label: 'Mailing Address',
        active: 1,
        input_id: `input_mailing_address${mailsCount}`,
        select_id: `select_mailing_address${mailsCount}`,
        options: ["personal", "working", "mailing"],
        defaultField: false,
        input_value: '',
        select_value: '',
        validationError: ' is incorrect',
        valid: false,
        required: 'required',
    };
}

export const phoneItem = (phonesCount) => {
    return {
        label: 'Phone',
        active: 1,
        input_id: `input_phone${phonesCount}`,
        select_id: `select_phone${phonesCount}`,
        options: ["personal", "business", "mobile"],
        defaultField: false,
        input_value: '',
        select_value: '',
        validationError: " is incorrect",
        valid: false,
        required: 'required',
        placeholder: ''
    };
}

export const emailItem = (emailsCount) => {
    return {
        label: 'Email',
        active: 1,
        input_id: `input_email${emailsCount}`,
        defaultField: false,
        validationError:  ' is invalid',
        input_value: '',
        valid: false,
        required: 'required'
    };
}

export const organizations = [
    {
        label: 'Organization',
        id: 'organization',
        options: store.userOrganizations,
    },
]

export const chapters = [
    {
        label: 'Chapter',
        id: 'chapter',
        options: store.userChapters,
    },
]

export const offices = [
    {
        label: 'Office',
        id: 'office',
        options: store.userOffices,
    },
]

export const selectGroup = [
    {
        label: 'Position',
        id: 'position',
        options: store.userPositions
    },
]

export const notes = [
    {
        label: 'Private Notes',
        id: 'private_notes',
        placeholder: 'Text here...'
    },
    {
        label: 'Public Notes',
        id: 'public_notes',
        placeholder: 'Text here...'
    },
];