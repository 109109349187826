import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import store from '../../../stores/Store';
import querystring from 'querystring';
import { url } from '../../../services/http/index';
import { VCF, VCF_EXPORT_CONDITION } from '../../../helper/strings';

@observer
class ExportDropdown extends Component {
    render() {
        const { exportOptions, selectedUsers } = this.props;

        return (
            <Fragment>
                {exportOptions.map((option, index) => {
                    const format = option.toLowerCase();
                    const toQuery = { access_token: store.token, format };
                    
                    if (option === VCF) {
                        toQuery['id'] = selectedUsers && selectedUsers.length === 1 && selectedUsers[0];
                        const query = querystring.stringify(toQuery);
    
                        return <a href={toQuery.id ? `${url}/v1/export?${query}` : `#`} key={index} download="users-list">
                                    <div>{toQuery.id ? option : `${option} ${VCF_EXPORT_CONDITION}`}</div>
                                </a>
                    } else {
                        if ( selectedUsers.length >=1 ) {
                            toQuery['ids[]'] = selectedUsers;
                        }    
                        const query = querystring.stringify(toQuery)
                        
                        return <a href={`${url}/v1/export?${query}`} key={index} download="users-list">
                                <div>{option}</div>
                                </a>
                    }               
                })
                }
            </Fragment>
        )
    }
}

export default ExportDropdown;