import React, { Component } from 'react';
import { observer } from 'mobx-react';
import removeButton from '../../../images/remove-tag.svg';
import store from '../../../stores';

@observer
class PositionsInput extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        focused: false,
        input: '',
        warning: false,
      };
  
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
    }

    orgPositionsStoreUpdate = () => {
        store.editedOrganizationPositions = store.editedOrganizationPositions.map((position) => {
            if (position.id) {
                return {
                    id: position.id,
                    name: position.name,
                    title: position.title,
                    existPosition: position.existPosition
                }
            } 
            else {
                return {
                    name: position.name,
                    title: position.title,
                    existPosition: position.existPosition
                }
            }
        });
    }

    handleInputChange = (e) => {       
        this.setState({ input: e.target.value });
    }
    
    handleInputKeyDown = (e) => {
        const {value} = e.target;
        const checkUnique = store.editedOrganizationPositions.filter((position) => position.name === value.toLowerCase() || position.title.toLowerCase() === value.toLowerCase())
        if (e.keyCode === 13 && !checkUnique.length) {
            e.preventDefault();          

            const newPosition = {
                title: value,
                name: value.toLowerCase(),
            }
            store.newPosition = newPosition;

            store.editedOrganizationPositions = [...store.editedOrganizationPositions]
            this.setState({input: '', warning: false}, ()=>this.orgPositionsStoreUpdate());
        }

        if (store.editedOrganizationPositions.length && e.keyCode === 8 && !this.state.input.length) {
            const orgId = store.editedOrgId;
            const tagId = store.editedOrganizationPositions[store.editedOrganizationPositions.length-1].id;
            const exist = store.editedOrganizationPositions[store.editedOrganizationPositions.length-1].existPosition;

            if (orgId && exist) {
                if(store.editedOrganizationPositions.length > 1) {
                    store.deletePositionFromOrg(orgId, tagId);
                    store.getEditedOrganization(orgId, false);
    
                    store.editedOrganizationPositions = store.editedOrganizationPositions.slice(0, store.editedOrganizationPositions.length - 1);
                    this.orgPositionsStoreUpdate();
                    this.setState({warning: false})
                } else if (store.editedOrganizationPositions.length === 1) {
                    this.props.onWarningClean();
                    this.setState({warning: true})
                }
            } else {
                store.editedOrganizationPositions = store.editedOrganizationPositions.slice(0, store.editedOrganizationPositions.length - 1);
                this.orgPositionsStoreUpdate();
            }            
        }        
    }
    
    handleRemoveItem = (index, tagId, exist) => {
        const orgId = store.editedOrgId;

        if (orgId && exist) {
            if(store.editedOrganizationPositions.length > 1) {
                store.deletePositionFromOrg(orgId, tagId);
                store.getEditedOrganization(orgId, false);

                store.editedOrganizationPositions = store.editedOrganizationPositions.filter((item, i) => i !== index)
                this.orgPositionsStoreUpdate();
                this.setState({warning: false})
            } else if (store.editedOrganizationPositions.length === 1) {
                this.props.onWarningClean();
                this.setState({warning: true})
            }
        } else {
            store.editedOrganizationPositions = store.editedOrganizationPositions.filter((item, i) => i !== index)
            this.orgPositionsStoreUpdate();
        }
    }
  
    render() {
        const { id, showError, validationError, warningClean } = this.props;
        const { warning } = this.state;

        const positionLabel = !warningClean && warning ? 
            'At least 1 position must be exist' :
            `Positions ${showError && validationError ? `${validationError} (type and press Enter)` : '(type and press Enter)'}`

        return (
                <label className="tags-block">
                    {id === "some_id" ? null : <label htmlFor="list" className={(showError && validationError) ? 'positions_invalid' : ''}>{positionLabel}</label>}
                    <ul className={`tags-container ${showError && validationError ? 'invalid' : ''}`} id={id}>
                    {store.editedOrganizationPositions.map((position, i) => {
                            return (
                                <li key={i} className='tag' onClick={()=>this.handleRemoveItem(i, position.id, position.existPosition)}>
                                    {position.name}
                                    <span><img src={removeButton} alt='remove'/></span>
                                </li>
                            )
                    })}
                    <input
                        className='tags-input'
                        value={this.state.input}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => this.handleInputKeyDown(e)} />
                    </ul>
                </label>
        );
    }
}

export default PositionsInput;