import React, { Component } from 'react';
import { observer } from 'mobx-react';
import SuccessWindow from './ModalWindowElements/SuccessWindow';
import ModalHeader from './ModalWindowElements/ModalHeader';
import { Button } from '../../helper/components';
import Portal from './Portal';
import deletion from '../../images/delete.svg';
import KnowledgeBaseStore from '../../stores/KnowledgeBaseStore';
import { 
    CHANGES_WERE_SAVED, 
    ARE_YOU_SURE_TO_DELETE_THE_LINE, 
    DELETE 
} from '../../helper/strings';

import './modal.scss';

@observer
class DeleteGroupModal extends Component {

    render() {
        const { success } = KnowledgeBaseStore;
        const { isShown, toggler, deleteGroups } = this.props;

        if (isShown) {
            return (
                <Portal>
                    <div className="modal-overlay">
                        <div className='modal-window deletion'>
                            <div className='modal-body'>
                                <div>
                                    {success ? 
                                        <SuccessWindow onModalClose={toggler} value={CHANGES_WERE_SAVED} />
                                        :
                                        <div>
                                            <ModalHeader value={DELETE} onModalClose={toggler} />
                                            <div className='add-edit-body deletion'>
                                                <img src={deletion} alt="delete-the-line" />
                                                <div>{ARE_YOU_SURE_TO_DELETE_THE_LINE}</div>
                                            </div>
                                            <div className="add-edit-footer">
                                                <Button className="primary-btn" onClick={() => deleteGroups()} value={DELETE} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
            return null;
    }
}

export default DeleteGroupModal;